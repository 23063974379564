@import "../../newShared.scss";

.breadcrumb {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Gotham Rounded Bold";
  text-decoration: none;
  margin: 0 5px;
  line-height: 20px;
  position: relative;    
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:nth-of-type(3){  
    max-width: 240px;
  }


  &.white {
    color: rgba(255, 255, 255, 0.7);
    &.current,
    &:hover {
      color: $main-orange;
    }
  }
  &.current,
  &:hover {
    max-width: initial;
    color: white;
  }
  &:first-child {
    margin-left: 0;
  }
}

.separators {
  margin-bottom: 1.5em;
  height: 30px;
  color: white;
  font-family: "Gotham Rounded Book";
  text-decoration: none;
  display: flex;
  align-items: center;
}

.selectedPill{
  cursor: pointer;
  width:240px;
  height: 30px;
  line-height: 30px;
  background:url('../../../../images/arrowDownYellow.svg') no-repeat right 10px center;
  background-color: #4e406e;
  border-radius: 15px;
  position: relative;
  z-index: 3;
  box-sizing:border-box;
  padding:0 15px;
  margin-left: 5px;

  h3{
    color: #ffce89;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
  }
}

.dropDown{
  width:230px;
  line-height: 30px;
  background-color: #4e406e;
  border-radius: 8px;
  position: absolute;
  top:30px;
  left:5px;
  box-sizing:border-box;
  padding:3px;

  label{
    width: 100%;
  }

  label>input[type=radio]{
    display: none;
  }

  div.dropdownItem{
    cursor: pointer;
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    box-sizing:border-box;
    padding:0 15px;
    border-radius: 8px;
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;

    &.selected{
      color: #ffce89;
    }

    &:hover{
      background-color: rgba(255, 255, 255, 0.2);
    }

  }

}

@media (max-width: 750px) {
  .separators{
    margin-bottom: .5em;
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .separators{
    font-size: 10px;
  }
}
