$main-yellow: #ffce89;
$main-yellow--bright: #fcc62d;
$main-orange: #ffa500;
$main-orange--hover: #e99820;
$main-purple: #4e406e;
$main-purple--hover: #3c3253;
$main-purple--bright: #8e00f0;
$alternative-purple: #7015d0;
$main-blue: #077ca0;
$main-blue--light: rgb(7, 124, 160);
$main-blue--dark: #2b4e7b;
$main-blue--dark--shadow: #1A385F;
$main-lightBlue: #45a5f8;
$main-lightBlue--hover: #7dc1fa;
$main-lightBlue--shadow: #3073ad;
$main-lightBlue--hover--shadow: #6e9ec6;
$main-green: #97d318;
$main-green--shadow: #76a514;
$main-green--hover: #cbe98b;
$main-red: #d00000;
$main-red--shadow: #8c0000;
$main-red--hover: #d93b3b;
$main-red--light: #f4bfbf;
$main-grey: #e9e9e9;
$main-mediumgrey: #cacaca;
$alternative-mediumgrey: #d2d2d2;
$main-darkgrey: #858585;
$alternative-darkgrey: #4a4a4a;
$main-black: #333333;
$shadow-white: #a4e9ff;
$shadow-purple: #382e4e;
$shadow-green: #76a514;
$shadow-yellow: #a46d1b;

a {
  text-decoration: none;
}

.adaptToScreen {
  padding-left: 150px;
  padding-right: 10px;
}

.adjustRow {
  display: flex;
  align-items: center;
}

.dashboardContainer {
  padding: 3em 0em;
  max-width: 1300px;
  margin: 0 auto;
}

@mixin btn($color, $bg, $padding) {
  cursor: pointer;
  background: $bg;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $color;
  text-align: center;
  padding: 0.75em $padding;
  margin: 0.5em 0em;
  display: inline-block;
  border: 1.5px solid $bg;
  transition: all 0.5s ease;

  &:hover {
    background-color: $color;
    color: $bg;
    border: 1.5px solid $bg;
  }
}

@media (max-height: 830px) {
  .dashboardContainer {
    padding: 0em;
    padding-top: 1.5em;
  }
}

@media (max-width: 950px) {
  .dashboardContainer {
    padding: 0em;
    padding-top: 1.5em;
  }
}
@media (max-width: 750px) {
  .adaptToScreen {
    padding-left: 10px;
  }
}
