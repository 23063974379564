.tickles-modal {
    background: #FFFFFF;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 500;
    &--completed{
        z-index: 1;
    }
    &__frame {
        background: #FFFFFF;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: scroll;
        padding: 20px;
    }
    &__back-button {
        margin-bottom: 20px;
    }
    &__close-button { 
        display: none;
    }
    &__dropdowns {
        display: flex;
        margin: 60px -10px 40px;
    }
    &__dropdown-holder {
        flex: 1;
        margin: 0 10px;
    }
    &__sub-title {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: $primaryFontBold;
    }
    &__dropdown {
        // background-position: right 5px top 8px;
        // border-color: #F8F8F8;
        // border-width: 5px;
        // height: 40px;
        // line-height: 30px;
        // padding: 0 25px 0 5px;
        &--add {
            background-position: right 5px top 8px;
            border-color: #F8F8F8;
            border-width: 5px;
            flex: 1;
            height: 40px;
            line-height: 30px;
            padding: 0 35px 0 5px;
        }
    }
    &--add {
        z-index: 1001;
        .tickle {
            margin-top: 20px;
        }
    }
    &__actions {
        align-content: center;
        display: flex;
        justify-content: space-between;
        margin: 40px 0 10px;
        .dropdown {
            flex: none;
            width: auto;
        }
    }
    &__no-activity {
        margin: 0;
    }
    &__incomplete {
        text-align: center;
        margin: 60px 0 0;
    }
    &__incomplete-text {
        font-family: $primaryFontBold;
        font-size: 18px;
        margin-bottom: 20px;
    }
    &__add-buttons {
        display: flex;
        justify-content: center;
        align-content: center;
        margin: 40px 0 0;
        text-align: center;
        button {
            margin: 0 5px;
        }
    }
}
.tickle {
    align-items: center;
    background: $blueLight;
    border: 1px solid #D6D6D6;
    border-radius: 9px;
    margin: 5px 0;
    padding: 10px;
    &--completed {
        background: none;
        border: none;
        border-bottom: 1px solid #F2F2F2;
        padding: 0 0 10px 0;
        margin: 10px 0;
    }
    &--completed:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding: 0;
    }
    &__content {
        color: $blue;
        font-family: $primaryFontBold;
    }
    &__boomer-holder {
        background: #FFFFFF;
        border-radius: 9px;
        border: 1px solid #F2F2F2;
        cursor: pointer;
        height: 70px;
        margin-right: 10px;
        padding: 5px;
        position: relative;
        width: 70px;
        min-width: 70px;
        &--large {
            border: 2px solid #F2F2F2;
            cursor: initial;
            height: 140px;
            width: 140px;
        }
    }
    &__boomer-badge {
        position: absolute;
        left: calc(50% - 10px);
        top: 44px;
        width: 20px;
        &--large {
            left: calc(50% - 20px);
            top: 86px;
            width: 40px;
        }
    }
    &__rank {
        bottom: -8px;
        left: calc(50% - 40px);
        position: absolute;
        width: 80px;
        &--large {
            bottom: -11px;
            left: calc(50% - 80px);
            width: 160px;
        }

    }
}
.student-overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 201;
}
.student-info {
    background: #FFF;
    border-radius: 15px;
    display: flex;
    max-width: 600px;
    padding: 15px;
    position: relative;
    width: calc(100% - 20px);
    &--loading {
        justify-content: center;
    }
    &__body {
        margin-left: 20px;
        flex: 1;
    }
    &__badges {
        max-height: 300px;
        overflow-y: scroll;
    }
    &__badge {
        background: #F5F5F5;
        border-radius: 8px;
        cursor: pointer;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
        width: calc(33.333% - 10px);
        &:hover {
            background: $blue;
        };
    }
    &__badge:nth-child(3) {
        margin-right: 0;
    }
    &__badge-img {
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    &__close {
        background: #F6F6F7;
        box-shadow: 0 0 4px rgba(0,0,0,0.3);
    }
    &__tickles {
        overflow: scroll;
    }
}

@media screen and (min-width: $tablet) {
    .tickles-modal {
        background: none;
        /*position: static;*/
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        padding: 20px;
        &__blanker {
            background: rgba(0 ,0 ,0 , 0.5);
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 400;
        }
        &__back-button {
            display: none;
        }
        &__frame {
            background: #FFFFFF;
            border-radius: 12px;
            bottom: 30px;
            margin: 0 auto;
            padding: 30px 20px 20px;
            position: fixed;
            top: 50px;
            width: 80%;
            overflow: visible;
        }
        &__close-button {
            align-items: center;
            background: darken($red, 10%);
            border: none;
            border-radius: 30px;
            cursor: pointer;
            display: block;
            font-family: $primaryFontBold;
            height: 40px;
            justify-content: center;
            padding: 0;
            position: absolute;
            right: -20px;
            top: -20px;
            width: 40px;
            &:hover {
                background: $red;
            }
        }
        &__close-icon {
            color: #FAFAFA;
            font-size: 30px;
        }
        &--add {
            .tickles-modal__frame {
                bottom: auto;
                top: 90px;
                width: 70%;
            }
        }
        &__list-holder {
            height: 100%;
            overflow: scroll;
        }
    }
}

@media screen and (min-width: $desktop) {
    .tickles-modal {
        &__frame {
            max-width: 1040px;
        }
        &--add {
            .tickles-modal__frame {
                width: 60%;
                max-width: 750px;
            }
        }
    }
}