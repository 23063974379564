.error-page {
    align-items: center;
    background: #F3F3F3;
    bottom: 0;
    color: $defaultTextColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 10000;
    &__inner {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 600px;
    }
    &__title {
        color: $purple;
        font-family: $primaryFontBold;
        font-size: 72px;
    }
    &__image {
        max-width: 210px;
    }
    &__subtitle {
        margin: 10px 0 20px;
        font-size: 21px;
    }
    &__caption {
        font-size: 18px;
        margin: 20px 0 20px;
    }
    &__body {
        font-size: 14px;
        margin-bottom: 20px;
    }
}
