@import "./../UnitWriting.module.scss";

.sidePanel {
  background-color: $main-purple;
  border: 4px solid $main-orange;
  border-radius: 10px;
  box-shadow: inset 0px -10px 0px 0px $main-purple--hover, -5px -5px 25px rgba(0, 0, 0, 0.2),
    5px 5px 25px rgba(0, 0, 0, 0.2);

  .contentPanel {
    margin-top: 2em;
    margin-bottom: 1em;
    overflow-y: auto;
    height: calc(100% - 85px);
  }
}


@media (max-width: 600px) {
  .sidePanel{
    width: 100%;
    height: 25%;
    .contentPanel{
      margin-top: 1em;
    }
  }
}

@media (max-height: 700px) {
  .sidePanel{
    .contentPanel{
      margin-top: 0.5em;
      height: calc(100% - 55px);
    }
  }
}

