@import "./../../newShared.scss";

.unitCard {
  height: 150px;
  background-color: white;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  color: $alternative-darkgrey;
  padding: 1em;
  position: relative;
  box-shadow: inset 0px -6px 0px 0px $alternative-mediumgrey;
  transition: all 1s ease;
  border: 6px solid $alternative-mediumgrey;
  min-width: 300px;

  &:hover {
    box-shadow: inset 0px -12px 0px 0px $alternative-mediumgrey;
    animation: redFlash 0.5s ease-in-out;
  }

  &.dueDatePassed {
    background-color: $alternative-mediumgrey;
    &:hover {
      &:before {
        width: 0px;
      }
    }
  }
  .dateContainer {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    font-family: "Gotham Rounded Bold";
    max-width: 160px;
    height: 45px;
    font-size: 20px;
    line-height: 24px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .margin {
    margin-bottom: 0.25em;
  }
}
.none {
  display: none;
}
.inactiveLink {
  pointer-events: none;
  cursor: default;
}

@keyframes redFlash {
  0% {
    background-color: white;
  }
  50% {
    background-color: $alternative-mediumgrey;
  }
  100% {
    background-color: white;
  }
}
