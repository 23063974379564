@import "./../../newShared.scss";

.noCards {
  background: #ffffff;
  /* Assignments */
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  width: 520px;
  margin: 1em 10em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    max-width: 400px;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #4e406e;
    margin: 1em 0em;
  }
  img {
    width: 185px;
  }
  .btn {
    background-color: $main-orange;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    border-radius: 10px;
    padding: 1em 2em;
    &:hover {
      background-color: $main-orange--hover;
    }
  }
}
