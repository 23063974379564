.boomer-heading {
	font-family: $primaryFont;
	padding-left: 65px;
	margin-bottom: 20px;
	position: relative;
	&--no-boomer {
		padding-left: 0 !important;
	}
	&__boomer {
		height: 61px;
		width: 61px;
		left: 0;
		border-radius: 61px;
		overflow: hidden;
		border: 5px solid $yellow;
		position: absolute;
	}
	&__boomer--rank {
		border: 1px solid $yellow;
	}
	&__title {
		color: white;
		font-size: 20px;
		font-family: $primaryFontBold;
		margin-bottom: 18px;
		position: relative;
	}
	&__title::before {
		background: $yellow;
		content: '';
		display: block;
		height: 5px;
		position: absolute;
		bottom: -10px;
		width: 20px;

	}
	&__badge {
		cursor: pointer;
		position: absolute;
		height: auto;
		left: 14px;
		top: 45px;
		width: 34px;
		z-index: 50;
	}
	&__sub {
		color: white;
		font-size: 16px;
		font-weight: 500;
	}
	&__rank {
		position: absolute;
		left: -5px;
		top: 5px;
		height: auto;
		width: 70px;
		z-index: 49;
	}
	&__wb-logo {
		height: auto;
		left: -5px;
		position: absolute;
		width: 55px;
	}
	&--boomer-desktop-only {
		padding-left: 0;
		.boomer-heading__boomer, .boomer-heading__badge, .boomer-heading__rank, .boomer-heading__wb-logo {
			display: none;
		}
	}
	&--project-home {
		margin-bottom: 0;
		.boomer-heading__title {
			color: $yellow;
		}
		.boomer-heading__sub {
			color: #000;
		}
	}
	&--success {
		.boomer-heading__title {
			color: #23B232;
		}
	}
	&--reject {
		.boomer-heading__title {
			color: #FF8481;
		}
	}
	&--writing {
		margin-bottom: 40px;
	}
}

@media screen and (min-width: $desktop) {
	.boomer-heading {
		&--boomer-desktop-only {
			padding-left: 65px;
			.boomer-heading__boomer, .boomer-heading__badge, .boomer-heading__rank, .boomer-heading__wb-logo {
				display: block;
			}
		}
		&__title {
			font-size: 28px;
			margin-bottom: 15px;
		}
		&__title::before {
			bottom: -7px;
		}
		&__sub {
			font-size: 18px;
		}
	}
}