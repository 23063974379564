@import "./newShared.scss";

.adaptToScreen {
  background-repeat: repeat;
  height: auto;
  padding-bottom: 100px;
}

.dashboardBody {
  
  display: grid;
  grid-template-columns: minmax(280px, 25%) 1fr;
  margin-top: 2em;
  .pickerBtnRow {
    display: flex;
    margin-left: 20px;
  }
  .fixthis {
    width: 96%;
  }
}

.entryRouterAdjust {
  padding: 1.5em 3em 100px 160px;

  .breadcrumbDiv{
    height:35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    img.typePill{
      margin-bottom: 1.5em;
      margin-left: .5em;
      display: none;
    }
  }
}

@media (min-width: 700px) {
  .entryRouterAdjust {  
    .breadcrumbDiv{
        flex-direction: row;
      img.typePill{
        display: block
      }
    }
  }
}

@media (max-width: 1300px) {
  .entryRouterAdjust {
    padding: 1.5em 1em 2em 160px;
  }
}

@media (max-width: 1000px) {
  .entryRouterAdjust {
    padding: 1.5em 1em 10em 140px;
  }
 
}

@media (max-width: 950px) {
  .dashboardBody {
    display: flex;
    .fixthis {
      width: 100%;
      height: 100%;
    }
    .pickerBtnRow {
      margin: 0;
    }
  }
}

@media (max-width: 750px) {
  .entryRouterAdjust {
    padding: 15px 15px 100px 15px;
  }
}
