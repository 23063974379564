.activity-feed {
    display: none;
}

@media screen and (min-width: $desktop) {
    .activity-feed {
        display: block;
        text-align: center;
        &__header {
            border-bottom: 1px solid #979797;
            display: inline-block;
            font-family: $primaryFontBold;
            margin-top: 40px;
            padding-bottom: 10px;
        }
        &__list {
            max-height: 280px;
            overflow-y: scroll;
        }
        &__item {
            font-size: 14px;
            line-height: 20px;
            padding: 20px 10px 5px 10px;
        }
		&__item--placeholder {
			padding: 20px 10px;
		}
        &__item:nth-child(odd) {
            background: $purpleDark;
        }
        &__coins-img {
            height: 14px;
            margin-right: 2px;
            position: relative;
            top: 2px;
        }
        &__time {
            color: $goldDark;
            font-size: 12px;
            text-align: right;
        }
    }
}