.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  width: calc(100% - 20px) !important;
  // width:1040px !important;
}

.fc-toolbar.fc-header-toolbar {
  width: calc(100% - 20px) !important;
}

//top bar (month, arrows)
.fc-toolbar-title {
  font-size: 1.75em !important;
  font-weight: 300 !important;
}
.fc-toolbar.fc-header-toolbar {
  height: 70px !important;
  display: flex !important;
  align-items: center !important;
  box-sizing: border-box;
  padding: 0 0 0 30px;
  margin: 0 !important;
}

//default buttons (hide, replace with custom btns)
.fc-button-group,
.fc-today-button {
  display: none !important;
}

//table header
.fc-scrollgrid-section-header {
  position: absolute;
  width: calc(90vw - 260px) !important;
  * {
    border: 0px !important;
  }
}
.fc-col-header {
  width: calc(90vw - 260px) !important;
}
// table header day cell
.fc-col-header-cell.fc-day {
  text-align: left;
  border-bottom: none;
}

// day block
//number text
.fc-daygrid-day-number {
  padding: 3px 7px !important;
  color: #333333 !important;
  font-size: 14px;
}
// day text
.fc-col-header-cell-cushion {
  display: inline-block;
  padding: 7px 10px !important;
  color: #858585 !important;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}
// day text TODAY
.fc-day-today {
  background-color: white !important;
  .fc-daygrid-day-number {
    background-color: rgb(6, 95, 229);
    border-radius: 50%;
    color: white !important;
    padding: 0px 3px !important;
    margin: 3px 5px 0 0;
  }
}

//day block
.fc-daygrid-day-frame {
  height: 120px !important;
}

// event
.fc-daygrid-event {
  cursor: pointer;
  height: 26px !important;
  border-color: white !important;
  color: white !important;
  margin-top: 5px !important;
  margin-left: 5px !important;

  * {
    height: 24px !important;
  }
}
//event title

.fc-event-title-container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 80px);
  

  span {
    color: white !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    margin-left: 30px !important;
  }
}
.fc-event-title {
  line-height: 20px;
  font-size: 15px;
  font-weight: bold;
  padding-left: 40px !important;
  vertical-align: middle !important;
  padding-top: 4px !important;
}
.fc-daygrid-event-dot + .fc-event-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 80px);
}
// end date marker (unwanted)
.fc-daygrid-event-dot {
  display: none;
}
.fc-daygrid-day-events {
  height: 75%;
}
// 'more' marker
.fc-daygrid-more-link {
  position: absolute !important;
  bottom: 0 !important;
  right: 3px !important;
  color: #858585 !important;
}

//popover
.fc-more-popover {
  width: 400px;
}
.fc-popover-header {
  background-color: white !important;
  .fc-popover-title {
    font-weight: 300;
    color: #333333;
  }
}
