@import "./../UnitWriting.module.scss";

$tab-blue: #2c9ff7;
$tab-blue-shadow: #065fe5;
$tab-yellow: #ffa826;
$tab-yellow-shadow: #a46d1b;
$tab-salmon: #2c9ff7;
$tab-salmon-shadow: #065fe5;

.mainWritingEditor {
  height: 100%;
  background-color: white;
  border: 4px solid $main-purple;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: -5px -5px 25px rgba(0, 0, 0, 0.2), 5px 5px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  display: flex;
  -moz-box-shadow: inset 0px -10px 0px 0px #B8B8B8;
  -webkit-box-shadow: inset 0px -10px 0px 0px #B8B8B8;
  box-shadow: inset 0px -10px 0px 0px #B8B8B8;
  max-width: 100%; // just added this
  &.shift_down{
    margin-top: 40px;
  }


  .principalWritingPanel {
    display: block;
    position: relative;
    overflow-y: hidden;
    flex-direction: column;
    height: 100%;
    transition: all .7s ease-in-out;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    -moz-box-shadow: inset 0px -10px 0px 0px rgba(212, 212, 212, 1);
    -webkit-box-shadow: inset 0px -10px 0px 0px rgba(212, 212, 212, 1);
    box-shadow: inset 0px -10px 0px 0px rgba(212, 212, 212, 1);

    
    &.half {
      padding-right: 10px;
      border-right: 4px solid $main-purple;
      width: 70%;
    }

    .contentEditable {
      flex: 1;
      height: calc(100% - 140px);
      overflow-y: scroll;
      overflow-x: hidden;
      white-space: pre-wrap;
      font-size: 22px;
      line-height: 30px;
      color: #4a4a4a;
      box-sizing:border-box;
      padding:10px 15px;
      margin-top: 5px;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 8px;
        background-color: rgb(220, 220, 220);
        border:1px solid rgb(220, 220, 220);
      }
      

      &:focus {
        outline: none;
      }
    }
  }
  .auxiliarPanel {
    width: 1px;
    opacity: 0;
    transition: all .7s ease-in-out;
    overflow-y: scroll;
    // overflow: hidden;
    
    
    &.active {
      width: 55%;
      opacity: 1;
    
    }
  }
}

@media (max-width: 600px) {
  .mainWritingEditor{
    margin-right: 0px;
  }
}

.endPanel {
  border-top: 1px solid #CCC;
  font-weight: bold;
  font-family: "Gotham Rounded Bold";
  padding-top: 20px;
  text-align: center;
  width: 100%;
}

.authorDetails {
  padding: 10px;
  text-align: center;
  font-size: 22px;
  &__chapter {
    font-family: "Gotham Rounded Bold";
  }
}

