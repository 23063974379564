.user-view--dash {
    background: url(../images/pattern-grey.png);
}

.dashboard {
    padding-top: 90px;
    &__tasks-container {
        width: 100%;
    }
}

.dash-class {
    background: #7015d0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    color: #FFF;
    margin-bottom: 40px;
    overflow: hidden;
    &__meta {
        color: #FFF;
        float: left;
        padding: 30px 20px 40px 20px ;
        position: relative;
        text-decoration: none;
        width: 240px;
        &:before {
            background: lighten(#7015d0, 5%);
            border-radius: 800px;
            content: '';
            left: -130px;
            top: -100px;
            position: absolute;
            height: 350px;
            width: 350px;
            z-index: 0;
        }
    }
    .dash-class__meta:hover {
        &:before {
            background: #7015d0;
        }
    }
    &__teacher, &__title, &__student-count {
        position: relative;
        z-index: 2;
    }
    &__title {
        font-family: $primaryFontBold;
        font-size: 20px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__student-count {
        border-radius: 200px;
        background: orange;
        display: inline;
        font-family: $primaryFontBold;
        height: 38px;
        width: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        text-align: center;
        color: white;
        &__orange{
            background: #4E406E;

        }
    }
    &__projects {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 156px;
        float: left;
        padding-right: 20px;
        width: calc(100% - 240px);
    }
    &__project {
        background: lighten(#7015d0, 5%);
        border-radius: 15px;
        color: #FFF;
        font-size: 14px;
        padding: 7px 35px 7px 10px;
        position: relative;
        margin: 5px 0;
        text-decoration: none;
        width: 100%;
        &:hover {
            background: lighten(#7015d0, 10%);
        };
    }
    &__phase {
        border-radius: 15px;
        bottom: 0;
        background-color: orange;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 18px auto;
        top: 0;
        position: absolute;
        right: 0;
        width: 35px;
        &--writing {
            background-image: url('../images/pencil.png');
        }
        &--voting {
            background-image: url('../images/check-black.png');
        }
        &--reading {
            background-image: url('../images/book-icon-black.png');
        }
        &--multi {
            background-image: url('../images/multi.png');
        }
    }
}


.dash-class--alt {
    background: orange;
    color: #4a4a4a;
    .dash-class__meta {
        color: #4a4a4a;
    }
    .dash-class__meta:before {
        background: lighten(orange, 8%);
    }
    .dash-class__student-count {
        background: #7015d0;
        color: #FFF;
    }
    .dash-class__project {
        background: lighten(orange, 8%);
        color: #4a4a4a;
    }
    .dash-class__phase {
        background-color: #7015d0;
    }
    .dash-class__phase--writing {
        background-image: url('../images/pencil-alt.png');
    }
    .dash-class__phase--voting {
        background-image: url('../images/check-white.png');
    }
    .dash-class__phase--reading {
        background-image: url('../images/book-icon-white.png');        
    }
    .dash-class__phase--multi {
        background-image: url('../images/multi-alt.png');
    }
}

.dash-class--alt {
    .dash-class__meta:hover {
        &:before {
            background: orange;
        }
    }
}


.project-link {
    border-bottom: 1px solid #d4d4d4;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: $blue;
    display: flex;
    padding: 20px 20px 20px 5px;
    text-decoration: none;
    width: 100%;
    &__img-holder {
        margin-right: 10px;
        padding-top: 5px;
        width: 50px;
        &--wb {
            margin-left: 5px;
            width: 40px;
        }
    }
    &__img {
        height: auto;
        width: 100%;
    }
    &__main {
        flex: 1;
    }
    &__title {
        font-family: $primaryFontBold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    &__details, &__teacher {
        align-items: center;
        color: #4a4a4a;
        display: flex;
        font-size: 14px;
    }
    &__details {
        justify-content: space-between;
    }
    &__teacher {
        text-align: right;
    }
    &__teacher-name {
        display: block;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__section {
        font-family: $primaryFontBold;
        margin-right: 10px;
    }
    &__ava {
        border-radius: 100px;
        height: 20px;
        margin-right: 5px;
        width: auto;
    }
    &--wb {
        background: #EFC210;
        .project-link__title {
            color: #4a4a4a;
        }
    }
    &:hover {
        background: $blue;
        .project-link__title {
            color: #FFF;
        }
    }
    &--voting {
        color: $purple;
        &:hover {
            background: $purpleDark;
            .project-link__details, .project-link__teacher {
                color: #FFF;
            }
        }
    }
}
.action-link {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    .action-link__inner {
        background: #FAFAFA;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 15px;
    }
    .button {
        display: flex;
        justify-content: center;
        &__image {
            margin: 0 5px;
        }
    }
    &__deadline {
        align-items: center;
        color: $red;
        display: flex;
        font-size: 14px;
        justify-content: center;
        margin-bottom: 10px;
        text-transform: capitalize;
    }
    &__deadline-img {
        height: 18px;
        margin-right: 5px;
        width: auto;
    }
    &__status {
        align-content: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: #f0b822;
        display: flex;
        font-family: $primaryFontBold;
        font-size: 14px;
        line-height: 18px;
        justify-content: center;
        padding: 15px 0;
        &--success {
            background: #D3F9D0;
            color: #23B232;
        }
        &--fail {
            background: #FFE7E7;
            color: #e66558;
        }
        &--complete {
            background: #F0FAFF;
            color: $blue;
        }
        &:hover {
            background: #FAFAFA;
        }
        &--winner {
            background: #F38D00;
            color: #FFE733;
            font-size: 18px;
            text-shadow: 0 0 5px rgba(200,0,0,0.5);
            &:hover {
                background: darken(#F38D00, 3%);
            }
        }
    }
    .action-link__status-icon {
        height: 18px;
        margin: 0 15px;
        width: auto;
    }
}

@media screen and (min-width: $tablet) {
    .dashboard {
        padding-top: 0;
        max-width: 1200px;
    }
}


@media screen and (min-width: 1100px) {
    .dash-class {
        margin: 20px 0 0 0;
        float: left;
        width: calc(50% - 10px);
    }
    .dash-class:nth-child(odd) {
        margin-right: 20px;
    }
}

@media screen and (min-width: 1240px) {
    .dash-class {
        margin: 40px 0 0 0;
        width: calc(50% - 20px);
    }
    .dash-class:nth-child(odd) {
        margin-right: 40px;
    }
}
