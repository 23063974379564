@import './../UnitInstructions.module.scss';
.carouselWrapper{
    margin-top: 20px;
    width: calc(100% + 50px);
    position: relative;
    left: -50px;

    .buttons{
        margin-top:1em;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn{
            background-color: $main-yellow;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background-image: url('./../../../../../../../../images/project/carouselArrow.png');
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
            background-color: #ddaf6e;
            }
            &.left{
                transform:rotate(-180deg)
            }
        }

        .totalSlides{
            width: 100px;
            text-align: center;
        }
    }
}