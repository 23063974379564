.history {
	bottom: 0;
	background: #FFF;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 500;
}
.history__close {
	background: red;
	border-radius: 150px;
	color: #FFF;
	cursor: pointer;
	font-family: $primaryFontBold;
	font-size: 22px;
	height: 28px;
	line-height: 28px;
	position: fixed;
	top: 10px;
	right: 10px;
	text-align: center;
	width: 28px;
	z-index: 501;
}
.history__header {
	padding: 20px;
}
.history__inner, .history__main {
 	display: flex;
  	flex-direction: column;
  	height: 100%;
}
.history__list-panel {
 	display: flex;
 	flex: 1;
  	flex-direction: column;
  	height: 100%;
	&--hide {
		display: none;
	}
}
.history__list {
	flex: 1;
	min-height: 100px;
	padding: 0 20px;
	overflow-y: scroll;
	&--preview {
		flex: 0;
		min-height: 70px;
	}
}
.history__entry {
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.3);
	color: $blue;
	display: block;
	font-size: 14px;
	margin-bottom: 10px;
	padding: 20px;
	position: relative;
}

.history__entry-view {
	background: #E6F6FF;
	border-radius: 5px;
	display: block;
	font-size: 12px;
	padding: 5px 10px;
	position: absolute;
	right: 20px;
	top: calc(50% - 12px);
}

.history__entry:hover, .history__entry--selected {
	cursor: pointer;
	background: $blue;
	color: #FFF;
	.history__entry-view {
		background: #0073B2;
		color: $blue;
	}
}
.history__preview {
	box-shadow: 0 -2px 5px rgba(0,0,0,0.3);
	padding: 20px;
	&--hide {
		display: none;
	}
	&--double {
		display: flex;
		justify-content: space-between;
	}
	.button {
		flex: 1;
		margin-right: 10px;
	}
	.button--green {
		margin-left: 10px;
		margin-right: 0;
	}
}

.history__entry-preview {
	display: none;
	height: 100%;
	flex-direction: column;
	flex: 1;
	&--show {
		display: flex;
	}
}
.history__text {
	flex: 1;
	overflow-y: scroll;
	padding: 0 20px;
}
.history__warning {
	font-family: $primaryFontBold;
	text-align: center;
}
@media screen and (min-width: $desktop) {
	.history {
		bottom: 80px;
		border-radius: 15px;
		left: 80px;
		right: 80px;
		top: 80px;
	}
	.history__close {
		right: 66px;
		top: 66px;
	}
	.history__inner {
		display: flex;
	}
	.history__main {
		flex: 1;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.history__list-panel {
		&--hide {
			display: flex;
		}
	}
	.history__preview {
		box-shadow: none;
		.button--green {
			margin-left: 0;
		}
	}
	.history__header {
		min-width: 100%;
		width: 100%;
	}
	.history__entry-preview {
		display: flex;
	}
	.history__hide-desktop {
		display: none;
	}
}

.history__entry-holder {
	border: 1px dashed #CCC;
	font-size: 16px;
	line-height: 24px;
	height: 100%;
	overflow-y: scroll;
	padding: 20px;
	&--placeholder {
		color: #555555;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

}