@import "./../Tabs.module.scss";

.instructionsContainer {
  display: flex;
  flex-direction: column;
  padding: 0em 2em 0em 1.5em;

  .instruction {
    display: flex;
    margin-bottom: 2em;
    .instructionImage {
      width: 35px;
      height: 35px;
    }
    .instructionText {
      white-space: pre-wrap;
      a{
        color: $main-yellow;
      }
    }

    .instructionBody {
      flex: 1;
      margin-left: 1em;
      font-family: Gotham Rounded Bold;
      color: $main-yellow;
      p {
        &:first-child {
          color: $main-yellow;
          font-size: 16px;
          font-family: Gotham Rounded Bold;
        }
        font-family: Gotham Rounded Book;
        color: white;
        font-size: 14px;
        line-height: 18px;
        span{color: white;}
      }
      ul {
        height: 45px;
      }
      .wordBankText {
        color: $main-yellow;
        font-family: Gotham Rounded Bold;
        span {
          color: white;
          font-family: Gotham Rounded Book;
        }
      }
      .rubricBtn {
        cursor: pointer;
        margin-top: 0.5em;
        border: 0;
        border-radius: 10px;
        background: #00a5ff;
        color: white;
        font-family: "Gotham Rounded Bold";
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 32px);
        &:hover {
          background: #008bd6;
        }
        .rubricName {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .rubricView {
          font-family: "Gotham Rounded Book";
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .instructionsContainer {
  padding: 0em 1em 0em 0.5em;
    
  }
}
