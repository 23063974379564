.voting {
    padding: 20px;
    position: relative;
    &__instructions {
        background: $purple;
        display: none;
        &--show {
            display: block;
        }
    }
    &__instruction-btn {
        display: none;
    }
    &__close {
        border: none;
        border-radius: 200px;
        cursor: pointer;
        top: 20px;
        right: 20px;
        padding: 5px;
        position: absolute;
        height: 40px;
        width: 40px;
        &:hover {
            background: #F1F1F1;
        }
        &:focus {
            border: 2px solid $purple;
            outline: none;
        }

        &-img {
            height: auto;
            width: 20px;
        }
    }
    &__entry-loader {
        height: 30px;
        position: absolute;
        right: 15px;
        top: 12px;
        width: auto;
    }
    &__title {
        font-family: $primaryFontBold;
        font-size: 21px;
        margin-bottom: 10px;
    }
    &__entries-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        &__entry {
            background: white;
            margin: 10px;
            padding: 20px;
            position: relative;
            width: calc(100% - 20px);
            .u-pill {
                position: absolute;
                right: 15px;
                top: 15px;
            }
        }
    }
    &__entry-preview {
        font-size: 14px;
        line-height: 22px;
        height: 70px;
        margin: 20px 0;
        overflow: hidden;
    }
    &__entry-buttons {
        &--double {
            display: flex;
            margin: 0 -10px;
            .button {
                flex: 0 calc(50% - 20px);
                margin: 0 10px;
            }
        }
        .button {
            width: 100%;
        }
    }
    &__entry {
        background: white;
        padding: 20px;
        &__buttons {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px 10px;
            button {
                margin: 0 5px;
            }
        }
        &__title {
            align-items: center;
            color: $blue;
            display: flex;
            font-family: $primaryFontBold;
            font-size: 21px;
            margin-bottom: 20px;
            .voting__entry__star {
                height: 22px;
                margin: -4px 0 0 10px;
                width: auto;
            }
        }
    }
    &__entry__header {
        align-items: center;
        color: $blue;
        display: flex;
        font-family: $primaryFontBold;
        .voting__entry__star {
            height: 18px;
            margin: -2px 0 0 5px;
            width: auto;
        }
    }
    &__progress {
        background: #FFFBE7;
        border-radius: 15px;
        color: $blue;
        font-family: $primaryFontBold;
        padding: 15px 15px 25px 15px;
        position: relative;
    }
    &__bar {
        background: #F4F4F4;
        border-radius: 20px;
        box-shadow: inset 0 0px 3px rgba(0,0,0,0.2);
        display: block;
        height: 10px;
        margin-top: 10px;
        overflow: hidden;
        width: 100%;
    }
    &__bar-inner {
        background-color: rgb(255,128,0); /* Old browsers */
        background-image: url('../images/progress_bg.png');
        background-image: url('../images/progress_bg.png'), -moz-linear-gradient(left,  rgba(255,128,0,1) 0%, rgba(255,187,71,1) 100%); /* FF3.6-15 */
        background-image: url('../images/progress_bg.png'), -webkit-linear-gradient(left,  rgba(255,128,0,1) 0%,rgba(255,187,71,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background-image: url('../images/progress_bg.png'), linear-gradient(to right,  rgba(255,128,0,1) 0%,rgba(255,187,71,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8000', endColorstr='#ffbb47',GradientType=1 ); /* IE6-9 */
        display: block;
        height: 10px;
        transition: width linear 0.5s;
        width: 0%;
    }
    &__progress-check {
        animation: votingEnabled 0.5s ease-in 0.5s forwards, pulse ease 1s 1s;
        background: #FFF;
        border-radius: 100px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 30px;
        width: 30px;
        transform: scale(0);
        &--report {
            height: 40px;
            width: 40px;
        }
    }
    &__progress-img {
        height: auto;
        width: 100%;
    }
    &__progress-text {
        bottom: 5px;
        color: #4a4a4a;
        font-size: 12px;
        position: absolute;
        right: 40px;
        transition: color ease 1s;
        &--active {
            color: $green;
        }
    }
}
.button--bookmark {
    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: center;
    left: 50%;
    line-height: 16px;
    margin-left: -111px;
    position: absolute;
    top: 15px;
    width: 222px;
}
.button__image--bookmark {
    height: 14px;
    margin: -2px 5px 0 0;
    width: auto;
}
.entry__bookmark-loader {
    height: auto;
    left: 50%;
    margin-left: -18px;
    position: absolute;
    top: 20px;
    width: 36px;
}
.voting__entry {
    width:calc(100% - 120px);
    animation: expand 0.5s;
    bottom: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    left: 120px;
    margin: auto;
    position: fixed;
    top: 0;
    z-index: 950;
}
.voting__entry-header {
    border-bottom: 1px solid #CCC;
    margin-bottom: 10px;
}
.voting__entry__text {
    font-size: 16px;
    height: calc(100vh - 170px);
    line-height: 26px;
    margin-bottom: 20px;
    overflow-y: scroll;
    white-space: pre-wrap;
}

.voting__entry__content {
    animation: fadeIn 0.8s ease 0.5s 1 normal forwards;
    margin: 0 auto;
    max-width: 800px;
    opacity: 0; 
}
.voting-tab--instructions {
    background: $purpleLight;
    border: none;
    border-radius: 20px;
    color: $yellowLight;
    flex: 0;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 10px 5px 25px;
    position: absolute;
    right: 20px;
    top: 15px;
}
.voting-tab__info {
    display: block;
    left: -10px;
    position: absolute;
    height: auto;
    top: -2px;
    width: 30px;
}

.instruction__detail-prompt {
    align-items: center;
    color: #CCC;
    display: flex;
}
.instruction__detail-coin {
    height: 16px;
    margin-right: 10px;
    width: auto;
}
.voting-success {
    font-family: $primaryFontBold;
    max-width: 600px;
    padding: 20px;
    text-align: center;
    &__boomers {
        height: auto;
        width: 200px;
    }
}
.vote-confirm-overlay {
    &__body {
        background: $purple;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__voted-img {
        max-height: 213px;
        max-width: 570px;
        width: 100%;
    }
    &__group-img {
        max-height: 233px;
        max-width: 982px;
        width: 100%;
    }
    &__title,
    &__message {
        color: #FFFFFF;
        font-size: 18px;
        font-family: $primaryFontBold;
        line-height: normal;
        margin: 10px 0 10px;
    }
    &__title {
        font-size: 24px;
        margin-top: 20px;
    }
    &__coins {
        height: 24px;
    }
    &__button-holder {
        margin: 10px 0 20px;
    }
    &__button {
        display: inline-block;
    }
}

@media screen and (min-width: $tablet) {
    .voting {
        padding: 0;
        &__entries-list {
            &__entry {
                flex: 0 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
        }
    }
    .voting-tab--instructions {
        right: 0;
    }

    .vote-confirm-overlay {
        &__body {
            background: #FFFFFF;
        }
        &__inner {
            background: #FFFFFF;
            border-radius: 24px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            padding: 20px;
        }
        &__voted-img {
            max-height: 213px;
            max-width: 570px;
            position: absolute;
            top: -183px;
            width: 100%;
        }
        &__group-img {
            bottom: -100px;
            max-height: 233px;
            max-width: 982px;
            position: absolute;
            width: 100%;
        }
        &__title,
        &__message {
            color: $defaultTextColor;
        }
        &__title {
            color: $blue;
            margin-top: 0;
        }
        &__coins {
            height: 24px;
        }
        &__button-holder {
            margin-bottom: 0;
        }
        &__close-button {
            right: -40px;
        }
    }
}
@media screen and (max-width: $desktop) {
    .voting {
        &__instructions {
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 200;
        }
    }
}
@media screen and (min-width: $desktop) {
    .voting {
        &__content {
            display: flex;
        }
        &__entries-list{
            flex: 1;
            height: 0%; /*hack*/
            order: 1;
            &__entry {
                flex: 1 calc(50% - 20px);
            }
        }
        &__instruction-btn {
            background: $purple;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            color: $yellowLight;
            display: inline-block;
            font-family: $primaryFontBold;
            margin-top: 20px;
            padding: 10px 15px;
            width: initial;
        }
        &__instructions {
            border-radius: 15px;
            border-top-left-radius: 0;
            display: block;
            padding: 20px 0;
        }
        &__side {
            flex: 0;
            order: 2;
            min-width: 250px;
            margin: 10px 0 0  20px;
        }
    }
    .voting-tab--instructions {
        display: none;
    }
    .vote-confirm-overlay {
        &__group-img {
            bottom: -110px;
        }
    }
}

@media screen and (min-width: 1100px) {
    .voting {
        &__side {
            min-width: 350px;
        }
    }
}

@keyframes votingEnabled {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes votingEnabled {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

  
@-webkit-keyframes expand {
    0% {
      height: 0;
      width: 0;
    }
    100% {
        height: 100%;
        width: 100%;
    }
}

@keyframes expand {
    0% {
        height: 0;
        width: 0;
    }
    100% {
        height: 100%;
        width: 100%;
    }
}
.votingBoomers {
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
}
@media screen and (min-width: 600px) {
    .votingBoomers {
        max-width: 500px;
    }
}
.votingEndPanel {
    max-width: 700px;
}
.votingHeader {
    color: $purple;
    margin-bottom: 10px;
    font-size: 30px;
}