.modal {
    position: fixed;
    top: 0;
    left: 120px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 51;

    &.show {
        display: block;
    }

    .carouselWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .carousel {
            max-width: 1200px;
            max-height: 600px;
            img{
               
            }
        }
    }


    .closeModal {
        position: absolute;
        top: -20px;
        right: 40px;
        width: 40px;
        height: 40px;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: #ffffff;
        background: #c01313;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 2;
        p{
            margin-top:4px
        }
    }
}
