@import "../NewDashboard/newShared.scss";

.adaptToScreen {
  padding-top: 4em;
  padding-left: 120px;
  background-repeat: repeat;
  height: auto;
}

.calendarContainer {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  border-radius: 5px;
  // height: 90vh;

  .calendarColumn {
    background-color: $main-purple;
    border-radius: 5px 0px 0px 5px;
    max-width: 250px;
    .pickerRow {
      display: flex;
      border-bottom: 0.5px solid $main-mediumgrey;
      justify-content: center;
      align-items: center;
      .pickerBtn {
        cursor: pointer;
        position: relative;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.5);
        padding: 0.75em;
        display: flex;
        align-items: center;
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
        &.active {
          color: white;
          &:after {
            height: 3px;
          }
        }
        &:after {
          content: "";
          width: 72px;
          height: 0px;
          position: absolute;
          bottom: 0;
          border-radius: 10px 10px 0px 0px;
          background-color: white;
          left: calc((100% - 72px) / 2);
          transition: height 200ms ease;
        }
        p {
          margin-left: 5px;
        }
        svg {
          width: 20px;
        }
      }
      .rowTitle {
        color: white;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        padding: 1em 1.25em 0.5em;
      }
    }
    .classSelector {
      margin: 1em 0.5em;
      .classSelectorBtn {
        width: 180px;
        border-radius: 5px;
        height: 40px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        transition: 0.2s all ease;
        background: url("./../../images/calendar/Intersect.svg");
        background-repeat: no-repeat;
        background-size: 0px 40px;
        color: rgba(255, 255, 255, 0.5);

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        &.active {
          background-color: rgba(255, 255, 255, 0.1);
          background-size: 8px 40px;
          color: white;
          span {
            background-color: white;
          }
        }
        p {
          margin-left: 1em;
          width: 110px;
        }
        .ribbon {
          width: 20px;
          height: 24px;
          &.active {
            background-image: url("./../../images/calendar/ribbon.svg");
            background-repeat: no-repeat;
          }
        }

        span {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          font-size: 14px;
          line-height: 14px;
          color: $main-purple;
          // margin-left: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .toThePower {
            margin-left: 3px;
            margin-top: 5px;
            font-size: 13px;
            sup {
              position: absolute;
              font-size: 10px;
              top: 2px;
              right: 4px;
            }
          }
        }
      }
    }
  }
}

.calendarDiv {
  position: relative;
  flex-grow: 2;
  padding-bottom: 1.5em;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 0px 1px 10px 0px;

  .arrows {
    display: flex;
    align-items: center;
    width: 100px;
    height: 40px;
    position: absolute;
    top: 17px;
    left: 240px;

    .left,
    .right {
      background: url("../../images/calendar/calendarArrow.svg") no-repeat center;
      height: 40px;
      width: 50px;
      cursor: pointer;
      // border:1px solid chartreuse;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    .right {
      transform: rotate(180deg);
    }
  }

  .legend {
    position: absolute;
    right: 100px;
    top: 15px;
    height: 40px;
    width: 340px;
    display: flex;
    align-items: center;

    .legendItem {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      .dot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-right: 8px;
        &.orange {
          background-color: $main-orange;
        }
        &.blue {
          background-color: $main-blue--light;
        }
        &.green {
          background-color: $main-green;
        }
      }

      .ribbon {
        margin-right: 8px;
      }

      h5 {
        font-size: 12px;
        font-weight: 300;
        color: #333333;
      }
    }
  }
}

.assignment {
  background-color: $main-orange !important;
}
.story {
  background-color: $main-green !important;
}
.journal {
  background-color: $main-blue--light !important;
}
.aRibbon {
  width: 20px;
  height: 24px;
  background-image: url("./../../images/calendar/ribbon.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 15px;
}

.event {
  position: relative;
  border-radius: 7px !important;

  div.keyDot {
    color: $main-blue;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 17px;
    background-color: white;
    left: 7px;
    top: 3px;
    width: 17px;
    height: 17px !important;
    border-radius: 50%;
    position: absolute;
    box-sizing: border-box;
    padding: 2px 0 0 1px;

    .toThePower {
      position: relative;
      left: 0;
      margin-left: -4px;
      sup {
        font-size: 9px;
        position: absolute;
      }
    }

    //dots inside the 'ending-today' type event
    &:nth-child(3) {
      box-sizing: border-box;
      padding: 0 0 1px 0;

      .toThePower {
        bottom: 2px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .calendarDiv {
    .arrows {
      width: 50px;
      height: 40px;
      position: absolute;
      top: 17px;
      left: 230px;

      .left,
      .right {
        background: url("../../images/calendar/calendarArrow.svg") no-repeat center;
        height: 30px;
        width: 40px;
        cursor: pointer;
        // border:1px solid chartreuse;
        &:hover {
          background-color: #f2f2f2;
        }
      }
    }
    .legend {
      right: 50px;

      .legendItem {
        margin-right: 10px;
      }
    }
  }
}
