.boomerHeadingText {
    display: flex;
    justify-content: space-between;
    max-width: 1150px;
    position: relative;

    h1 {
        span {
            
            color: white;
            background: linear-gradient(94.11deg, #f26283 0.33%, #d00000 100.5%);
            border-radius: 5px;
            font-size: 20px;
            line-height: 19px;
            padding: 0.25em 1em;
        }
    }
}
