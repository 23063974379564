@import './../../../newShared.scss';

.classmateCard{
  width: 130px;
  height: 130px;
  margin: 10px 10px 10px 10px;
  padding-top: 10px;
  background-color: white;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}