
.project__mobile-header {
    background: $purple;
	display: flex;
	justify-content: flex-start;
	padding: 10px;
	&__title {
		color: #FFF;
		flex-grow: 0;
		font-family: $primaryFontBold;
		left: -34px;
		line-height: 38px;
		margin: auto;
		position: relative;
		text-align: center;
		width: calc(100% - 	160px);
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
	}
	&__button {
		z-index: 10;
	}	
}
.project__content {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 58px);
	min-height: 600px;
    padding: 20px;
}
.boomer-heading--project {
	width: 100%;
}
.writing-container {
	display: flex;
	flex: 1;
	padding: 50px 0 0 0;
	position: relative;
	max-height: 100%;
	width: 100%;
}
.project-text {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	width: 100%;
}
/* test */
.project-entry {
	
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: 20px;
	overflow-y: scroll;
	font-size: 16px;
	line-height: 24px;
	padding: 20px;
	white-space: pre-line;
	overflow-y: hidden;
}
.project-entry-editable {
	flex: 1;
	overflow-y: scroll;
	padding-left:2px;
	// // white-space: pre-wrap;
}
.project-entry-editable:empty:before {
	content: attr(placeholder);
	display: block;
	color: #aaa;
}
/*.button--project-action {
	flex: 0;
}*/
.project-tabs {
	display: flex;
	height: 50px;
	position: absolute;
	left: 0;
	top: 1px;
	width: 100%;
	&--preview {
		display: none;
	}
	&--hide-mob {
		display: none;
	}
}
.project-tab {
	background: #F9F9F9;
	border: 1px solid #D6D6D7;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	flex: 1;
    font-family: $primaryFontBold;
	font-size: 16px;
	line-height: 32px;
	text-align: center;
	white-space: nowrap;
	&--instructions {
		background: $purple;
	}
	&--active {
		background: #FFF;
		border-bottom: none;
		color: $purple;
		&--chat {
			background-color: $purple;
			color: #FFCE89;
		}
	}
	&:focus {
		outline: none;
	}
	&.project-tab--default-revisions {
		color: red;
	}
}
.project-tab__info {
	display: none;
}
.project-aside {
	flex: 1;
	max-height: 100%;
 	&--show {
 		width: 100%;
 	}
}
.dropdown--prev {
	margin: 0 20px;
	width: calc(100% - 40px);
}
.project-side {
	display: none;
	background: #FFF;
	height: 100%;
	padding: 20px 0;
	width: 100%;
	&--show {
		display: flex;
		flex-direction: column;
	}
	&--preview {
		background: $purple;
	}
	&--feedback {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		overflow-y: scroll;
		padding: 20px;
	}
}

.project-side__prev-chap {
	display: none;
	flex: 1;
	line-height: 20px;
	overflow-y: scroll;
	padding: 0 20px;
	&--show {
		display: flex;
		flex-direction: column;
	}
}
.instructions-mob {
	flex-grow: 0;
}
.instructions__content {
	flex: 1;
	overflow-y: auto;
}
.instruction {
	display: flex;
	min-width: 0;
	padding: 0 20px;
	&--closed {
		background: $redLight;
		color: $defaultTextColor;
		margin: 0 20px;
		padding: 20px;
		.instruction__main,
		.instruction__detail {
			color: $defaultTextColor;
		}
	}
	&--review {
		padding: 0;
	}
}
.instruction .linkified {
	color: $yellowLight;
}
.instruction__img {
	height: auto;
	width: 35px;
}
.instruction__side {
	min-width: 50px;
	width: 50px;
	&--review {
		min-width: 40px;
		width: 40px;
	}
}
.instruction__main {
	color: $yellowLight;
	font-family: $primaryFontBold;
	flex: 1;
	min-width: 0;
}
.instruction__detail {
	color: #FFF;
	font-size: 14px;
    font-family: $primaryFont;
	line-height: 18px;
	white-space: pre-line;
}
.project-next--mob {
	bottom: 20px;
	left: 20px;
	position: absolute;
	width: calc(100% - 40px);
}
.instruction__vocab {
	background: $purpleDark;
	border-radius: 15px;
	color: $yellowLight;
	float: left;
    font-family: $primaryFont;
	margin: 10px 5px 0 0;
	padding: 5px 8px;
	transition: all 0.5s ease;
	&--used {
		background: $yellow;
		color: $purple;
	}
}
.instruction__vocab-usage {
	clear: both;
	font-size: 14px;
	margin-top: 10px;	
}

.instruction__doc {
	color: #D8D8D8;
	display: block;
	background: $purpleDark;
	border-radius: 30px;
	font-size: 12px;
    font-family: $primaryFont;
	margin-top: 10px;
	position: relative;
	text-decoration: none;
	&:hover {
		background: darken($purple, 15%);
	};
}
.instruction__doc-name {
	display: block;
  	overflow: hidden;
	padding: 10px;
  	text-overflow: ellipsis;
	width: calc(100% - 70px);
	white-space: nowrap;
}
.instruction__doc-read {
	background: $blue;
	bottom: 4px;
	border-radius: 15px;
	color: #FFF;
	font-size: 14px;
    font-family: $primaryFontBold;
	/*line-height: 24px;*/
	line-height: 30px;
	position: absolute;
	padding: 0 10px;
	right: 4px;
	top: 4px;
}


.instructions__close {
	display: none;
}

.project-crumbs, .project-crumbs__link {
	color: #828282;
    font-family: $primaryFontBold;
	text-decoration: none;
}
.project-crumbs__link {
	margin: 0 5px;
	&--current, &:hover {
		color: #333333;
	}
	&:first-child {
		margin-left: 0;
	}
}

.feedback {
	color: $defaultTextColor;
	&__text {
		background: $greenLight;
		border-radius: 10px 10px 10px 0;
		line-height: 1.5;
		padding: 10px;
		margin-bottom: 5px;
	}
	&__teacher {
		align-items: center;
		display: flex;
		margin-bottom: 30px;
		&-image {
			border: 1px solid #F2F2F2;
			border-radius: 15px;
			margin-right: 5px;
			max-height: 30px;
			max-width: 30px;
		}
	}
	&__revisions {
		background: white;
		margin-top: 30px;
	}
	&__revision {
		margin-bottom: 20px;
		&-bubble {
			background: #F2F2F2;
			border-radius: 10px 10px 10px 0;
			padding: 10px;
			margin-bottom: 5px;
		}
		
		&-date {
			color: $purple;
			font-size: 12px;
			margin-bottom: 15px;
		}
		&-teacher {
			margin-bottom: 0;
			margin-top: 5px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.revision {
	color: $defaultTextColor;
	&__title {
        font-family: $primaryFontBold;
		margin-bottom: 10px;
	}
	&__bubble {
		background: #F2F2F2;
		border-radius: 10px 10px 10px 0;
		padding: 10px;
		margin-bottom: 10px;
		&--pending {
			background: $yellowLighter;
		}
		&--alert {
			background: $redMid;
		}
	}
	&__date {
		color: $purple;
		font-size: 12px;
		margin-bottom: 15px;
	}
	&__text {
		font-style: italic;
		line-height: 1.5;
		margin-bottom: 10px;
	}
	&__teacher {
		align-items: center;
		display: flex;
		margin-bottom: 30px;
		&-image {
			border: 1px solid #F2F2F2;
			border-radius: 15px;
			margin-right: 5px;
			max-height: 30px;
			max-width: 30px;
		}
	}
}

.project-entry-header {
	color: $defaultTextColor;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	line-height: normal;
	max-width: 300px;
	flex-direction: column;
			flex-wrap: wrap;
	&.openBreakout{
		flex-direction: column;
		align-items: flex-end;

		.project-entry-header__left{
			width: 100% !important;

			.scroll-arrows{ 
				p{
					width: 93%;
					max-width: 70%;
				}
			}
		}
	}

	&__left{


		.scroll-arrows{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			/*margin-left: 20px;*/ //changed this just now
			height: 20px;
	
			img.arrow{
				cursor: pointer;
	
				&.disable{
					pointer-events: none;
					opacity: .2;
				}
	
				&:first-of-type{
					margin-right: 20px;
				}
				&:last-of-type{
					margin-left: 20px;
				}
			}
	
			p{
				margin:0 5px;
				font-size: 22px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 100%;
			}

		}

		.switch-buttons {
			margin:15px 0 20px 0; //changed left margin from 15px to 0
			// height: 35px;
	
	
			.my-entry{
				height: 30px;
				line-height: 30px;
				background:#8e00f0;
				color: white;
				text-align: center;
				border-radius: 8px;
				width: 100px;
				font-size: 15px;
			}
		.winning-entry-slider{
			width: 260px;
			height: 35px;
			display: flex;
			justify-content: space-between;
			background-color: #4e406e;
			border-radius: 10px;
			box-sizing: border-box;
			padding: 4px;
	
			.button {
				border-radius: 10px;
				font-weight: 300;
				font-size: 15px;
				margin: 0 2px;
				padding: 0 15px;
				border:none;
				color: white;
				background-color: transparent;
				line-height: 30px;
				&.active {
					background: #8e00f0;
					color: #FFFFFF;
				}
			}
		}

		.author-name{
			margin:15px 0;
			p{
				font-size: 13px;

				&:last-of-type{
					font-size: 14px;
					color: #858585;
					margin-top: 5px;
				}
			}
		}
	
			.button {
				margin: 0 2px;
				padding: 0 15px;
				&.active {
					background: $blue;
					color: #FFFFFF;
				}
			}
		}

	}
	
	&__right {
		text-align: left; //swapped
		margin-top: -10px;

		.flexBtns{
			display: flex;
			align-items: center;
			width: 195px;
			justify-content: flex-end;
		}
	}
	&__title {
		font-size: 14px;
        font-family: $primaryFontBold;
		margin-bottom: 10px;
	}
	&__author {
		font-size: 14px;
	}
	&__count {
		color: $purple;
		font-size: 14px;
		&--alert {
			color: $red;
        	font-family: $primaryFontBold;
		}

		&__review{
			margin:15px 0 8px 0;
		}
	}

	&__voted{
		font-size: 13px;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 15px;
		
		&.red{
			color:red;
			
		}

		img{
			height: 15px;
			margin-right: 5px;
			position: relative;
			bottom: 2px;;
		}
	}

	.project-complete{
		font-size: 12px;
		width: 150px;
		height: 25px;
		line-height: 23px;
		text-align: center;
		background:#DAF7D3;
		color:#56AD46;
		border:2px solid #56AD46;
		border-radius: 8px;
	}


	&__numbers {
        font-family: $primaryFontBold;
	}
	.winner-name {
		width: 100%;
		text-align: center;
		padding: 10px;
		font-size: 22px;
		line-height: 22px;
		&_chapter {
			font-family: "Gotham Rounded Bold";
		}
	}
}

@media (min-width: 700px) {
	.project-entry-header {
		max-width: initial;
		flex-direction: row;
		&__left{
			.scroll-arrows{
				margin-left: 20px;
			}
			.switch-buttons {
				margin:15px 0 20px 15px;
			}
		}

		&__right {
			text-align: right;
		}
	}
}
// @media (max-height:700px){
// 	.project-entry-header{
// 		&__right{
// 			margin-top: 0;
// 		}
// 	}
// }

.previous-chapter {
	color: $defaultTextColor;
	margin-top: 20px;
	&__title {
		font-size: 14px;
        font-family: $primaryFontBold;
	}
	&__author {
		font-size: 14px;
		margin-bottom: 20px;
	}
	&__content {
		font-size: 16px;
		line-height: 24px;
		white-space: pre-line;
	}
}

.instructions__close--voting {
	background: none;
	border: none;
	display: block;
	height: 40px;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 40px;
}
.instructions__close-icon--voting {
	height: auto;
	width: 22px;
}

.writing-closed {
    align-items: center;
	background: $redLight;
	border-radius: 5px;
	display: flex;
    justify-content: center;
	padding: 10px;
	text-align: center;
	&__image {
		height: 18px;
		margin-right: 5px;
	}
}

.tickle-notification {
	align-items: center;
	animation: slideInNotification 6s ease 0.5s forwards;
	background: $greenLight;
	border-radius: 10px;
	border: 1px solid $green;
	color: $green;
	display: flex;
	font-size: 15px;
	justify-content: center;
	padding: 8px;
	position: fixed;
	top: 10px;
	right: -500px;
	z-index: 400;
	&__boomer-holder {
		height: 30px;
		margin-right: 5px;
		width: 30px;
	}
}

.open-rubric {
	background: $blue;
	border: 0;
	border-radius: 10px;
	color: #FFF;
	font-family: $primaryFont;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	position: relative;
	width: 100%;
	&__name {
		display: block;
		font-family: $primaryFontBold;
	  	overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 32px);
	}
	&__meta {
		right: 5px;
		top: 1px;
		position: absolute;
	}
	&--centered {
		text-align: center;
	}
	&:hover {
		background: darken($blue, 8%);
		cursor: pointer;
	};
}

.instruction__rubric-skill {
	background: $purpleDark;
	border-radius: 10px;
	color: #AAAAAA;
	font-family: $primaryFont;
	font-size: 12px;
	line-height: 1;
	margin-bottom: 5px;
	padding: 5px 25px 5px 10px; 
	position: relative;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.instruction__rubric-skill-score {
	background: #CCC;
	border-radius: 15px;
	color: #FFF;
	height: 22px;
	line-height: 22px;
	font-family: $primaryFontBold;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 22px;
	&--1 {
		background: $rubricBG1;
	}
	&--2 {
		background: $rubricBG2;
	}
	&--3 {
		background: $rubricBG3;
	}
	&--4 {
		background: $rubricBG4;
	}
}

.full-rubric {
	background: #FFF;
	color: #4a4a4a;
	height: 100%;
	left: 0;
	max-height: 100%;
	padding: 40px;
	position: absolute;
	overflow-y: scroll;
	top: 0;
	width: 100%;
	z-index: 500;
}

.fr-skill {
	display: flex;
	width: 100%;
}
.fr-skill:nth-child(even){
	background: #f7f6f6;
}

.fr-cell {
	flex: 1;
	padding: 40px 20px;
	&--scored {
		background: $blue;
		color: #FFF;
	}
}

.mob-skill {
	border: 1px solid #e4e3e3;
	border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.mob-criteria {
	padding: 30px 20px 30px 70px;
	position: relative;
	&--scored {
		color: $blue;
	}
	&:nth-child(even) {
		background: #f7f6f6;
	};
	&__score {
		background: #CCC;
		border-radius: 200px;
		color: #FFF;
		display: block;
		height: 40px;
		line-height: 40px;
		font-family: $primaryFontBold;
		font-size: 20px;
		left: 15px;
		position: absolute;
		text-align: center;
		top: calc(50% - 20px);
		width: 40px;
		&--1 {
			background: $rubricBG1;
		}
		&--2 {
			background: $rubricBG2;
		}
		&--3 {
			background: $rubricBG3;
		}
		&--4 {
			background: $rubricBG4;
		}
	}
}

.fr-heading {
	color: #FFF;
	padding: 10px;
	text-align: center;
	&--1 {
		background: $rubricBG1;
	}
	&--2 {
		background: $rubricBG2;
	}
	&--3 {
		background: $rubricBG3;
	}
	&--4 {
		background: $rubricBG4;
	}
}

.fr-close {
	background: $red;
	border-radius: 100px;
	color: #FFF;
	cursor: pointer;
	font-family: $primaryFontBold;
	font-size: 18px;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 35px;
	text-align: center;
	top: 30px;
	width: 30px;
	&:hover {
		background: darken($red, 5%);
	};
}

.large-rubric {
	display: none;
}
.mobile-rubric {
	display: block;
}
@media screen and (min-width: 900px) {
	.large-rubric {
		display: block;
	}
	.mobile-rubric {
		display: none;
	}
}

@media screen and (max-width: $tablet) {
	.writing-container {
		&--preview {
			padding: 0;
		}
		&--preview, &--norel {
			position: initial;
		}
	}
	.project-tab--instructions {
		background: $purpleLight;
		border: none;
		border-radius: 20px;
		color: $yellowLight;
		flex: 0;
		font-size: 12px;
		line-height: 16px;
		padding: 5px 10px 5px 25px;
		position: absolute;
		right: 0;
		top: -95px;
	}
	.project-side--preview {
		bottom: 0;
		height: calc(100vh - 58px);
		left: 0;
		padding: 30px 20px 60px 20px;
		position: absolute;
		top: 58px;
		// width: 100vw;
		z-index: 51;
	}
	.project-side--instructions {
		height: 100vh;
		top: 0;
	}
	.instruction-boomers {
		display: block;
		height: auto;
		margin: 0 auto 20px auto;
		padding: 30px 20px 20px 20px;
		width: 110px;
	}
	.project-tab__info {
		display: block;
		left: -10px;
		position: absolute;
		height: auto;
		top: -2px;
		width: 30px;
	}
	.instructions__close {
		background: none;
		border: none;
		display: block;
		height: 40px;
		position: absolute;
		right: 10px;
		top: 10px;
		width: 40px;
	}
	.instructions__close-icon {
		height: auto;
		width: 22px;
	}
}

@media screen and (min-width: $tablet) {
	.project {
		padding: 20px 20px 20px 140px;
	}
	.project__content {
		height: calc(100vh - 80px);
	}
	.project__content {
		padding: 0;
	}
	.instructions-mob {
		display: none;
		&--voting {
			display: block;
		}
	}
	.project__mobile-header {
		display: none;
	}
	.project-tab {
		&--instructions {
			border: none;
			background: $purple;
			color: #FFF;
			flex: 1;
			position: initial;
			right: initial;
			top: initial;
			&.project-tab--default {
				color: $yellowLight;
			}
		}
	}
	.project-tabs {
		&--preview {
			display: flex;
		}
		&--hide-mob{
			display: none;
		}
	}
	.project-side--preview {
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
}

@media screen and (max-width: $desktop) {
	.instruction-boomers--voting {
		    display: block;
    height: auto;
    margin: 0 auto 20px auto;
    padding: 30px 20px 20px 20px;
    width: 150px;
	}
	.project-entry--edit {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	.project-text--hide {
		display: none;
	}
}

@media screen and (min-width: $desktop) {
	.project {
		padding: 40px 40px 40px 160px;
	}
	.boomer-heading--project {
		margin-bottom: 40px;
	}
	.writing-container {
		display: flex;
		max-width: 1200px;
		padding: 0 0 0 0;
		&--generic-preview {
			display: flex;
			flex-direction: column;
			max-width: 900px;
		}
	}
	.project__content {
		height: calc(100vh - 120px);
	}
	.project-text, .project-aside {
		flex: 1;
		padding: 0 20px 0 0;
	}
	.project-aside {
		padding: 50px 0 20px 20px;
		position: relative;
		flex: 0;
		min-width: 425px;
		max-width: 425px;
		&--generic-preview {
			flex: 1;
			max-width: initial;
			min-width: initial;
			padding-left: 0;
		}
	}
	.instructions__content {
		height: 100%;
	}
	.project-tabs {
		left: 20px;
		&--preview {
			display: flex;
		}
		&--generic-preview {
			left: 0;
		}
	}
	.project-tab {
		border: 1px solid $purple;
		flex: 0;
		&--entry {
			display: none;
		}
		&--default {
			color: $blue;
		}
		&--active, &--default {
			background: #FFF;
			border-bottom: 1px solid #FFF;
		}
		&--instructions {
			background: $purple;
			border-bottom: 1px solid $purple;
		}
	}
	.project-side {
		border: 1px solid $purple;
		height: calc(100% - 50px);
		border-top-left-radius: 0;
		border-top-right-radius: 15px;
		&--generic-preview {
			height: 100%;
		}
	}
	.project-side--default {
		display: block;
	}
	.project-next--mob, .instructions__close--voting, .instructions-mob--voting {
		display: none;
	}
	.project-side--preview {
		background: $purple;
	}
	.instruction--hidden-desktop {
		display: none;
	}
}

@-webkit-keyframes slideInNotification {
    0% {
        right: -500px;
    }
    10% {
        right: 10px;
	}
	90% {
		right: 10px;
	}
    100% {
        right: -500px;
    }
}

@keyframes slideInNotification {
    0% {
        right: -500px;
    }
    10% {
        right: 10px;
	}
	90% {
		right: 10px;
	}
    100% {
        right: -500px;
    }
}