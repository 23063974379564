.split50 {
	width: 100%;
}

.user-view {
	display: block;
    height: calc(100vh - 85px);
    overflow-y: scroll;
    padding: 20px;
	width: 100%;
	&--fullscreen-mob {
		height: 100vh;
		padding: 0;
	}
}

@media screen and (max-width: $smallMobile) {
	.user-view {
		padding: 10px;
		&--fullscreen-mob {
			height: 100vh;
			padding: 0;
		}
	}
}

@media screen and (min-width: $mobile) {
	.user-view {
		padding: 30px;
		&--fullscreen-mob {
			padding: 0;
		}
	}
}

@media screen and (min-width: $tablet) {
	.user-view {
		float: left;
		padding: 30px 30px 30px 150px;
		height: initial;
		min-height: 100vh;
		width: 100%;
		&--has-sub-menu {
			padding: 30px 30px 30px 325px;
		}
		&--fullscreen-mob {
			height: auto;
		}
	}
	// .fullscreen-mob {
	// 	padding: 30px 30px 30px 150px;
	// }		
}

@media screen and (min-width: $desktop) {
	.user-view {
		padding: 50px 50px 50px 170px;
		&--has-sub-menu {
			padding: 50px 50px 50px 345px;
		}
	}
	// .fullscreen-mob {
	// 	padding: 50px 50px 50px 170px;		
	// }
	.split50 {
		width: 50%;
		float: left;
	}
}
