.masonry-grid {
    column-count: 1;
    column-gap: 20px;
}

/* chrome renders the css grid slightly differently to other browsers so we add this class to the children of the grid container */
.chrome-item {
    -webkit-column-break-inside: avoid;
    break-inside: avoid-column;
}
/* this is for non chrome browsers */
.masonry-item {
	display: inline-block;
}

@media screen and (min-width: 700px) {
	.masonry-grid {
	    column-count: 2;
	}
}
@media screen and (min-width: 750px) {
	.masonry-grid {
	    column-count: 1;
	}
}
@media screen and (min-width: 840px) {
	.masonry-grid {
	    column-count: 2;
	}
}
@media screen and (min-width: 1000px) {
	.masonry-grid {
	    column-count: 1;
	}
}
// @media screen and ($desktop) {
// 	.masonry-grid {
// 	    column-count: 1;
// 	}
// }
@media screen and (min-width: 1260px) {
    .masonry-grid {
        column-count: 2;
    }
}

@media screen and (min-width: 1600px) {
	.masonry-grid {
	    column-count: 3;
	}
}