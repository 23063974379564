@import './../UnitInstructions.module.scss';

.thumbnail {
    width: 120px;
    height: 75px;
    border-radius: 10px;
    position: relative;
    margin: 2px;
    overflow: hidden;

    img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
}

.hoverContainer {
    background: transparent;
    width: 120px;
    height: 75px;
    border-radius: 10px;
    position: absolute;
    // border: 4px solid $main-purple--hover;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    &.hide {
        display: none;
    }

    .btnWrapper {
        height: 30px;
        width: 90px;
        .bordered {
            border: 4px solid $main-yellow--bright;
            box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            font-size: 16px;
            line-height: 20px;
            padding: 1px 0px 1px 0px;
            &:hover {
                box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.3);
                padding: 2px 0px 0px 0px;
            }
        }
    }
}
