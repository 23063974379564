@media screen and (max-width: $mobileLimit) {
    .sub-menu {
        background: lighten($blue, 30%);
        border-radius: 12px;
        overflow: hidden;
        &__title {
            display: none;
        }
        &__items {
            display: flex;
        }
        &__item {
            border-right: 1px solid lighten($blue, 20%);
            flex: 1;
            &:last-child {
                border: none;
            }
        }
        &__link {
            color: #FFF;
            display: block;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 9px;
            text-align: center;
            text-decoration: none;
            &:hover {
                background: darken($blue, 10%);
            }
            &.active {
                background: $blue;
            }
        }
    }
}
@media screen and (max-width: $smallMobile) {
	.sub-menu {
		&__link {
            font-size: 16px;
            padding: 10px 5px;
        }
	}
}
@media screen and (min-width: $tablet) {
    .sub-menu {
        background: $purpleDark;
        border-left: 1px solid lighten($purple, 10%);
        display: block;
        font-weight: bold;
        height: 100%;
        left: 120px;
        position: fixed;
        width: 175px;
        text-align: center;
        top: 0;
        &__title {
            color: $yellow;
            font-size: 18px;
            padding: 20px;
            
        }
        &__item {
            border-top: 1px solid lighten($purple, 10%);
            margin: 0 20px;
            &:last-child {
                border-bottom: 1px solid lighten($purple, 10%);
            }
        }
        &__link {
            color: #FFF;
            display: block;
            font-size: 14px;
            margin: 0 -20px;
            padding: 20px;
            text-decoration: none;
            transition: all 0.1s ease;
            &:hover {
                background: darken($purpleDark, 10%);
            }
            &.active {
                background: $blue;
            }
        }
    }
}