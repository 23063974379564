@import "../../newShared.scss";

.classColumn {
  width: 400px;
  height: 700px;
  background-color: $main-purple;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  box-shadow: inset 0px -8px 0px 0px $shadow-purple;
  @media (max-width:1100px){
    display:none;
  }


  .classColumnHeader {
    padding: 1.5em;
    border-bottom: 0.5px solid #CACACA;
    h1 {
      color: white;
    }
    div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        margin-right: 10px;
      }
      p {
        color: white;
      }
    }
  }

  .pickerRow{
    display: flex;
    border-bottom: 0.5px solid #CACACA;
    .pickerBtn{
      display: flex;
      color: #a79fb7;
      align-items: center;
      font-weight: bold;
      padding: 1.25em 1em;
      font-size: 14px;
      position: relative;
      &:hover{
        background: rgba(255, 255, 255, 0.1);
      }
      &:after{
        content: '';
        width:72px ;
        height: 0px;
        position:absolute;
        bottom: 0;
        border-radius: 10px 10px 0px 0px;
        background-color: white;
        left: calc( (100% - 72px)/2);
        transition: height 200ms ease;
      }
      &.single{
        flex-grow: 2;
      }
      &.active{
        color:white;
        &:after{
          height: 3px;
        }
      }
      p{
        margin-left:10px
      }
    }
  }
}
