@import "./../../../../newShared.scss";

.projectBullet {
  background-color: $main-grey;
  color: $alternative-darkgrey;
  border-radius: 16px;
  width: 90%;
  margin: 0.5em 0em 0.25em 2em;
  padding: 0 2em;
  font-size: 14px;
  min-height: 30px;
  display: flex;
  position: relative;
  transition: all 200ms ease;
  box-shadow: inset 0px -4px 0px 0px #d2d2d2;
  display: flex;
  // justify-content: center;
  align-items: center;
  

  &:hover {
    background-color: $alternative-mediumgrey;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .infoDiv {
    color: white;
    border-radius: 16px;
    width: 37px;
    background-color: $main-mediumgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    &.storie {
      background-color: $main-green;
    }
    &.journal {
      background-color: $main-orange;
    }
    &.assignment {
      background-color: $main-blue;
    }
  }
}

@media (max-width: 1200px) {
  .projectBullet {
    width: 96%;
   
    margin: 0.5em 0em 0.25em 0.25em;

    p {
      width: calc(100% - 36px);
    }
  }
}

@media (max-width: 1000px) {
  .projectBullet {
    width: 90%;
    padding-left: 10px;
    p {
      width: calc(100% - 36px);
    }
  }
}

@media (max-width: 510px) {
  .projectBullet {
    width: 70%;
  }
}

@media (max-width: 370px) {
  .projectBullet {
    width: 50%;
  }
}
