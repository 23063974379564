.chat-toggle {
	background-color: $blue;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 25px auto;
	border: none;
	border-radius: 200px;
	box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
	color: #fff;
	cursor: pointer;
	font-family: $primaryFontBold;
	font-size: 16px;
	position: absolute;
	right: 10px;
	top: 25px;
	height: 40px;
	text-align: center;
	padding: 0;
	width: 40px;
	z-index: 200;
	&--new {
		animation: chat-pulse 1.5s infinite;
	}
}
.chat-loader {
	display: block;
	margin: 20px auto;
}
.chat-graphic {
	height: auto;
	margin-bottom: 20px;
	width: 100px;
}
.chat-main__default {
	align-items: center;
	display: flex;
	border-left: 1px solid #f4f4f4;
	flex: 1;
	justify-content: center;
}
@-webkit-keyframes chat-pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(242, 98, 98, 1);
	}
	70% {
		-webkit-box-shadow: 0 0 0 7px rgba(242, 98, 98, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(242, 98, 98, 0);
	}
}

@keyframes chat-pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(242, 98, 98, 1);
		box-shadow: 0 0 0 0 rgba(242, 98, 98, 1);
	}
	70% {
		-moz-box-shadow: 0 0 0 7px rgba(242, 98, 98, 0);
		box-shadow: 0 0 0 7px rgba(242, 98, 98, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(242, 98, 98, 0);
		box-shadow: 0 0 0 0 rgba(242, 98, 98, 0);
	}
}

.chat-modal {
	background: #ffffff;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 500;
	font-family: "Gotham Rounded Book";
	text-transform: capitalize;
}

.chat-mob-close {
	background: $purple;
	height: 50px;
	position: relative;
}

.chat-mob-close__btn {
	background: none;
	border: none;
	padding: 0;
	position: absolute;
	top: 10px;
	right: 10px;
}
.chat-mob-close__btn-img {
	cursor: pointer;
	height: 25px;
	width: auto;
}

.chat-mob__return-btn {
	position: absolute;
	left: 5px;
	top: 5px;
}

.chat-modal-inner {
	align-items: center;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 20px;
	overflow-y: scroll;
	text-align: center;
	padding: 30px;
	&--hide {
		display: none;
	}
}

.chat-contact {
	color: #4a4a4a;
	cursor: pointer;
	line-height: 30px;
	margin: 20px 0;
	padding: 0 0 0 35px;
	position: relative;
	text-align: left;
	&:hover {
		color: $blue;
	}
}

.chat-contact__ava {
	background: $blue;
	border-radius: 200px;
	height: 30px;
	left: 0;
	position: absolute;
	top: 0;
	width: 30px;
}

@media screen and (max-width: $desktop) {
	.chat-contact {
		font-size: 22px;
		padding: 10px 0 10px 70px;
		margin: 30px 0;
	}
	.chat-contact__ava {
		height: 60px;
		width: 60px;
		top: -5px;
	}
}

.chat-main {
	display: none;
	flex-direction: column;
	height: 100%;

	&--show {
		display: flex;
	}
}

.chat-main__header {
	background: #f4f4f4;
	box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 5;
	text-align: center;
	.chat-contact {
		display: inline-block;
		cursor: default;
		&:hover {
			color: #4a4a4a;
		}
	}
}
.chat-main__message-body {
	flex: 1;
	overflow-y: scroll;
	padding: 20px 20px 0 20px;
}
.chat-main__message {
	margin-bottom: 20px;
	position: relative;
	padding-left: 60px;
	text-align: left;
	font-weight: normal;

	&--self {
		text-align: right;
		padding-left: 0;
		padding-right: 60px;
	}
}
.chat-main__ava {
	border-radius: 200px;
	height: 50px;
	left: 0;
	position: absolute;
	width: 50px;
	&--self {
		left: initial;
		right: 0;
	}
}
.chat-contact__unread {
	background: #f26262;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
	border-radius: 50px;
	display: block;
	height: 10px;
	left: 22px;
	position: absolute;
	width: 10px;
	animation: chat-pulse 1s infinite;
}
.chat-main__message__text {
	background: $blue;
	border-radius: 10px;
	color: #fff;
	min-height: 60px;
	padding: 10px;
	&--self {
		background: #efefef;
		color: #4a4a4a;
	}
}
.chat-main__message__date {
	color: #a6a6a6;
	font-size: 12px;
	margin-top: 5px;
	padding: 0 10px;
	&--self {
		text-align: right;
	}
}
.chat-main__textarea {
	padding: 20px;
}
.chat-main__textarea .button {
	float: right;
}
.chat-main__textarea__input {
	border: 1px solid #ccc;
	border-radius: 10px;
	font-size: 16px;
	height: 70px;
	padding: 10px;
}

@media screen and (min-width: $desktop) {
	.chat-toggle {
		background-color: $purple;
		right: 20px;
		top: 20px;
	}
	.chat-overlay {
		background: rgba(0, 0, 0, 0.5);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 499;
	}
	.chat-modal {
		border-radius: 15px;
		bottom: 75px;
		flex-direction: initial;
		justify-content: initial;
		left: calc(50% - 350px);
		right: initial;
		top: 75px;
		width: 800px;
	}
	.chat-modal-inner {
		align-items: initial;
		justify-content: initial;
		margin-bottom: 0;
		padding: 10px 20px;
		width: 250px;
		&--hide {
			display: flex;
		}
	}
	.chat-mob__return-btn {
		display: none;
	}
	.chat-mob-close {
		background: red;
		border-radius: 200px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
		height: 33px;
		position: absolute;
		right: -15px;
		top: -15px;
		width: 33px;
		&__btn {
			right: 4px;
			top: 4px;
		}
	}
	.chat-main {
		display: flex;
		padding: 20px;
		width: 550px;
		&--hide {
			display: flex;
		}
		&__header {
			box-shadow: none;
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
		}
		&__message-body {
			border: 1px solid #f4f4f4;
		}
		&__textarea {
			padding: 0;
			margin-top: 10px;
			&__input {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
		}
	}
}
