@import './../../newShared.scss';

.startEnd {
  display: flex;
}
.date {
  width: 75px;
  height: 25px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 11px;
  line-height: 22px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 4px 0.5em 4px 0em;
  &.dark{
    background: rgba(0, 0, 0, 0.1);
    color: $main-black;
  }
  p {
    margin-bottom: 0;
  }
}