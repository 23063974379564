@import "./../MainWritingEditor.module.scss";

.entryHeader {
  font-family: Gotham Rounded Bold;
  font-style: normal;
  // font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: $main-purple;
  position: relative;
  margin-top: -15px;
  margin-left: -5px;
  margin-bottom: 15px;
  height: 70px;
  display: flex;
  align-items: center;
  h2 {
    margin-left: 70px;
  }
}

@media (max-width: 700px) {
  .entryHeader {
    font-size: 18px;
    line-height: 18px;
  }
}

@media (max-width: 500px) {
  .entryHeader {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-height:700px){
  .entryHeader{
    display: none;
  }
}
