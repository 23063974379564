.main-loader {
	align-items: center;
	background-color: rgba(255, 255, 255, 1);
	color: $purple;
	display: flex;
	flex-direction: column;
    font-family: $primaryFontBold;
	font-size: 24px;
	height: 100%;
	justify-content: center;
	position: fixed;
	right: 0;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 200;
	&__svg {
		margin-top: -150px;
		width: 200px;
	}
	&__img {
		width: 200px;
	}
	&__message {
		margin-top: -50px;
	}
	&--fill-parent {
		background-color: rgba(255, 255, 255, 0.5);
		left: 0;
		position: absolute;
		top: 0;
	}
	&--project {
		background: #F3F3F3;
		top: 58px;
	}
}
.u-full-width {
	.main-loader {
		width: 100% !important;
	}
}

@media screen and (min-width: $tablet) {
	.main-loader {
		width: calc(100% - 120px);
		&--has-sub-menu {
			width: calc(100% - 295px);
		}
		&--fill-parent {
			width: 100%;
		}
		&--project {
			top: 50px;
		}
	}
}

@media screen and (min-width: $desktop) {
	.main-loader--project {
		top: 60px;
	}
}