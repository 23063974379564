@import "./../UnitCard.module.scss";

.status {
  display: flex;
  position: absolute;
  align-items: center;
  width: 180px;
  height: 44px;
  padding: 10px;
  top: 15px;
  right: 40px;
  border-radius: 10px;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.dueDatePassed {
    background-color: $main-grey;
    box-shadow: none;
    padding: 10px;

    p {
      font-size: 12px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #007bff transparent transparent transparent;
  }
  &.pink {
    background: linear-gradient(94.11deg, #f26283 0.33%, #fe875f 100.5%);
    &:after {
      border-color: #fe875f transparent transparent transparent;
    }
  }
  &.purple {
    background: linear-gradient(180deg, #065fe5, #8e00f0);
    &:after {
      border-color: #8e00f0 transparent transparent transparent;
    }
  }
  &.yellow {
    background: linear-gradient(143.85deg, #ffa826 14.37%, #ff8258 93.54%);
    &:after {
      border-color: #ff8258 transparent transparent transparent;
    }
  }
  p {
    font-family: "Gotham Rounded Bold";
    font-size: 13px;
    line-height: 17px;
    color: white;
  }
  .hangingBoomer {
    position: absolute;
    bottom: -80px;
    right: -30px;
  }
}

.finishStatus {
  display: flex;
  position: absolute;
  top: 35px;
  right: 30px;
  align-items: center;
  font-family: "Gotham Rounded Bold";
  color: black;
  font-size: 18px;
  text-transform: uppercase;
  p {
    margin-left: 0.5em;
  }
}

@media (max-width: 1300px) {
  .status {
    width: 140px;
    padding: 10px 0em;
    right: 5px;
    font-size: 14px;
    .hangingBoomer {
      position: absolute;
      bottom: -80px;
      right: 5px;
    }
  }
}
