@import "./../NewDashboard/newShared.scss";

$whiteOff: rgba(
  $color: white,
  $alpha: 0.1,
);

.navBar {
  background: $main-purple;
  bottom: 0;
  color: $main-yellow;
  display: block;
  font-family: "Gotham Rounded Book";
  height: 100%;
  position: absolute;
  width: 120px;
  z-index: 450;
  display: flex;
  flex-direction: column;
  padding: 1em 0em 0em 0em;
  .userTitle {
    padding: 0.75em;
    padding-bottom: 0;
    text-align: center;
    .name {
      display: block;
      font-family: "Gotham Rounded Bold";
      border-bottom: 1px solid $whiteOff;
      padding: 0.5em;
    }
    .coinsContainer {
      border-bottom: 1px solid $whiteOff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em 0em;
      span {
        font-weight: bold;
        padding-left: 0.5em;
      }
      img {
        width: 18px;
      }
    }
  }

  .ul {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .listItem {
      position: relative;
      cursor: pointer;
      display: block;
      font-size: 14px;
      font-family: "Gotham Rounded Bold";
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      .itemContent {
        border-bottom: 1px solid $whiteOff;
        margin: 0 0.75em;
        padding: 10px 0;
        transform: translateZ(1px);
        p {
          padding: 5px 0;
          color: #ffce89;
        }
        img {
          width: 45px;
        }
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 100%;
        z-index: 0;
        background-color: #00a5ff;
        transition: right 0.15s ease;
      }
      &:hover {
        color: #fff;
        &:before {
          right: 0;
        }
      }
    }
  }

  .activeLink {
    background-color: $main-purple--hover;
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 5px;
      top: 0;
      left: 0;
      right: 100%;
      background-color: #00a5ff;
    }
  }

  .copyright {
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    display: block;
    color: #bfbfbf;
    margin: 15px 0px;
  }
}

@media (max-width: 750px) {
  .navBar {
    width: 100%;
    height: 85px;
    flex-direction: row;
    padding: 0;
    .userTitle {
      display: none;
    }
    .ul {
      flex-grow: 1;
      flex-direction: row;
      .split1 {
        display: flex;
        width: 60%;
        li {
          width: 33.33%;
        }
      }
      .split2 {
        display: flex;
        width: 40%;
        li {
          width: 50%;
        }
      }
      .listItem {
        height: 100%;
        border-right: 1px solid $whiteOff;

        .itemContent {
          height: 100%;
          border-bottom: 0px solid $whiteOff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: auto;
            height: 40px;
          }
        }
      }
    }
    .copyright {
      display: none;
    }
  }
}

@media (max-width: 520px) {
  .navBar {
    .ul {
      .listItem {
        .itemContent {
          img {
            width: auto;
            height: 30px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 340px) {
  .navBar {
    .ul {
      .listItem {
        .itemContent {
          img {
            width: auto;
            height: 30px;
          }
          p {
            font-size: 8px;
          }
        }
      }
    }
  }
}
@media (min-width: 750px) and (max-height: 650px) {
  .navBar {
    .userTitle {
      padding: 0.25em;
      font-size: 12px;
      .name {
        font-size: 12px;
      }
      .coinsContainer {
        padding: 0.5em 0em;
        img {
          width: 14px;
        }
      }
    }
    .ul {
      .listItem {
        .itemContent {
          p {
            font-size: 10px;
          }
          img {
            width: 25px;
          }
        }
      }
    }
  }
}
