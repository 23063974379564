.main-class {
	position: relative;
	max-width: 1200px;
}

.class-title {
	color: #4a4a4a;
	font-size: 28px;
	font-family: $primaryFontBold;
	width: calc(100% - 200px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.class-teacher {
	color: #4a4a4a;
	padding-left: 35px;
	position: relative;
	margin: 15px 0;
	&__img {
		border-radius: 100px;
		height: auto;
		left: 0;
		top: -8px;
		position: absolute;
		width: 30px;
	}
}
.class-students {
	position: absolute;
	right: -20px;
	top: 0;
}

.class-filters {
	border-top: 1px solid #e8e5e5;
	margin-top: 20px;
	padding-top: 20px;
}

.class-filter {
	background: none;
	border: none;
	border-radius: 10px;
	font-family: $primaryFontBold;
	color: #4a4a4a;
	font-size: 12px;
	padding: 7px 12px;
	&--active {
		background: orange;
		color: #FFF;
	}
}

.class-project {
	background: #7015d0;
	border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.4);
	color: #FFF;
	display: block;
	height: 140px;
	margin-top: 20px;
	padding-right: 200px;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	&__meta {
		background: lighten(#7015d0, 5%);
		height: 100%;
		padding: 20px 0 20px 20px;
        position: relative;
        text-decoration: none;
        width: 100%;
        .meta-child {
        	position: relative;
        	z-index: 2;
        }
        &:before {
            background: lighten(#7015d0, 5%);
            border-radius: 800px;
            content: '';
            right: -30px;
            top: -120px;
            position: absolute;
            height: 350px;
            width: 350px;
            z-index: 0;
        }
	}
	&__title {
		font-size: 20px;
		font-family: $primaryFontBold;
		line-height: 24px;
		margin-bottom: 2px;
	 	width: 100%;
	  	white-space: nowrap;
	  	overflow: hidden;
		text-overflow: ellipsis;
	}
	&__phase {
		background: #FFF;
		border-radius: 20px;
		color: #000;
		display: inline-block;
		font-family: $primaryFontBold;
		font-size: 14px;
		margin-top: 10px;
		padding: 5px 10px;
	}
	&__deadline {
		color: orange;
		font-size: 14px;
		font-family: $primaryFontBold;
		position: absolute;
		left: 100px;
		top: calc(50% - 7px);
		width: 100px;
		z-index: 2;
	}
	&__alarm {
		height: 16px;
		position: absolute;
		right: -22px;
		top: 2px;
		width: auto;
		z-index: 2;
	}
	&__hero {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 120px auto;
		background-image: url('../images/writing-boomer.png');
		height: 145px;
		position: absolute;
		right: 0px;
		top: 0;
		width: 170px;
		&--voting {
			background-image: url('../images/voting-boomer.png');
		}
		&--reading {
			background-image: url('../images/reading-boomer.png');
			background-size: 100px auto;
		}
	}
}

.class-project--alt {
	background: orange;
	color: #4a4a4a;
	.class-project__meta:before, .class-project__meta {
        background: lighten(orange, 8%);
	};
	.class-project__deadline {
		color: #7015d0;
	}
}

@media screen and (min-width: 920px) {
	.class-project {
		float: left;
		width: calc(50% - 10px);
	}
	.class-project:nth-child(odd) {
		margin-right: 20px;
	}
}

@media screen and (min-width: 1400px) {
	.class-project {
		width: calc(33% - 10px);
		margin-right: 20px;
	}
	.class-project:nth-child(3n+3) {
		margin-right: 0;
	}
}
