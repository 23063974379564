@import "./../newShared.scss";

.adaptToScreen {
  background-repeat: repeat;
  height: auto;
  min-height: 100vh;
  &.center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.classHeader {
  background-color: $main-purple;
  color: white;
  padding: 1.25em;
  max-width: 515px;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  margin-bottom: 2em;
  position: relative;
  box-shadow: inset 0px -4px 0px 0px $shadow-purple;

  .teacherInfo{
    display: flex;
    align-items: center;
    margin-top: 1em;
    figure{
      width: 30px;
      height: 30px;
      margin-right:0.5em;
      img{
        width: 100%;
        border-radius: 50px;
      }
    }
  }
  .deco{
    position: absolute;
    top:0;
    right:0;
    width:85px
  }
}

.pickerBtnContainer {
  display: flex;
}

.mainDisplay{
  max-width: 800px;
  // overflow-y: auto;
  // height: 500px
}
