@import "./../MainWritingEditor.module.scss";

.introVideoButton {
  position: absolute;
  left: 10px;
  bottom: 22px;
  background-color: $main-blue--dark;
  color: white;
  width: 220px;
  height: 45px;
  transition: all 0.3s cubic-bezier(0.12, -0.23, 0.75, 0.87);
  font-family: Gotham Rounded Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
  color: #ffffff;
  border-bottom: 4px solid $main-blue--dark--shadow;

  &.shiftRight{
    left:140px
  }

  img.closeIcon{
    position: absolute;
    top:-8px;
    right:-8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 50;
  }
  
  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    margin-top: 2px;
  }
  &:hover{
  border-bottom: 2px solid $main-blue--dark--shadow;
  }

  &.hide{
    bottom: -55px;
    opacity: 0;
  }
}
