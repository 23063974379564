@import "./../MainWritingEditor.module.scss";

.breakoutContainer {
  padding: 10px;
  height: 100%;
  border-radius: 10px;

  .infoBox {
    background-color: #2c9ff7;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    padding: 1em;
    box-shadow: inset 0px -5px 0px 0px #065FE5;
    position: relative;

    .infoBoxText {
      margin-left: 1em;
      h6 {
        font-family: "Gotham Rounded Bold";
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .imageFrame {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.45));
      figure {
        border-radius: 100%;
        background-color: white;
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          width: 130%;
          left: -6px;
          top: -5px;
        }
      }
    }
    .closeBtn {
      cursor: pointer;
      width: 35px;
      height: 35px;
      color: white;
      position: absolute;
      font-weight: bold;
      top: 17px;
      right: 15px;
      border-radius: 100%;
      font-family: "Gotham Rounded Bold";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background:url('../../../../../../images/btn_back.svg') no-repeat center;
    }
  }
  .textContainer {
    padding: 20px;
    height: 88%;
    overflow-y: auto;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 17px;
    color: #4a4a4a;
  }
}
