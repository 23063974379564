$schoolColor00: #7b389e;
$schoolColor01: #8e44ad;
$schoolColor02: #9b59b6;

$schoolColor10: #c0392b;
$schoolColor11: #e74c3c;
$schoolColor12: #ff5d55;

$schoolColor20: #d35400;
$schoolColor21: #e67e22;
$schoolColor22: #f39c12;

$schoolColor30: #1f9b51;
$schoolColor31: #27ae60;
$schoolColor32: #2ecc71;

$schoolColor40: #0f846e;
$schoolColor41: #16a085;
$schoolColor42: #1abc9c;

$schoolColor50: #375d7c;
$schoolColor51: #2980b9;
$schoolColor52: #3498db;

.school-text-00 {
    color: $schoolColor00;
}
.school-text-01 {
    color: $schoolColor01;
}
.school-text-02 {
    color: $schoolColor02;
}
.school-text-10 {
    color: $schoolColor10;
}
.school-text-11 {
    color: $schoolColor11;
}
.school-text-12 {
    color: $schoolColor12;
}
.school-text-20 {
    color: $schoolColor20;
}
.school-text-21 {
    color: $schoolColor21;
}
.school-text-22 {
    color: $schoolColor22;
}
.school-text-30 {
    color: $schoolColor30;
}
.school-text-31 {
    color: $schoolColor31;
}
.school-text-32 {
    color: $schoolColor32;
}
.school-text-40 {
    color: $schoolColor40;
}
.school-text-41 {
    color: $schoolColor41;
}
.school-text-42 {
    color: $schoolColor42;
}
.school-text-50 {
    color: $schoolColor50;
}
.school-text-51 {
    color: $schoolColor51;
}
.school-text-52 {
    color: $schoolColor52;
}


.school-theme-00 {
    background: $schoolColor00;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor01;
    }
}

.school-theme-01 {
    background: $schoolColor01;
    .icon-holder, .school-district, .school-theme-alt{
        background: $schoolColor02;
    }
}

.school-theme-02 {
    background: $schoolColor02;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor01;
    }
}
.school-theme-10 {
    background: $schoolColor10;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor11;
    }
}

.school-theme-11 {
    background: $schoolColor11;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor12;
    }
}

.school-theme-12 {
    background: $schoolColor12;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor11;
    }
}

.school-theme-20 {
    background: $schoolColor20;
    .icon-holder, .school-district, .school-theme-alt{
        background: $schoolColor21;
    }
}

.school-theme-21 {
    background: $schoolColor21;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor22;
    }
}

.school-theme-22 {
    background: $schoolColor22;
    .icon-holder, .school-district, .school-theme-alt{
        background: $schoolColor21;
    }
}


.school-theme-30 {
    background: $schoolColor30;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor31;
    }
}

.school-theme-31 {
    background: $schoolColor31;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor32;
    }
}

.school-theme-32 {
    background: $schoolColor32;
    .icon-holder, .school-district, .school-theme-alt{
        background: $schoolColor31;
    }
}

.school-theme-40 {
    background: $schoolColor40;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor41;
    }
}

.school-theme-41 {
    background: $schoolColor41;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor42;
    }
}

.school-theme-42 {
    background: $schoolColor42;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor41;
    }
}

.school-theme-50 {
    background: $schoolColor50;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor51;
    }
}

.school-theme-51 {
    background: $schoolColor51;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor52;
    }
}

.school-theme-52 {
    background: $schoolColor52;
    .icon-holder, .school-district, .school-theme-alt {
        background: $schoolColor51;
    }
}