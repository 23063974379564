// temporary for /slider route only - should be deleted 
.demoOnlyWrapper {
    padding: 1.5em 3em 3em 160px;
  }
  @media (max-width: 1300px) {
    .demoOnlyWrapper {
      padding: 1.5em 1em 2em 160px;
    }
  }
  @media (max-width: 1000px) {
    .demoOnlyWrapper {
      padding: 1.5em 1em 1em 140px;
    }
  }
  @media (max-width: 750px) {
    .demoOnlyWrapper {
      padding: 15px;
    }
  }
// END temporary


$tablet: 768px;
$desktop: 1200px;

@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .sliderWrapper {
        margin-right: 1.5em;// Undo some entryRouterAdjust styles 
    }
}
.slide {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 50px 20px 40px;
    color: #fff;
    overflow: hidden;
    -webkit-transform:translate3d(0,0,0);
    -webkit-backface-visibility: hidden;    
    -webkit-transform-style: preserve-3d;
    transform:translate3d(0,0,0);
    backface-visibility: hidden;    
    transform-style: preserve-3d;
    &:focus {
        outline: none;
    }
}

.slideWhite {
    background: #FFFFFF;
    color: #4A4A4A;
}
.slideGreen,
.slideRed {
    background: #75B800;
    color: #fff;
}
.slideRed {
    background: #D01D1D;
}

// content
.slideIntro {
    text-align: center;
    h2 {
        font-size: 30px;
    }
}
@media screen and (min-width: $tablet) {
    .slideIntro {
        h2 {
            font-size: 40px;
            text-align: left;
        }
    }
}
@media screen and (min-width: $desktop) {
    .slideIntro {
        h2 {
            font-size: 57px;
        }
    }
}

.centerContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &Title,
    &Title2, &TitleAlt {
        font-size: 24px;
        font-family: "Gotham Rounded Bold";
        text-align: center;
        max-width: 800px;
    }
    &Img {
        img {
            max-width: 100%;
            max-height: 400px;
        }
    }
}

@media screen and (min-width: $tablet) {
    .centerContent {
        &Title {
            font-size: 60px;
        }
        &Title2, &TitleAlt {
            font-size: 40px;
        }
    }
}
@media screen and (min-width: $desktop) {
    .centerContent {
        &Title {
            font-size: 70px;
        }
        &Title2 {
            font-size: 50px;
        }
        &TitleAlt {
            font-size: 30px;
        }
    }
}


.sideContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &Title {
        font-size: 50px;
        font-family: "Gotham Rounded Bold";
    }
}

@media screen and (min-width: $tablet) and (max-width: 800px) {
    .sideContent {
        flex-direction: row;
        &Title {
            font-size: 60px;
            flex-basis: 50%;
        }
    }
}
@media screen and (min-width: 800px) {
    .sideContent {
        flex-direction: row;
        &Title {
            font-size: 70px;
        }
    }
}

@media screen and (min-width: $desktop) {
    .sideContent {
        &Title {
            font-size: 130px;
            flex-basis: 550px;
        }
    }
}

.titleSingle {
    font-size: 40px;
    font-family: "Gotham Rounded Bold";
}

@media screen and (min-width: $tablet) {
    .titleSingle {
        font-size: 60px;
    }
}

@media screen and (min-width: $desktop) {
    .titleSingle {
        font-size: 80px;
    }
}


// fix svg
.svgSide {
    width: 200px;
}
.svgTyping {
    width: 250px;
}

@media screen and (min-width: $tablet) {
    .svgTyping {
        width: 350px;
    }
    .svgSide {
        width: 300px;
    }
}

@media screen and (min-width: $desktop) {
    .svgSide {
        width: 400px;
    }
}

.scrollBtn {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 100;
    border: none;
    box-shadow: none;
    padding: 0;
    background: none;
    appearance: none;
    &:not(:disabled) {
        cursor: pointer;
    }
}
  
.circleOuter {
    width: 60px;
    height: 60px;
    background: #0074ff;
    opacity: 1;
    border-radius: 50%;
}
.circleInner {
    width: 40px;
    height: 40px;
    background: #559FF7;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        color: #fff;
    }
}

.animatedSpin {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    opacity: .7;
}
  
.animatedZoomIn {
    -webkit-animation: zoomIn 2s linear infinite;
    -moz-animation: zoomIn 2s linear infinite;
    animation: zoomIn 2s linear infinite;
}
  
@-webkit-keyframes zoomIn {
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.4, .4, .4);
              transform: scale3d(.4, .4, .4);
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
}
  
@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.4, .4, .4);
        transform: scale3d(.4, .4, .4);
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
  

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.sliderWrapper {
    margin: 0 -50px -40px -40px;
}

.slideImage {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
}