@import "./../../newShared.scss";

.projectCard {
  width: 100%;
  height: 185px;
  padding: 1.25em 1.5em;
  margin: 10px 20px 10px 0px;
  background-color: $main-purple;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 200ms ease;
  box-shadow: inset 0px -4px 0px 0px $shadow-purple;
  .projectImg {
    width: 100px;
    height: auto;
    position: absolute;
    right: 10px;
    top: 15px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 120px;
    background-color: aliceblue;
    border-radius: 0px 20px 20px 0px;
    left: 0;
    top: calc((100% - 120px) / 2);
  }
  &:hover {
    background-color: $main-purple--hover;
  }
  &.storie {
    &:before {
      background-color: $main-green;
    }
  }
  &.journal {
    &:before {
      background-color: $main-orange;
    }
  }
  &.assignment {
    &:before {
      background-color: $main-blue;
    }
  }
  &.current{
    background-color: $main-purple;
    
  }
  &.past{
    background-color: $main-blue--dark;
    .goDown{
      display: none;
    }
  }
  &.archived{
    background-color: $alternative-mediumgrey;
    .goDown{
      display: none;
    }

  }

  figure {
    position: absolute;
    &.stories{
      right: 20px;
      top: 30px;
      width: 140px;
    }
    &.assig{
      right: 20px;
      top: 35px;
      width: 160px;
    }
    &.journal{
      right: 20px;
      top: 25px;
      width: 180px;
    }
    img {
      width: 100%;
    }
  }
  .cardBody{
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px 0px;
    width: 60%;
    h5{
      font-weight: bold;
    }
  }
  h4 {
    font-size: 24px;
    display: flex;
    align-items: center;
  }
  .projectCardText {
        margin-right: 100px;
        h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
    }
    h4 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    h5 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .phaseIcon {margin-right: 10px;}
  }
}
.iconContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 10px;

  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  position: absolute;
  top:25px;
  right:10px;
  color: #ffffff;
}

.projectBullet {
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.75em;
  margin: 0.25em 0em;
  transition: all 200ms ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &.active {
    h4 {
      color: white;
    }
    h5 {
      color: white;
    }
    &.current{
      background-color: $alternative-purple;
    }
    &.past{
      background-color: $main-blue--dark;
    }
    &.archived{
      background-color: $alternative-mediumgrey;
      h4 {
        color: $main-black;
      }
      h5 {
        color: $main-black;
      }
    }
  }
  h4 {
    margin-left: 10px;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #a79fb7;
  }
  h5 {
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #a79fb7;
  }
}
.projectCardTitle {
  font-weight: bold;
}
.a{
  height: 170px;
}
