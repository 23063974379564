@import "./../HelpPopUps.module.scss";

.popUpModal {
  border: 4px solid black;
  width: 340px;
  padding: 1em;
  border-radius: 10px;
  box-shadow: -9px -9px 20px rgba(0, 0, 0, 0.2), 9px 9px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 8px;
  background-color: white;
  z-index: -1;
  transition: opacity 1s ease;
  // max-height: 00px;
  opacity: 0;

  &.feedback {
    border-color: $main-green;
  }
  &.revision {
    border-color: $main-red;
  }
  &.active {
    animation: transitionFromBottom 1.5s;
    animation-timing-function: cubic-bezier(0.6, -0.5, 0.6, 1.5);
    z-index: 100;
    opacity: 1;
    .closeBtn {
      display: flex;
    }
  }

  .message {
    height: 90%;
    overflow-y: auto;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
    border-radius: 10px 10px 10px 0px;
    padding: 0.5em 1em 0.75em;

    &.feedback {
      background-color: $main-green--hover;
      color: $main-green--shadow;
    }
    &.revision {
      background-color: $main-red--light;
      color: $main-red;
      margin: 1em 0em;
    }

    .dateContainer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-bottom: 0.5em;
    }
  }

  .teacherContainer {
    display: flex;
    align-items: center;
    margin-top: 1em;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    color: $main-purple;
    font-family: "Gotham Rounded Book";
    margin-left: 0.5em;
    img {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      margin-right: 1em;
    }
  }

  .closeBtn {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: $main-red;
    color: white;
    position: absolute;
    font-weight: bold;
    top: -9px;
    right: -9px;
    border-radius: 100%;
    font-family: "Gotham Rounded Book";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    display: none;
  }
}

@keyframes transitionFromBottom {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
