.main-menu {
	background: $purple;
	bottom: 0;
	color: $yellow;
	display: block;
	font-family: $primaryFont;
	height: 85px;
	position: absolute;
	width: 100%;
	z-index: 450;
	display: flex;
	flex-direction: column;
	&__items {
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__link {
		background: none;
		border: none;
		color: #FFCE89;
		cursor: pointer;
		display: block;
		font-size: 14px;
        font-family: $primaryFontBold;
		height: 85px;
		padding: 10px 0;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		width: 100%;
		&--active {
			background: $purpleDark;
		}
	}
	&__inner {
		display: block;
		height: 65px;
		position: relative;
		width: 100%;
		border-right: 1px solid rgba(144,143,147, 0.2);
		&--last {
			border-right: none;
		}
		&--rel {
			position: relative;
		}
	}
	&__boomer {
		height: 35px;
		margin: 5px auto 0;
		overflow: hidden;
		position: relative;
		&--badge {
			margin: 0 auto;
		}
	}
	&__boomer-badge {
		display: block;
		position: absolute;
		height: auto;
		left: 50%;
		margin-left: -11px;
		top: 30px;
		width: 22px;
	}
	&__link-text {
		display: block;
		line-height: normal;
		margin-top: 10px;
		&--badge {
			margin-top: 15px;
		}
	}
	&__item {
		float: left;
		width: 25%;
		padding: 0;
		display: block;
	}
	&__icon {
		display: block;
		height: 35px;
		margin: 5px auto 0;
		width: auto;
		&--chat {
			color: #FFF;
			background-size: 25px auto;
			background-position: center center;
			background-repeat: no-repeat;
			padding-top: 10px;
			width: 35px;
		}
		&--calendar{
			width: 35px;
		}
	}
	&__user-info, 
	&__copyright  {
		display: none;
	}
	&--hidden-mobile {
		display: none;
	}
}
@media screen and (min-width: $tablet) {
	.main-menu {
		height: 100%;
		width: 120px;
		position: fixed;
		&__user-info, 
		&__copyRight  {
			display: block;
		}
		&__user-info {
			font-size: 14px;
			font-weight: 100;
			line-height: 18px;
			padding: 20px 10px 0 10px;
			text-align: center;
		}
		&__name {
			display: block;
	        font-family: $primaryFontBold;
		}
		&__coins {
			width: 16px;
			height: 16px;
			margin-right: 5px;
			display: inline-block;
		}
		&__wallet {
			border-top: 1px solid rgba(144,143,147, 0.2);
			border-bottom: 1px solid rgba(144,143,147, 0.2);
			display: flex;
			justify-content: center;
			padding: 10px 0;
			margin-top: 10px;
		}
		&__copyright {
			font-size: 10px;
			line-height: 18px;
			text-align: center;
			display: block;
			color: #BFBFBF;
			margin: 15px 0px;
			width: 100%;
		}
		&__icon {
			margin: 0 auto 5px auto;
			display: block;
			width: 50%;
			height: auto;
			&--ava {
				width: 40%;
			}
			&--logout {
				width: 30%
			}
			&--portfolio {
				width: 60%;
			}
			&--chat {
				height: 25px;
				margin: 5px auto 0 auto;
				padding-top: 6px;
				width: 25px;
			}

		}
		&__item {
			float: none;
			width: 100%;
		}
		&__link {
			height: initial;
			position: relative;
			padding: 0 10px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				height: 100%;
				top: 0;
				left: 0;
				right: 100%;
				z-index: 0;
				background-color: $blue;
				transition: right 0.15s ease;
			}
			&:hover {
				color: #FFF;
				&::before {
					right: 0;
				}
			}
			&--active {
				&:hover {
					color: $yellow;
					&::before {
						right: 97%;
					}
				}
				&::before {
					right: 97%;
				}
			}
		}
		&__inner {
			padding: 12px 0;
			border-bottom: 1px solid rgba(144,143,147, 0.2);
			border-right: none;
			width: initial;
			height: initial;
			z-index: 1;
		}
		&__boomer {
			height: 40px;
			margin: 0px auto 5px;
		}
		&__boomer-badge {
			margin-left: -15px;
			top: 45px;
			width: 30px;
		}
		&__link-text {
			display: inline-block;
			margin-top: none;
		}
		&--hidden-mobile {
			display: flex;
		}
	}
}
@media screen and (min-height: 700px) and (min-width: $tablet) {
	.main-menu {
		&__icon {
			margin: 0 auto 10px auto;
			display: block;
			width: 60%;
			height: auto;
			&--ava {
				width: 50%;
			}
			&--logout {
				width: 40%;
			}
			&--portfolio {
				width: 70%;
			}
			&--chat {
				margin: 5px auto 5px auto;
				height: 25px;
				width: 25px;
			}
		}
		&__boomer {
			height: 45px;
			margin: 0px auto 10px;
		}
		&__boomer-badge {
			top: 58px;
		}
		&__inner {
			padding: 20px 0;
		}
	}
}