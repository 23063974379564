@import "./../Tabs.module.scss";

.previousChaptersContainer {
  background-color: white;
  margin: 4px;
  border-radius: 10px;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .dropdown {
    padding: 0.5em;
    width: 85%;
    margin: 1em;
    border-radius: 10px;
    background-color: white;
    border-color: $main-grey;
    color: $alternative-darkgrey;
    font-weight: bold;
    font-size: 18px;
    text-overflow: ellipsis;
    option {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .textContainer {
    height: 100%;
    width: 100%;
    p {
      padding: 1em;
      height: 100%;
      overflow: auto;
      font-size: 14px;
      line-height: 18px;
      color: #4a4a4a;
      white-space: pre-line;
    }
  }
}
