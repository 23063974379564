@import "./../../newShared.scss";

.time {
  font-size: 11px;
  line-height: 12px;
}

.unitTime {
  position: absolute;
  bottom: 55px;
  right: 100px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: $main-black;
}
