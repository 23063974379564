@import "./../NewDashboard/newShared.scss";

.modalWrapper {
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.6);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
  .videoModal {
    color: white;
    position: relative;
    font-weight: bold;
    text-align: center;
    iframe {
      border-radius: 20px;
    }
    .closeButton {
      position: absolute;
      top: -20px;
      right: -30px;
      cursor: pointer;
    }
    .closeText {
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
