@import "./../MainWritingEditor.module.scss";



.topTabsContainer {
  display: flex;
  position: absolute;
  right: -4px;
  top: -52px;
  .tab {
    background-color: $tab-blue;
    color: white;
    border-radius: 15px 15px 0px 0px;
    border: 4px solid $main-purple;
    border-bottom: 0px;
    margin-left: -22px;
    padding: 0.75em 1.5em 0.4em 0.4em;
    box-shadow: inset 0px 5px 0px 0px $tab-blue-shadow;
    font-family: "Gotham Rounded Bold";
    font-style: normal;
    font-size: 16px;
    opacity: 1;
    transition: opacity 1s ease;
    display: flex;
    align-items: center;
    z-index: 2;
    &:hover {
      box-shadow: inset 0px 3px 0px 0px $tab-blue-shadow;
    }
    &:focus {
      outline: none;
    }
    &.active {
      opacity: 0;
    }
    &.feedback {
      background-color: $tab-yellow;
      box-shadow: inset 0px 5px 0px 0px $tab-yellow-shadow;
      z-index: 3;
      img{
        width: 25px;
        margin-right:10px
      }
      &:hover {
        box-shadow: inset 0px 3px 0px 0px $tab-yellow-shadow;
      }
    }
  }
}

.imageFrame {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.45));
  figure {
    border-radius: 100%;
    background-color: white;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      width: 150%;
      left: -7px;
      top: -6px;
    }
  }
}
