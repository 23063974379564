@import "./../SidePanel.module.scss";

.tabsContainer {
  display: flex;
  text-align: center;
  .tab {
    cursor: pointer;
    flex: 2;
    padding: 0.75em 0.25em;
    color: $main-yellow;
    border-radius: 10px;
    margin: 4px;
    font-family: Gotham Rounded Bold;
    background-color: #60547D;
    transition: all 0.5s ease;
    
    &.active {
      background-color: $main-purple--bright;
    }
  }
}


@media (max-height: 700px) {
  .tabsContainer{
    .tab {
      padding: 0.25em;
    }
  }
}
