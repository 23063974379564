@import "./../newShared.scss";

.adaptToScreen {
  background-repeat: repeat;
  height: auto;
  min-height: 100vh;
}

.projectDashboardContainer {
  display: flex;
  .projectMenu {
    width: 850px;
    position: relative;
    @media (max-width:1100px){
      width:100%;
    }
    .unitCardContainer {
      height: calc(100vh - 325px);
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow-y: auto;
      border-radius: 10px;
      @media (max-width:675px){
        height: calc(100vh - 355px);
      }
      a {
        height: 150px;
        width: calc(50% - 7.5px);
        margin-top: 15px;
        margin-right: 5px;
        @media (max-width:850px){
          width:100%
        }
        &:nth-child(odd) {
          margin-right: 2.5px;
        }
        &:nth-child(even) {
          margin-left: 2.5px;
        }
      }
    }
  }
}

.scrollArrows {
  position: absolute;
  bottom: 0;
  left: -20px;
  div {
    cursor: pointer;
    background-color: $main-purple;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    height: 40px;
    width: 40px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    span {
      margin-bottom: -4px;
    }
  }
}
