.showContent {
	display: block;
}
.hideContent {
	display: none;
}
@media screen and (max-width: $tablet) {
	.project-home {
		padding: 20px;
	}
}
.project-home {
	max-width: 1200px;
}
.report {
	background: #fff;
	border-radius: 15px;
	padding: 1px 10px;
	width: 100%;
}
.report-side {
	margin-bottom: 20px;
	overflow: hidden;
	&__top {
		padding: 20px;
		&--wb {
			background: #efc210;
		}
	}
	&__title {
		color: $blue;
		font-family: $primaryFontBold;
		font-size: 16px;
		&--wb {
			color: #4a4a4a;
			padding-left: 40px;
			position: relative;
		}
		&__light {
			font-family: $primaryFont;
			font-size: 14px;
		}
	}
	&__code {
		color: $green;
		font-family: $primaryFontBold;
		font-size: 14px;
		margin-top: 10px;
	}
	&__label {
		color: #000;
	}
	&__wb-icon {
		height: auto;
		left: 0px;
		position: absolute;
		width: 30px;
	}
	&__wb-showmore {
		font-size: 14px;
		padding-left: 40px;
	}
}
.project-meta {
	border-top: 1px solid #ccc;
	font-size: 14px;
	margin-top: 10px;
	padding-top: 10px;
	&__group {
		border-right: 1px solid #ccc;
		font-family: $primaryFontBold;
		margin-right: 10px;
		padding-right: 10px;
	}
}
.report-progress {
	background: #edf7ff;
	padding: 20px;
	position: relative;
}
.report-callout {
	display: block;
	text-decoration: none;
}
.report-button__para {
	flex: 1;
	font-size: 14px;
	text-align: right;
}
.p-callout {
	background-color: #fff5e7;
	background-image: url("../images/chev-yellow.png");
	background-position: right 20px center;
	background-repeat: no-repeat;
	background-size: 30px auto;
	border: 2px solid $yellow;
	border-radius: 15px;
	padding: 20px;
	&:hover {
		background-color: darken(#fff5e7, 3%);
	}
	&--success {
		background-color: #d3f9d0;
		background-image: url("../images/chev-green.png");
		border: 2px solid #23b232;
		&:hover {
			background-color: darken(#d3f9d0, 5%);
		}
	}
	&--reject {
		background-color: #ffe7e7;
		background-image: url("../images/chev-red.png");
		border: 2px solid #ff8481;
		&:hover {
			background-color: darken(#ffe7e7, 5%);
		}
	}
}
.report__unit {
	background: #f0f8ff;
	border: 1px solid #f0f0f0;
	border-radius: 8px;
	display: block;
	margin: 10px 0;
	overflow: hidden;
	width: 100%;
	&--current {
		border: 2px solid #fcc62d;
	}
}

.report__heading {
	display: flex;
}
.report__heading-link {
	align-items: center;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	color: $blue;
	display: flex;
	flex: 1;
	font-family: $primaryFontBold;
	line-height: 22px;
	padding: 15px;
	text-decoration: none;
	.button {
		width: 60%;
	}
	.report__heading-title {
		width: 40%;
	}
	&:hover {
		background: darken(#f0f8ff, 3%);
	}
}
.report__heading-winner {
	color: #afafaf;
	font-family: $primaryFont;
	margin-left: 10px;
}
.report__heading-toggle {
	background: $blue;
	border: none;
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
	width: 40px;
	&:hover,
	&:focus {
		background: darken($blue, 4%);
		outline: none;
	}
}
.report__heading-alert {
	background: #ff7166;
	border-radius: 20px;
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	margin-left: 10px;
	padding: 4px 10px;
}
.report__chevron {
	height: auto;
	transform: rotate(180deg);
	transition: transform 0.2s;
	width: 20px;
	&--up {
		transform: rotate(0deg);
	}
}
.report__body {
	padding: 5px 15px 10px 15px;
	width: 100%;
}
.report__body-winner {
	border-bottom: 1px solid #c7eafd;
	color: #5f5f5f;
	font-size: 14px;
	margin-bottom: 5px;
	overflow: hidden;
	padding-bottom: 10px;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
}

.report__entry {
	background: #fff;
	border-radius: 8px;
	display: flex;
	padding: 10px;
	width: 100%;
}
.report__entry-success {
	align-items: center;
	color: $green;
	display: flex;
	font-family: $primaryFontBold;
	font-size: 14px;
	margin-left: 20px;
}
.report__entry-tick {
	height: 18px;
	margin-right: 5px;
	width: auto;
}

.report__draft {
	background: #eeeeee;
	border-radius: 8px;
	color: #c5bebe;
	display: flex;
	padding: 15px;
	.report__draft-title {
		font-family: $primaryFontBold;
		margin-right: 10px;
	}
}
.report-earnings {
	padding: 20px;
}
.earnings-breakdown {
	font-size: 14px;
	margin-bottom: 20px;
	position: relative;
}
.earnings-breakdown__amount {
	align-content: center;
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	&--total {
		font-family: $primaryFontBold;
		font-size: 16px;
	}
}
.earnings-breakdown__img {
	height: 14px;
	margin-right: 3px;
	width: auto;
	&--total {
		height: 16px;
	}
}
.report-earnings__title {
	font-family: $primaryFontBold;
}
.report-earnings__expand {
	color: #949494;
	cursor: pointer;
	font-family: $primaryFont;
	font-size: 14px;
	margin-left: 10px;
}

.recent-tickles {
	background: #fff;
	border-radius: 7px;
	display: flex;
	margin-top: 10px;
	padding: 10px;
	&--outer-space {
		margin: 10px 15px;
	}
	&__label {
		flex: 0;
		padding-right: 20px;
	}
	&__feed {
		flex: 1;
	}
	&__tickles {
		display: flex;
		&--completed {
			display: block;
		}
	}
	.tickle {
		&__content {
			color: $blue;
		}
		&__body {
			flex: 1;
		}
		&__author {
			font-size: 16px;
			font-family: $primaryFontBold;
		}
		&__like-button {
			align-self: flex-start;
		}
	}
	&__avatars {
		display: flex;
		padding: 0 20px 0 10px;
		margin-top: 10px;
	}
	&__view-more {
		border: none;
		font-size: 12px;
		line-height: 28px;
		padding: 0 20px;
	}
	&__other-count {
		text-decoration: underline;
	}
}
.no-activity {
	background: #fff5e7;
	border: 1px solid #fcc62d;
	border-radius: 8px;
	color: #fcc62d;
	font-family: $primaryFontBold;
	margin: 10px;
	padding: 10px;
	text-align: center;
}
.recent-tickles__time-stamp,
.tickle__time-stamp {
	color: #a2a2a2;
	font-size: 14px;
	margin-top: 5px;
}
.tickles-list {
	width: 100%;
}
.tickle {
	display: flex;
	&__avatar {
		margin-right: 10px;
	}
}
.recent-ava {
	background: #fff;
	border: 2px solid #4e406e;
	border-radius: 500px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	height: 40px;
	margin-left: -10px;
	position: relative;
	width: 40px;
	&--rank {
		border: 2px solid rgba(0, 0, 0, 0);
	}
	&--orange {
		border: 2px solid #fca502;
	}
	&--writingClub{
		border: 2px solid #7015D0;
		
	}
	.boomer-holder--recent-ava {
		border-radius: 500px;
		height: 34px;
		margin: 1px;
		overflow: hidden;
		width: 34px;
	}
	.boomer-holder__image {
		height: auto;
		left: 50%;
		margin-left: -32px;
		max-height: initial;
		max-width: initial;
		position: relative;
		top: -12px;
		width: 64px;
	}
	.boomer-holder__badge {
		margin-left: -13px;
		top: 29px;
		width: 26px;
	}
	&__rank {
		height: auto;
		left: 50%;
		margin-left: -24px;
		position: absolute;
		top: 0;
		width: 48px;
	}
}
.tickle__like-button {
	align-items: center;
	background: white;
	border: 1px solid $blue;
	border-radius: 8px;
	box-shadow: none;
	color: $blue;
	display: flex;
	font-family: $primaryFontBold;
	font-size: 15px;
	justify-content: center;
	line-height: 25px;
	min-width: 55px;
	&__count,
	&__img {
		margin: 0 2px;
	}
	&:hover {
		background: #f0f8ff;
	}
}

@media screen and (max-width: $desktop) {
	.earnings-breakdown--hidden {
		display: none;
	}
}
@media screen and (min-width: $desktop) {
	.report-earnings__expand {
		display: none;
	}
	.project-home__main {
		display: flex;
		min-width: 0;
	}
	.report-container {
		flex: 1;
		flex-grow: 1;
		min-width: 0;
	}
	.report-side-container {
		flex: 1;
		order: 2;
		margin-left: 20px;
		max-width: 400px;
		min-width: 0;
	}
}

@media (max-width:420px){
	.recent-tickles{
		&__avatars{
			display: none;
		}
	}
}
