@import "./../../newShared.scss";

.unitWriting {
  /*height: calc(100vh - 80px);*/ // removed this
  display: grid;
  grid-template-columns: 1fr minmax(270px, 35%);
  position: relative;

  .editorContainer {
    // overflow-y: hidden;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    max-width: 100%; // added this
  }



  .modal{
    width: calc(100vw - 60px);
    position: absolute;
    // left: calc(50% - 350px);
    left: -60px;
    z-index: 100;
    // transform: translateX(-50px);
    top:-60px;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.583);
    display: flex;
    align-items: center;
    justify-content: center;
    .inner{
      height: 400px;
      width: 800px;
      position: relative;
      right: 100px;
      // left: calc(50% - 350px);
      // // transform: translateX(-50px);
      // border:1px solid chartreuse;
      // background: red
      .whitePanel {
        background: #FFF;
        padding: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

}

@media (min-width: 700px) {
    .unitWriting {
        height: calc(100vh - 80px)
    }
}

@media (max-width: 750px) {
  .unitWriting {
    margin-top: 0;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .unitWriting {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    .editorContainer {
      height: 75%;
    }
  }
}
// @media (max-height:700px){
//   .unitWriting{
//     height: 78vh;
//   }
// }

@media (max-height: 700px) and (max-width: 600px){
  .unitWriting {
    .editorContainer {
      height: 72%;
    }
  }
}

@media (max-height: 630px) and (max-width: 600px) {
  .unitWriting {
   
    .editorContainer {
      height: 68%;
    }
  }
}


.modal2{
  width: 100vw;
  position: absolute;
  // left: calc(50% - 350px);
  left: 0;
  z-index: 100;
  // transform: translateX(-50px);
  top: 0;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.583);
  display: flex;
  align-items: center;
  justify-content: center;
  .inner{
    height: 400px;
    width: calc(100% - 40px);
    max-width: 700px;
    position: relative;
    // left: calc(50% - 350px);
    // // transform: translateX(-50px);
    // border:1px solid chartreuse;
    // background: red
    .whitePanel {
      background: #FFF;
      padding: 100px 40px 40px 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative
    }
  }
}
.hifiveImg {
  position: absolute;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  height: auto;
  top: -90px;
}
@media (min-width: 750px) {
  .modal2 {
    left: 120px;
    width: calc(100vw - 120px)
  }
}
.closeModal {
  position: absolute;
  top: -20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #c01313;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}

.submitBtn {
  font-family: "Gotham Rounded Bold";
  font-size: 20px;
  line-height: 20px;
  color: white;
  cursor: pointer;
  padding: 1em;
  background-color: $main-lightBlue;
  border: 4px solid $main-yellow;
  box-shadow: inset 0px -5px 0px 0px $main-lightBlue--shadow;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  min-width: 300px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover,
  &:disabled {
    background-color: $main-lightBlue--hover;
    box-shadow: inset 0px -3px 0px 0px $main-lightBlue--hover--shadow;
  }
}