@import "./../../newShared.scss";

.pickerBtn {
  color: $main-darkgrey;
  background-color: white;
  padding: 1em;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  position: relative;
  min-width: 180px;
  margin-bottom: 1em;
  height: 55px;
  transition: all 200ms ease;
  box-shadow: inset 0px -4px 0px 0px $shadow-white;

  &:hover {
    background-color: $main-grey;
    color: $main-black;
  }
  &.active {
    color: white;
    background-color: $alternative-purple;
    box-shadow: inset 0px -4px 0px 0px $shadow-purple;

    &.darkPurple {
      background-color: $main-purple;
      // &:after{
      // border-top: 10px solid $main-purple;
      // }
    }
    &.orange {
      background-color: $main-orange;
      // &:after{
      // border-top: 10px solid $main-orange;
      // }
      box-shadow: inset 0px -4px 0px 0px $shadow-yellow;
    }
    &.blue {
      background-color: $main-blue--dark;
      // &:after{
      // border-top: 10px solid $main-blue--dark;
      // }
    }
    &.grey {
      color: $main-black;
      background-color: $alternative-mediumgrey;
      // &:after{
      // border-top: 10px solid $alternative-mediumgrey
      // }
    }
    &.green {
      color: white;
      background-color: #97d318;
      box-shadow: inset 0px -4px 0px 0px $shadow-green;
    }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: -10px;
    //   left: calc(50% - 10px);
    //   width: 0;
    //   height: 0;
    //   border-left: 5px solid transparent;
    //   border-right: 5px solid transparent;
    //   // border-top: 10px solid transparent;
    //   border-top: 10px solid $alternative-purple;
    // }
  }
  p {
    margin-left: 0.5em;
  }
  figure {
    margin-right: 0.5em;
    img {
      height: 100%;
      color: red;
    }
  }
}

@media (max-width: 420px) {
  .pickerBtn {
    padding: 0.5em;
    font-size: 14px;
    min-width: 140px;
  }
}
