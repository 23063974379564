// fonts
@font-face {
	font-family: 'Gotham Rounded Book';
	src: url('https://bw-students-prod.s3.us-west-2.amazonaws.com/fonts/GothamRounded-Book.woff');
}
@font-face {
	font-family: 'Gotham Rounded Bold';
	src: url('https://bw-students-prod.s3.us-west-2.amazonaws.com/fonts/GothamRounded-Bold.woff');
}



// Breakpoints
$smallMobile: 375px;
$mobile: 500px;
$tablet: 750px;
$desktop: 1000px;
$mobileLimit: 749px;

// Fonts
$primaryFont: 'Gotham Rounded Book';
$primaryFontBold: 'Gotham Rounded Bold';

// Colours
$defaultTextColor: #4A4A4A;
$blue: #00A5FF;
$blueLight: #F0F8FF;
$gold: #FFB100;
$goldDark: #D18720;
$green: #23B232;
$greenDark: #0E921D;
$greenLight: #D3F9D0;
$orange: #FA8A1F;
$purple: #4E406E;
$purpleDark: #3D2D63;
$purpleLight: #6F59A1;
$red: #E91D1D;
$redMid: #FFC6C6;
$redLight: #F8CDCD;
$yellow: #FCC62D;
$yellowLight: #FFCE89;
$yellowLighter: #FFF7CB;


$rubricBG1: #CB2723;
$rubricBG2: #EFAB27;
$rubricBG3: #7ED81C;
$rubricBG4: #417801; 