.store {
	background: #FFF;
	position: relative;
}
.store__preview {
	height: 220px;
	position: relative;
}

.store__mob-header {
	background: $purple;
	display: flex;
	padding: 10px;	
}

.store__header-text {
	color: #FFF;
	flex-grow: 1;
    font-family: $primaryFontBold;
	line-height: 38px;
	text-align: center;
}

.store__header-coins {
	height: auto;
	margin-right: 5px;
	width: 20px;
}

.store__header-bucks {
	align-items: center;
	color: #FFCE89;
	display: flex;
    font-family: $primaryFontBold;
	justify-content: center;
	width: 69px;
}

.store__controls {
	background: #ececec;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-flow: column;
	height: calc(100vh - 280px);
	overflow: hidden;
	padding-bottom: 5px;
}

.store__wallet {
	display: none;
}

.store__tabs {
	background: $blue;
	display: flex;
}
.store__tab {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	color: #FFF;
	cursor: pointer;
	flex: 1;
    font-family: $primaryFontBold;
	padding: 15px;
	text-align: center;
	&:hover {
		background: lighten($blue, 10%);
		@at-root .store__tabs{
			background: lighten($blue, 10%);	
		} 
	}
	&--active, &--active:hover {
		background: #ececec;
		color: #000;
	}
}

.store__categories {
	display: flex;
}
.store__category-tab {
	align-items: center;
	background: #F1F1F1;
	border: 2px solid #E4E4E4;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex: 1;
	height: 55px;
	justify-content: center;
	margin: 10px 5px 0 5px;
	&--active {
		background: #FFF;
		border: 2px solid $blue;
	}
	&:hover {
		background: #FFF;
	}
}
.store__category-boomer {
	height: auto;
	width: 22px;
}
.store__category-img {
	height: auto;
	width: 30px;
}
.store__items {
	display: flex;
	flex: 1;
	padding-top: 10px;
	position: relative;
	width: 100%;
}
.store__items-list {
	background: #FFF;
	border: 1px solid #D6D6D6;
	border-radius: 10px;
	height: 100%;
	margin: 0 5px 10px 5px;
	overflow-y: scroll;
	padding: 5px;
	width: 100%;
	&--padded {
		padding: 5px 5px 60px 5px;
	}
}
.store__actions {
	background: #FFF;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	bottom: 1px;
	display: flex;
	left: 6px;
	padding-bottom: 5px;
	position: absolute;
	right: 6px;
	z-index: 200;
}
.store__actions .button {
	flex: 1;
	margin: 0 5px;
}
.store__item {
	background: #F1F1F1;
	border: 1px solid #F1F1F1;
	border-radius: 10px;
	cursor: pointer;
	float: left;
	margin: 5px;
	overflow: hidden;
	padding: 10px 5px;
	position: relative;
	width: calc( 25% - 10px );
	&:hover {
		background: #FFF;
		border: 1px solid #F0F0F0;
	}
	&--wearing, &--wearing:hover {
		background: $yellow;
	}
	&--owned {
		padding: 5px 5px 15px 5px;
	}
}
.store__no-item {
	color: #4a4a4a;
	font-family: $primaryFontBold;
	padding: 5px;
	text-align: center;
}
.store__item-price {
	align-items: center;
	background: $blue;
	border-radius: 10px;
	color: #FFF;
	display: flex;
    font-family: $primaryFontBold;
	height: 40px;
	justify-content: center;
	margin: 5px;
	font-size: 16px;
	text-align: center;
	width: calc(100% - 10px);
}
.store__item-desc {
	text-transform: capitalize;
}
.store__item-coins {
	height: 18px;
	margin: 0 2px 0 10px;
	width: auto;
}
.store__item-owned {
	background: $blue;
	bottom: 0;
	color: #FFF;
	font-size: 12px;
    font-family: $primaryFontBold;
	left: 0;
	padding: 2px 0;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.store__item-img {
	background-position: 50% 50%;
    background-repeat:   no-repeat;
    background-size: contain;
	display: block;
	height: 50px;
	width: 100%;
}

.store__item-img--remove {
	color: #4a4a4a;
	font-size: 12px;
	font-weight: bold;
	line-height: 50px;
	text-align: center;
	text-transform: uppercase;
}

.store__spotlight {
	height: auto;
	left: 5px;
	position: absolute;
	top: 0;
	width: 60px;
	&--right {
		left: initial;
		right: 5px;
	}
}
.store__preview .boomer-holder {
	text-align: center;
	height: 100%;
	width: 100%;
	padding: 10px;
	position: relative;
}
.boomer-holder__badge {
	position: absolute;
	top: 63%;
	left: 50%;
	height: auto;
	margin-left: -26px;
	width: 52px;
}
.store__purchase-overlay {
	&-items {
		border: none;
		border-top: 1px solid #F2F2F2;
		border-radius: 0;
		margin: 20px -5px 0;
		padding: 15px 0 0;
	}
	&-coins {
		height: 18px;
		margin-right: 2px;
		position: relative;
		top: 2px;
	}
	.store__item {
		cursor: default;
		&:hover {
			background: #F1F1F1;
			border: 1px solid #F1F1F1;
		}
	}
}

.store__badge {
	background: #f5f5f5;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	color: #005F62;
	cursor: pointer;
	display: flex;
	font-family: $primaryFontBold;
	margin-bottom: 10px;
	padding: 10px 10px 0 0;
	&:hover {
		background: #FFF;
	}
	&--disabled {
		cursor: default;
		&:hover {
			background: #F5F5F5;
		}
	}
	&--active {
		background: $blue;
		color: #FFF;
		&:hover {
			background: $blue;
		}
	}
}
.badge__img {
	background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 80px;
    margin-right: 10px;
    width: 80px;
}
.badge__equipped {
	color: #F5A623;
	font-size: 14px;
}
.badge__quali {
	color: #FF6346;
	font-size: 12px;
	font-family: $primaryFont;
	margin-top: 5px;
	position: relative;
}
.badge__lock {
	position: absolute;
	left: -17px;
	top: -6px;
}
.preview-badge {
	cursor: pointer;
	left: 20px;
	position: absolute;
	top: 140px;
	width: 70px;
	&--rank {
		left: initial;
		right: 20px;
	}
	&__badge {
		height: auto;
		width: 100%;
	}
}
@media screen and (min-width: $tablet) {
	.store {
		border-radius: 10px;
	    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
		height: calc(100vh - 80px);
		overflow: hidden;
	}
	.store__controls {
		box-shadow: none;
		height: calc(100% - 276px);
		padding-bottom: 10px;
	}
	.store__mob-button {
		display: none;
	}
	.store__header-text {
		padding-left: 69px;
	}
}

@media screen and (min-width: $desktop) {
	.store {
		background: #ececec;
    	border: none;
		border-radius: 15px;
		box-shadow: none;
		display: flex;
		height: calc(100vh - 100px);
		max-width: 800px;
		padding: 10px;
	}
	.store__mob-header {
		display: none;
	}
	.store__header {
		background: $blue;
		color: #FFF;
		font-size: 20px;
		line-height: 50px;
        font-family: $primaryFontBold;
		text-align: center;
	}
	.store__preview {
		background: none;
		border-radius: 15px;
		height: 100%;
		flex: 1;
		overflow: hidden;
	}
	.store__preview .boomer-holder {
		background: #FFF;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
		height: auto;
		padding: 50px 10px 50px 10px;
	}
	.preview-badge {
		left: 5px;
		top: 290px;
		width: 50px;
		&--rank {
			left: initial;
			right: 5px;
		}
	}
	.store__preview .boomer-holder__image, .store__preview .boomer-holder__loader {
		height: auto;
		width: 220px;
	}
	.boomer-holder__badge {
		top: 58.1%;
	}
	.store__controls {
		background: none;
		border-radius: 15px;
		height: initial;
		margin-left: 10px;
		padding-bottom: 0;
		width: 380px;
	}
	.store__tabs, .store__categories, .store__items {
		background: #F5f5f5;
	}
	.store__tabs {
		background: $blue;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		overflow: hidden;
	}
	.store__tab {
		&--active, &--active:hover {
			background: #F5F5F5;
		}
	}
	.store__items-list {
		margin: 0;
	}
	.store__items {
		padding: 10px 5px 5px 5px;
	}
	.store__spotlight {
		top: 50px;
		width: 50px;
		z-index: 200;
	}
	.store__coins {
		height: auto;
		margin: 0 5px 0 10px;
		width: 24px;
	}
	.store__bucks {
		color: #FFF;
		font-size: 24px;
		margin: 0 10px 0 0;
		padding-top: 1px;
	}
	.store__wallet {
		align-items: center;
		background: $purple;
		border-radius: 15px;
		color: #D4D4D4;
		display: flex;
		font-size: 16px;
        font-family: $primaryFontBold;
		height: 46px;
		justify-content: center;
		margin-bottom: 10px;
		text-align: center;
	}
	.store__categories {
		padding: 0 5px;
	}

	.store__items {
		padding: 10px;
	}
	.store__actions {
		bottom: 11px;
		left: 11px;
		padding: 0 5px 10px 5px;
		right: 11px;
	}
	.store__purchase-overlay {
		&-items {
			margin: 20px -5px 0;
		}
	}
}

@media screen and (min-width: 1100px) {
	.store__spotlight {
		width: 70px;
	}
	.boomer-holder__badge {
		top: 60.1%;
	}
	.preview-badge {
		left: 5px;
		top: 300px;
		width: 75px;
		&--rank {
			left: initial;
			right: 10px;
		}
	}
	.store__controls {
		width: 400px;
	}
}