.partnerContainer {
    background-color: white;
    padding: 1em 4.5em 1em 2em;
    margin-top: -2em;
    margin-right: 1em;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;

    img{
        width: 40px;
        height: 40px;
        margin-right:10px
    }

    &.down {
        position: absolute;
        right: 0;
        bottom: -300%;
    }

    &:after {
        content: "";
        background-color: #ffad44;
        height: 100%;
        position: absolute;
        width: 30px;
        border-radius: 15px 0px 0px 15px;
        right: 0;
        top: 0;
    }
    .heading {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #333333;
    }
}

@media (max-width: 920px) {
    .partnerContainer {
        margin-top: -0em;
        padding: 0.5em 2em 0.5em 1em;
        margin-right: 0em;
        &:after {
            width: 15px;
        }
    }
}
@media (max-width: 750px) {
    .partnerContainer {
        position: absolute;
        padding: 0.5em 2em 0.5em 1em;
        height: 35px;
        top: 100%;
        border-radius: 3px;
        img{
            height: 25px;
        }
        p{
            display: inline-block;
            margin-right: 10px;
        }
        &:after {
            width: 15px;
            right:0px
        }
    }

}
