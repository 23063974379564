.school-card {
    align-items: center;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 20px;
    &__image-holder {
        align-items: center;
        border-radius: 80px;
        display: flex;
        justify-content: center;
        height: 80px;
        width: 80px;
    }
    &__image {
        max-height: 50px;
        max-width: 50px;
    }
    &__text-holder {
        flex: 1;
        margin-left: 10px;
    }
    &__name {
        font-size: 21px;
    }
    &__grade {
        font-size: 18px;
        font-family: $primaryFontBold;
    }
    &__location {
        font-size: 14px;
        font-style: italic;
    }
}


.school-bar {
    color: $blue;
    font-family: $primaryFontBold;
    padding: 5px;
    width: 100%;
    &__inner {
        background: #FFF;
        border-radius: 12px;
        display: inline-block;
        padding: 16px 20px 16px 45px;
        position: relative;
        width: 100%;
    }
    &__icon {
        display: inline-block;
        height: 24px;
        position: absolute;
        left: 14px;
        top: 10px;
        width: 24px;
    }
    &__image {
        width: 24px;
        height: 24px;
    }
    &__name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}

.school-bar__filters {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
}

.school-bar__dropdown {
    width: calc(50% - 10px);
}

@media screen and (min-width: $tablet) {
    .school-card {
        flex-direction: column;
        justify-content: center;
        &__text-holder {
            margin-top: 10px;
            text-align: center;
        }
    }
}

@media screen and (min-width: $desktop) {
    .school-bar__inner {
        width: initial;
        &--portfolio {
            float: left;
            max-width: calc(100% - 420px);
        }
    }
    .school-bar__filters {
        float: right;
        margin-top: 0;
        width: 400px;
    }
}