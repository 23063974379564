@media screen and (max-width: $mobileLimit) {
    .user-view--profile {
        padding-top: 127px;
        .sub-menu {
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: $desktop) {
    .profile {
        &__split-content {
            display: flex;
            margin: 0 -25px;
            max-width: 900px;
        }
        &__left-content,
        &__right-content {
            flex: 1;
            margin: 0 25px;
        }
        &__left-content {
            min-width: 300px;
        }
    }
}

.class-list {
    &__item {
        background: #FFF;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        padding: 10px;
    }
    &__name {
        display: block;
        font-family: $primaryFontBold;
        margin-bottom: 10px;
    }
    &__pending {
        color: #717171;
        font-family: $primaryFont;
    }
    &__teacher {
        padding-left: 25px;
        position: relative
    }
    &__avatar {
        height: auto;
        left: 0;
        top: -4px;
        position: absolute;
        width: 20px;
    }
}