.project-dash {
	padding: 20px;
	&__card, &__units {
		background: #FFF;
		border-radius: 15px;
		box-shadow: 0 0 15px rgba(0,0,0,0.3);
	}
	&__units {
		margin-top: 20px;
		padding: 10px;
	}
	&__unit-link {
		background: #ffa22b;
		border-radius: 7px;
		color: #4A4A4A;
		display: block;
		font-family: $primaryFontBold;
		font-size: 20px;
		padding: 20px 100px 20px 20px;
		position: relative;
		text-decoration: none;
		&:hover {
			background: darken(#ffa22b, 10%);
		};
		&--draft, &--draft:hover {
			background: #EBE9EE;
			color: #B5B5B5;
		}
		&--active {
			background: #6518D2;
			color: #FFF;
			&:hover {
				background: darken(#6519D2, 10%);
			};
		}
	}
	&__reading-boomer, &__writing-boomer, &__voting-boomer {
		position: absolute;
		height: auto;
		bottom: 0;
		right: 10px;
		width: 85px;
	}
	&__writing-boomer {
		width: 115px;
	}
	&__voting-boomer {
		width: 100px;
	}
	&__unit-author {
		color: #696969;
		font-size: 14px;
		font-family: $primaryFont;
	}
	&__unit-pill {
		background: #FFF;
		border-radius: 15px;
		color: #4a4a4a;
		display: inline-block;
		font-size: 14px;
		line-height: 12px;
		margin-top: 5px;
		padding: 8px 10px 8px 35px;
		position: relative;
		&--alt, &--submitted {
			background: #ffad44;
			color: #000;
		}
		&--submitted {
			background: #7ae678;
		}
		&--rejected {
			background: #f93737;
			color: #FFF;
		}
	}
	&__unit-icon {
		height: auto;
		left: 10px;
		position: absolute;
		top: 6px;
		width: 18px;
		&--pencil {
			left: 12px;
			width: 16px;
		}
	}
	&__card-header {
		padding: 20px;
		text-align: center;
	}
	&__img {
		display: block;
		height: auto;
		margin: 0 auto;
		width: 120px;
		&--alt {
			width: 180px;
		}
	}
	&__card-title {
		color: #4a4a4a;
		font-size: 18px;
		margin: 20px 0 5px 0;
	}
	&__card-code {
		background: #ffa22b;
		border-radius: 15px;
		color: #4a4a4a;
		font-size: 14px;
		line-height: 14px;
		padding: 5px 15px;
	}
	&__card-class {
		color: #a5a5a5;
		display: block;
		margin-top: 20px;
	}
	&__card-progress {
		-webkit-box-shadow: inset 0 15px 15px -15px rgba(0,0,0,0.2);
	    -moz-box-shadow: inset 0 15px 15px -15px rgba(0,0,0,0.2);
	    box-shadow: inset 0 15px 15px -15px rgba(0,0,0,0.2);
	    color: #4a4a4a;
	    font-size: 14px;
	    font-family: $primaryFontBold;
	    padding: 20px;
	    text-align: center;
	}
	&__unit-deadline {
		color: #ffa22b;
		font-size: 12px;
		margin-left: 10px;
		padding-left: 20px;
		position: relative;
	}
	&__unit-deadline-img {
		left: 0;
		height: 18px;
		position: absolute;
		top: -4px;
		width: auto;
	}
	&:after {
			background-image: url('../images/pattern.png');
			content: '';
			height: 220px;
			left: 0;
			position: absolute;
			top: 0;
			width: 100vw;
			z-index: -1;
	};
}
.project-dash--alt:after {
	background-image: url('../images/pattern-alt.png');
}

@media screen and (min-width: $tablet) {
	.project-dash {
		padding: 40px 40px 40px 160px;
	}
}

@media screen and (min-width: $desktop) {
	.project-dash {
		&__card, &__units {
			float: left;
			width: 250px;
		}
		&__units {
			margin: 0 0 0 40px;
			max-width: 740px;
			width: calc(100% - 290px);
		}
	}
}

@media screen and (min-width: 1200px) {
	.project-dash__card {
		width: 300px;
	}
	.project-dash__units {
		width: calc(100% - 340px);
	}
}