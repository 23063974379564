@import "./../../../newShared.scss";

.classCard {
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  height: 160px;
  background: url("../../../../../images/newDashboard/decorations/classCardDeco.png");
  background-repeat: no-repeat;
  background-color: white;
  margin: 1em 1em 1em 0em;
  transition: all 200ms ease;
  background-size: 366px 160px;
  box-shadow: inset 0px -4px 0px 0px $main-purple--hover;

  &:hover {
    background: url("../../../../../images/newDashboard/decorations/classCardDecoHover.png");
    background-repeat: no-repeat;
    border-radius: 10px;
    background-color: white;
    background-size: 364px 160px;
  }
  &.writingClub {
    background: url("../../../../../images/newDashboard/decorations/classCardDecoWritingClub.png");
    background-repeat: no-repeat;
    background-color: white;

    &:hover {
      background: url("../../../../../images/newDashboard/decorations/classCardDecoWritingClubHover.png");
      background-repeat: no-repeat;
      background-color: white;
    }
  }
  .informationContainer {
    color: white;
    width: 357px;
    height: 160px;
    padding: 2em;
    transition: all 200ms ease;

    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .projects {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px 0px 10px 0px;
    .adjustDiv {
      margin-left: 20px;
    }
    .seeMore {
      background: $main-purple;
      border-radius: 5px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      width: 120px;
      text-align: center;
      padding: 4px 1em;
      margin: 2px auto;
      &:hover {
        background-color: $main-purple--hover;
      }
    }
  }
  .classmatesContainer {
    margin-top: 0.75em;
    display: flex;
  }
}

@media (max-width: 1200px) {
  .classCard {
    background-size: 250px 160px;
    &:hover {
      background-size: 248px 160px;
    }

    .informationContainer {
      width: 248px;
      padding: 1em;
    }
  }
}

@media (max-width: 1015px) {
  .classCard {
    width: 100%;
    background-size: 205px 160px;
    &:hover {
      background-size: 203px 160px;
    }

    .informationContainer {
      width: 206px;
      padding: 1em 0.25em;
    }
  }
}

@media (max-width: 510px) {
  .classCard {
    .projects {
      .seeMore {
        margin: 2px 10px;
      }
    }
  }
}

@media (max-width: 420px) {
  .classCard {
    background-size: 130px 160px;
    .informationContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
    &:hover {
      background-size: 128px 160px;
    }

    .informationContainer {
      width: 136px;
      padding: 1em 0.25em;
    }
  }
}



.classHeader {
  background: #FFF;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px 12px 17px 12px;
  box-shadow: inset 0px -8px 0px 0px #a4e9ff;
}