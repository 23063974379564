

.container {
    background: #FFF;
    margin: 0 -50px -20px -50px;
}

.storyStart {
    max-width: 700px;
    padding: 20px;
    margin: 0 auto;
    white-space: pre-wrap;
    font-size: 22px;
    line-height: 30px;
    color: #4a4a4a;
}

.author {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    .by {
        color: #8b8b8b;
        margin-bottom: 5px;
    }
    .authorName {
        font-family: "Gotham Rounded Bold";
    }
}

.button {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}


@media (max-width: 1000px) {
    .container {
    margin: 0 -20px -20px -20px;
    }
}

@media (max-width: 750px) {
    .container {
    margin: 0 -20px 70px -20px;
    }
}