@font-face {
  font-family: 'Gotham Rounded Book';
  src: url("https://bw-students-prod.s3.us-west-2.amazonaws.com/fonts/GothamRounded-Book.woff"); }

@font-face {
  font-family: 'Gotham Rounded Bold';
  src: url("https://bw-students-prod.s3.us-west-2.amazonaws.com/fonts/GothamRounded-Bold.woff"); }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  min-height: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

* html .clearfix {
  zoom: 1; }

/* IE6 */
*:first-child + html .clearfix {
  zoom: 1; }

/* IE7 */
/* chrome books chrome window is 1280 x 673 */
html, body {
  font-family: "Gotham Rounded Book";
  min-height: 100%; }

body {
  background: url("./../images/theme/pattern-blue.png"); }

h1 {
  font-size: 28px;
  font-family: "Gotham Rounded Bold"; }

strong {
  font-family: "Gotham Rounded Bold"; }

/* MARGINS */
.u-m-base-5 {
  margin-bottom: 5px; }

.u-m-base-10 {
  margin-bottom: 10px; }

.u-m-base-15 {
  margin-bottom: 15px; }

.u-m-base-20 {
  margin-bottom: 20px; }

.u-m-base-40 {
  margin-bottom: 40px; }

.u-m-top-5 {
  margin-top: 5px; }

.u-m-top-10 {
  margin-top: 10px; }

.u-m-top-15 {
  margin-top: 15px; }

.u-m-top-20 {
  margin-top: 20px; }

.u-m-top-40 {
  margin-top: 40px; }

.u-padded-20 {
  padding: 20px; }

.u-m-right-10 {
  margin-right: 10px; }

/* TEXT */
.u-t-green {
  color: #56AF46; }

.u-full-width {
  width: 100%; }

.u-inner-scroll {
  flex: 1;
  overflow-y: scroll; }

.u-hidden-mobile,
.u-only-desktop,
.u-only-tablet,
.u-visible-tablet,
.u-visible-desktop {
  display: none; }

.u-hidden-tablet,
.u-hidden-desktop,
.u-only-mobile,
.u-visible-mobile {
  display: block; }

.u-hidden {
  display: none; }

.u-bold {
  font-family: "Gotham Rounded Bold"; }

.u-blue-font {
  color: #00A5FF; }

.u-centered {
  margin-left: auto;
  margin-right: auto; }

.u-centered-text {
  text-align: center; }

.u-align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.u-border-sep {
  align-items: center;
  display: flex; }
  .u-border-sep::before, .u-border-sep::after {
    background: #D8D8D8;
    content: '';
    height: 1px;
    flex: 1; }

.u-border-sep__text {
  color: #FFCE89;
  flex: 0;
  font-size: 18px;
  font-family: "Gotham Rounded Bold";
  line-height: 16px;
  padding: 0 10px;
  text-align: center; }

.u-border-sep__button {
  margin: 0 10px; }

.u-rel {
  position: relative; }

.u-pill {
  align-items: center;
  border-radius: 10px;
  display: inline-flex;
  font-size: 14px;
  font-family: "Gotham Rounded Bold";
  padding: 5px 10px; }
  .u-pill__image {
    height: 14px;
    margin-right: 5px; }
  .u-pill--grey {
    background: #EEEEEE;
    color: #929292; }
  .u-pill--pending {
    background: #FFF7CB;
    border: 1px solid #FA8A1F;
    color: #FA8A1F; }
  .u-pill--alert {
    background: #F8CDCD;
    border: 1px solid #E91D1D;
    color: #E91D1D; }
  .u-pill--success {
    background: #D3F9D0;
    border: 1px solid #23B232;
    color: #23B232; }
  .u-pill--light-text {
    font-family: "Gotham Rounded Book";
    font-size: 12px; }

@media screen and (min-width: 750px) {
  .u-hidden-tablet,
  .u-only-desktop,
  .u-only-mobile {
    display: none; }
  .u-hidden-mobile,
  .u-hidden-desktop,
  .u-only-tablet,
  .u-visible-tablet {
    display: block; } }

@media screen and (min-width: 1000px) {
  .u-hidden-desktop,
  .u-only-mobile,
  .u-only-tablet {
    display: none; }
  .u-hidden-mobile,
  .u-hidden-tablet,
  .u-only-desktop,
  .u-visible-desktop {
    display: block; } }

.tagvocab {
  background: #FCC62D;
  border-radius: 15px;
  display: inline-block;
  padding: 0px 8px; }

.pulse {
  box-shadow: 0 0 0 #2ecc71;
  animation: pulse 1s; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #fcc62d; }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(252, 198, 45, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(252, 198, 45, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #fcc62d;
    box-shadow: 0 0 0 0 rgba(252, 198, 45, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
    box-shadow: 0 0 0 15px rgba(252, 198, 45, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
    box-shadow: 0 0 0 0 rgba(252, 198, 45, 0); } }

.green-pulse {
  box-shadow: 0 0 0 #23b232;
  animation: green-pulse 1s; }

@-webkit-keyframes green-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #23b232; }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(35, 178, 50, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(35, 178, 50, 0); } }

@keyframes green-pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #23b232;
    box-shadow: 0 0 0 0 #23b232; }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(35, 178, 50, 0);
    box-shadow: 0 0 0 15px rgba(35, 178, 50, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(35, 178, 50, 0);
    box-shadow: 0 0 0 0 rgba(35, 178, 50, 0); } }

.emoji-warning {
  line-height: 20px;
  max-width: 600px; }

.emoji-warning-img {
  height: auto;
  margin-top: 20px;
  width: 50px; }

.light-panel {
  background: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); }

.inset-panel {
  background: #ECECEC;
  border: 1px solid #E6E6E6;
  border-radius: 15px; }

.masonry-grid {
  column-count: 1;
  column-gap: 20px; }

/* chrome renders the css grid slightly differently to other browsers so we add this class to the children of the grid container */
.chrome-item {
  -webkit-column-break-inside: avoid;
  break-inside: avoid-column; }

/* this is for non chrome browsers */
.masonry-item {
  display: inline-block; }

@media screen and (min-width: 700px) {
  .masonry-grid {
    column-count: 2; } }

@media screen and (min-width: 750px) {
  .masonry-grid {
    column-count: 1; } }

@media screen and (min-width: 840px) {
  .masonry-grid {
    column-count: 2; } }

@media screen and (min-width: 1000px) {
  .masonry-grid {
    column-count: 1; } }

@media screen and (min-width: 1260px) {
  .masonry-grid {
    column-count: 2; } }

@media screen and (min-width: 1600px) {
  .masonry-grid {
    column-count: 3; } }

.main-menu {
  background: #4E406E;
  bottom: 0;
  color: #FCC62D;
  display: block;
  font-family: "Gotham Rounded Book";
  height: 85px;
  position: absolute;
  width: 100%;
  z-index: 450;
  display: flex;
  flex-direction: column; }
  .main-menu__items {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .main-menu__link {
    background: none;
    border: none;
    color: #FFCE89;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-family: "Gotham Rounded Bold";
    height: 85px;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; }
    .main-menu__link--active {
      background: #3D2D63; }
  .main-menu__inner {
    display: block;
    height: 65px;
    position: relative;
    width: 100%;
    border-right: 1px solid rgba(144, 143, 147, 0.2); }
    .main-menu__inner--last {
      border-right: none; }
    .main-menu__inner--rel {
      position: relative; }
  .main-menu__boomer {
    height: 35px;
    margin: 5px auto 0;
    overflow: hidden;
    position: relative; }
    .main-menu__boomer--badge {
      margin: 0 auto; }
  .main-menu__boomer-badge {
    display: block;
    position: absolute;
    height: auto;
    left: 50%;
    margin-left: -11px;
    top: 30px;
    width: 22px; }
  .main-menu__link-text {
    display: block;
    line-height: normal;
    margin-top: 10px; }
    .main-menu__link-text--badge {
      margin-top: 15px; }
  .main-menu__item {
    float: left;
    width: 25%;
    padding: 0;
    display: block; }
  .main-menu__icon {
    display: block;
    height: 35px;
    margin: 5px auto 0;
    width: auto; }
    .main-menu__icon--chat {
      color: #FFF;
      background-size: 25px auto;
      background-position: center center;
      background-repeat: no-repeat;
      padding-top: 10px;
      width: 35px; }
    .main-menu__icon--calendar {
      width: 35px; }
  .main-menu__user-info, .main-menu__copyright {
    display: none; }
  .main-menu--hidden-mobile {
    display: none; }

@media screen and (min-width: 750px) {
  .main-menu {
    height: 100%;
    width: 120px;
    position: fixed; }
    .main-menu__user-info, .main-menu__copyRight {
      display: block; }
    .main-menu__user-info {
      font-size: 14px;
      font-weight: 100;
      line-height: 18px;
      padding: 20px 10px 0 10px;
      text-align: center; }
    .main-menu__name {
      display: block;
      font-family: "Gotham Rounded Bold"; }
    .main-menu__coins {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      display: inline-block; }
    .main-menu__wallet {
      border-top: 1px solid rgba(144, 143, 147, 0.2);
      border-bottom: 1px solid rgba(144, 143, 147, 0.2);
      display: flex;
      justify-content: center;
      padding: 10px 0;
      margin-top: 10px; }
    .main-menu__copyright {
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      display: block;
      color: #BFBFBF;
      margin: 15px 0px;
      width: 100%; }
    .main-menu__icon {
      margin: 0 auto 5px auto;
      display: block;
      width: 50%;
      height: auto; }
      .main-menu__icon--ava {
        width: 40%; }
      .main-menu__icon--logout {
        width: 30%; }
      .main-menu__icon--portfolio {
        width: 60%; }
      .main-menu__icon--chat {
        height: 25px;
        margin: 5px auto 0 auto;
        padding-top: 6px;
        width: 25px; }
    .main-menu__item {
      float: none;
      width: 100%; }
    .main-menu__link {
      height: initial;
      position: relative;
      padding: 0 10px; }
      .main-menu__link::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 100%;
        z-index: 0;
        background-color: #00A5FF;
        transition: right 0.15s ease; }
      .main-menu__link:hover {
        color: #FFF; }
        .main-menu__link:hover::before {
          right: 0; }
      .main-menu__link--active:hover {
        color: #FCC62D; }
        .main-menu__link--active:hover::before {
          right: 97%; }
      .main-menu__link--active::before {
        right: 97%; }
    .main-menu__inner {
      padding: 12px 0;
      border-bottom: 1px solid rgba(144, 143, 147, 0.2);
      border-right: none;
      width: initial;
      height: initial;
      z-index: 1; }
    .main-menu__boomer {
      height: 40px;
      margin: 0px auto 5px; }
    .main-menu__boomer-badge {
      margin-left: -15px;
      top: 45px;
      width: 30px; }
    .main-menu__link-text {
      display: inline-block;
      margin-top: none; }
    .main-menu--hidden-mobile {
      display: flex; } }

@media screen and (min-height: 700px) and (min-width: 750px) {
  .main-menu__icon {
    margin: 0 auto 10px auto;
    display: block;
    width: 60%;
    height: auto; }
    .main-menu__icon--ava {
      width: 50%; }
    .main-menu__icon--logout {
      width: 40%; }
    .main-menu__icon--portfolio {
      width: 70%; }
    .main-menu__icon--chat {
      margin: 5px auto 5px auto;
      height: 25px;
      width: 25px; }
  .main-menu__boomer {
    height: 45px;
    margin: 0px auto 10px; }
  .main-menu__boomer-badge {
    top: 58px; }
  .main-menu__inner {
    padding: 20px 0; } }

.boomer-heading {
  font-family: "Gotham Rounded Book";
  padding-left: 65px;
  margin-bottom: 20px;
  position: relative; }
  .boomer-heading--no-boomer {
    padding-left: 0 !important; }
  .boomer-heading__boomer {
    height: 61px;
    width: 61px;
    left: 0;
    border-radius: 61px;
    overflow: hidden;
    border: 5px solid #FCC62D;
    position: absolute; }
  .boomer-heading__boomer--rank {
    border: 1px solid #FCC62D; }
  .boomer-heading__title {
    color: white;
    font-size: 20px;
    font-family: "Gotham Rounded Bold";
    margin-bottom: 18px;
    position: relative; }
  .boomer-heading__title::before {
    background: #FCC62D;
    content: '';
    display: block;
    height: 5px;
    position: absolute;
    bottom: -10px;
    width: 20px; }
  .boomer-heading__badge {
    cursor: pointer;
    position: absolute;
    height: auto;
    left: 14px;
    top: 45px;
    width: 34px;
    z-index: 50; }
  .boomer-heading__sub {
    color: white;
    font-size: 16px;
    font-weight: 500; }
  .boomer-heading__rank {
    position: absolute;
    left: -5px;
    top: 5px;
    height: auto;
    width: 70px;
    z-index: 49; }
  .boomer-heading__wb-logo {
    height: auto;
    left: -5px;
    position: absolute;
    width: 55px; }
  .boomer-heading--boomer-desktop-only {
    padding-left: 0; }
    .boomer-heading--boomer-desktop-only .boomer-heading__boomer, .boomer-heading--boomer-desktop-only .boomer-heading__badge, .boomer-heading--boomer-desktop-only .boomer-heading__rank, .boomer-heading--boomer-desktop-only .boomer-heading__wb-logo {
      display: none; }
  .boomer-heading--project-home {
    margin-bottom: 0; }
    .boomer-heading--project-home .boomer-heading__title {
      color: #FCC62D; }
    .boomer-heading--project-home .boomer-heading__sub {
      color: #000; }
  .boomer-heading--success .boomer-heading__title {
    color: #23B232; }
  .boomer-heading--reject .boomer-heading__title {
    color: #FF8481; }
  .boomer-heading--writing {
    margin-bottom: 40px; }

@media screen and (min-width: 1000px) {
  .boomer-heading--boomer-desktop-only {
    padding-left: 65px; }
    .boomer-heading--boomer-desktop-only .boomer-heading__boomer, .boomer-heading--boomer-desktop-only .boomer-heading__badge, .boomer-heading--boomer-desktop-only .boomer-heading__rank, .boomer-heading--boomer-desktop-only .boomer-heading__wb-logo {
      display: block; }
  .boomer-heading__title {
    font-size: 28px;
    margin-bottom: 15px; }
  .boomer-heading__title::before {
    bottom: -7px; }
  .boomer-heading__sub {
    font-size: 18px; } }

.button {
  background: #00A5FF;
  border-radius: 12px;
  color: #FFF;
  cursor: pointer;
  display: block;
  font-family: "Gotham Rounded Bold";
  font-size: 18px;
  padding: 0 10px;
  text-align: center;
  line-height: 34px;
  text-decoration: none;
  transition: background 0.1s ease, box-shadow 0.1s ease; }
  .button:hover {
    background: #0084cc; }
  .button:active {
    background: #33b7ff; }
  .button:focus {
    outline: none; }
  .button:disabled {
    opacity: 0.5 !important;
    pointer-events: none; }

.button__image {
  height: 18px; }

.button--bordered {
  background: #00A5FF;
  border: 4px solid #FCC62D;
  box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  font-size: 20px;
  line-height: 20px;
  padding: 10px 20px 10px 20px; }
  .button--bordered:hover {
    background: #00A5FF;
    box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.3);
    padding: 11px 20px 9px 20px; }
  .button--bordered:focus {
    outline: none; }
  .button--bordered:active {
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.3);
    padding: 14px 20px 6px 20px; }

.button--purple {
  background: #4E406E; }
  .button--purple:hover {
    background: #4E406E; }

.button--yellow {
  background: #FCC62D;
  color: #4E406E; }
  .button--yellow:hover {
    background: #f3b403; }
  .button--yellow:active {
    background: #fdd45f; }
  .button--yellow.button--bordered {
    border: 4px solid #00A5FF; }

.button--red {
  background: #E91D1D; }
  .button--red:hover {
    background: #d81515; }
  .button--red:active {
    background: #ee4b4b; }

.button--green {
  background: #23B232; }
  .button--green:hover {
    background: #1f9d2c; }
  .button--green:active {
    background: #31d743; }

.button--white {
  background: #FFFFFF;
  color: #00A5FF; }
  .button--white:hover {
    background: #f2f2f2; }
  .button--white:active {
    background: #33b7ff;
    color: #FFFFFF; }
  .button--white.button--bordered {
    border: 4px solid #0084cc; }

.button--outline {
  background: none;
  border: 2px solid #00A5FF;
  color: #00A5FF;
  display: inline-block;
  padding: 0 10px; }
  .button--outline:hover {
    background: #00A5FF;
    color: #FFF; }

.button--full-width {
  width: 100%; }

.button--no-border {
  border: none; }

.button--inline-block {
  display: inline-block; }

.tickles-button, .winner-tickle-button {
  background: #FF7600;
  border: none;
  border-radius: 20px;
  color: #FFFFFF;
  font-family: "Gotham Rounded Bold";
  font-size: 12px;
  line-height: 16px;
  padding: 5px 30px 5px 35px;
  position: absolute;
  right: 0;
  top: -50px; }
  .tickles-button__icon, .winner-tickle-button__icon {
    height: auto;
    left: -8px;
    position: absolute;
    top: -2px;
    width: 34px; }
  .tickles-button__counter, .winner-tickle-button__counter {
    border-radius: 200px;
    font-size: 14px;
    line-height: 24px;
    background: #E91D1D;
    height: 24px;
    right: 1px;
    position: absolute;
    top: 1px;
    width: 24px; }
  .tickles-button--review, .winner-tickle-button--review {
    top: 276px;
    right: 175px;
    z-index: 10; }
  .tickles-button--preview, .winner-tickle-button--preview {
    display: none; }

.winner-tickle-button {
  cursor: pointer;
  right: 20px;
  top: 20px; }
  .winner-tickle-button:hover {
    background: #ff9133; }

@media screen and (min-width: 750px) {
  .tickles-button--review {
    top: 254px; } }

@media screen and (min-width: 1000px) {
  .tickles-button {
    border: 4px solid #FCC62D;
    border-radius: 12px;
    box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    font-size: 20px;
    line-height: 22px;
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
    position: relative;
    right: initial;
    text-align: center;
    text-decoration: none;
    top: initial;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    width: 100%; }
    .tickles-button__icon {
      left: calc(50% - 70px);
      top: 4px;
      width: 26px; }
    .tickles-button__counter {
      right: calc(50% - 70px); }
    .tickles-button:hover {
      box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.3);
      padding: 11px 20px 9px 20px; }
      .tickles-button:hover .tickles-button__counter {
        top: 2px; }
      .tickles-button:hover .tickles-button__icon {
        top: 5px; }
    .tickles-button:focus {
      outline: none; }
    .tickles-button:active {
      box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.3);
      background: #ff9133;
      padding: 14px 20px 6px 20px; } }

.entry-history {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Gotham Rounded Bold";
  margin-top: 5px;
  padding: 4px 4px 2px 18px;
  position: relative;
  line-height: 16px; }
  .entry-history:hover {
    color: #23b232; }
  .entry-history--save {
    background: #00A5FF;
    border: none;
    color: #FFF;
    margin-right: 5px;
    padding: 4px 8px 2px 8px;
    width: 70px; }
    .entry-history--save:focus {
      outline: none; }
    .entry-history--save:hover {
      color: #FFF; }
  .entry-history--disabled {
    opacity: 0.5;
    cursor: default; }
  .entry-history__icon {
    background: #23b232;
    border-radius: 10px;
    display: block;
    height: 10px;
    left: 4px;
    top: calc(50% - 5px);
    position: absolute;
    width: 10px; }

.split50 {
  width: 100%; }

.user-view {
  display: block;
  height: calc(100vh - 85px);
  overflow-y: scroll;
  padding: 20px;
  width: 100%; }
  .user-view--fullscreen-mob {
    height: 100vh;
    padding: 0; }

@media screen and (max-width: 375px) {
  .user-view {
    padding: 10px; }
    .user-view--fullscreen-mob {
      height: 100vh;
      padding: 0; } }

@media screen and (min-width: 500px) {
  .user-view {
    padding: 30px; }
    .user-view--fullscreen-mob {
      padding: 0; } }

@media screen and (min-width: 750px) {
  .user-view {
    float: left;
    padding: 30px 30px 30px 150px;
    height: initial;
    min-height: 100vh;
    width: 100%; }
    .user-view--has-sub-menu {
      padding: 30px 30px 30px 325px; }
    .user-view--fullscreen-mob {
      height: auto; } }

@media screen and (min-width: 1000px) {
  .user-view {
    padding: 50px 50px 50px 170px; }
    .user-view--has-sub-menu {
      padding: 50px 50px 50px 345px; }
  .split50 {
    width: 50%;
    float: left; } }

.main-loader {
  align-items: center;
  background-color: white;
  color: #4E406E;
  display: flex;
  flex-direction: column;
  font-family: "Gotham Rounded Bold";
  font-size: 24px;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 200; }
  .main-loader__svg {
    margin-top: -150px;
    width: 200px; }
  .main-loader__img {
    width: 200px; }
  .main-loader__message {
    margin-top: -50px; }
  .main-loader--fill-parent {
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    position: absolute;
    top: 0; }
  .main-loader--project {
    background: #F3F3F3;
    top: 58px; }

.u-full-width .main-loader {
  width: 100% !important; }

@media screen and (min-width: 750px) {
  .main-loader {
    width: calc(100% - 120px); }
    .main-loader--has-sub-menu {
      width: calc(100% - 295px); }
    .main-loader--fill-parent {
      width: 100%; }
    .main-loader--project {
      top: 50px; } }

@media screen and (min-width: 1000px) {
  .main-loader--project {
    top: 60px; } }

@media screen and (max-width: 749px) {
  .sub-menu {
    background: #99dbff;
    border-radius: 12px;
    overflow: hidden; }
    .sub-menu__title {
      display: none; }
    .sub-menu__items {
      display: flex; }
    .sub-menu__item {
      border-right: 1px solid #66c9ff;
      flex: 1; }
      .sub-menu__item:last-child {
        border: none; }
    .sub-menu__link {
      color: #FFF;
      display: block;
      font-size: 18px;
      font-weight: bold;
      padding: 10px 9px;
      text-align: center;
      text-decoration: none; }
      .sub-menu__link:hover {
        background: #0084cc; }
      .sub-menu__link.active {
        background: #00A5FF; } }

@media screen and (max-width: 375px) {
  .sub-menu__link {
    font-size: 16px;
    padding: 10px 5px; } }

@media screen and (min-width: 750px) {
  .sub-menu {
    background: #3D2D63;
    border-left: 1px solid #65538e;
    display: block;
    font-weight: bold;
    height: 100%;
    left: 120px;
    position: fixed;
    width: 175px;
    text-align: center;
    top: 0; }
    .sub-menu__title {
      color: #FCC62D;
      font-size: 18px;
      padding: 20px; }
    .sub-menu__item {
      border-top: 1px solid #65538e;
      margin: 0 20px; }
      .sub-menu__item:last-child {
        border-bottom: 1px solid #65538e; }
    .sub-menu__link {
      color: #FFF;
      display: block;
      font-size: 14px;
      margin: 0 -20px;
      padding: 20px;
      text-decoration: none;
      transition: all 0.1s ease; }
      .sub-menu__link:hover {
        background: #271d40; }
      .sub-menu__link.active {
        background: #00A5FF; } }

.school-text-00 {
  color: #7b389e; }

.school-text-01 {
  color: #8e44ad; }

.school-text-02 {
  color: #9b59b6; }

.school-text-10 {
  color: #c0392b; }

.school-text-11 {
  color: #e74c3c; }

.school-text-12 {
  color: #ff5d55; }

.school-text-20 {
  color: #d35400; }

.school-text-21 {
  color: #e67e22; }

.school-text-22 {
  color: #f39c12; }

.school-text-30 {
  color: #1f9b51; }

.school-text-31 {
  color: #27ae60; }

.school-text-32 {
  color: #2ecc71; }

.school-text-40 {
  color: #0f846e; }

.school-text-41 {
  color: #16a085; }

.school-text-42 {
  color: #1abc9c; }

.school-text-50 {
  color: #375d7c; }

.school-text-51 {
  color: #2980b9; }

.school-text-52 {
  color: #3498db; }

.school-theme-00 {
  background: #7b389e; }
  .school-theme-00 .icon-holder, .school-theme-00 .school-district, .school-theme-00 .school-theme-alt {
    background: #8e44ad; }

.school-theme-01 {
  background: #8e44ad; }
  .school-theme-01 .icon-holder, .school-theme-01 .school-district, .school-theme-01 .school-theme-alt {
    background: #9b59b6; }

.school-theme-02 {
  background: #9b59b6; }
  .school-theme-02 .icon-holder, .school-theme-02 .school-district, .school-theme-02 .school-theme-alt {
    background: #8e44ad; }

.school-theme-10 {
  background: #c0392b; }
  .school-theme-10 .icon-holder, .school-theme-10 .school-district, .school-theme-10 .school-theme-alt {
    background: #e74c3c; }

.school-theme-11 {
  background: #e74c3c; }
  .school-theme-11 .icon-holder, .school-theme-11 .school-district, .school-theme-11 .school-theme-alt {
    background: #ff5d55; }

.school-theme-12 {
  background: #ff5d55; }
  .school-theme-12 .icon-holder, .school-theme-12 .school-district, .school-theme-12 .school-theme-alt {
    background: #e74c3c; }

.school-theme-20 {
  background: #d35400; }
  .school-theme-20 .icon-holder, .school-theme-20 .school-district, .school-theme-20 .school-theme-alt {
    background: #e67e22; }

.school-theme-21 {
  background: #e67e22; }
  .school-theme-21 .icon-holder, .school-theme-21 .school-district, .school-theme-21 .school-theme-alt {
    background: #f39c12; }

.school-theme-22 {
  background: #f39c12; }
  .school-theme-22 .icon-holder, .school-theme-22 .school-district, .school-theme-22 .school-theme-alt {
    background: #e67e22; }

.school-theme-30 {
  background: #1f9b51; }
  .school-theme-30 .icon-holder, .school-theme-30 .school-district, .school-theme-30 .school-theme-alt {
    background: #27ae60; }

.school-theme-31 {
  background: #27ae60; }
  .school-theme-31 .icon-holder, .school-theme-31 .school-district, .school-theme-31 .school-theme-alt {
    background: #2ecc71; }

.school-theme-32 {
  background: #2ecc71; }
  .school-theme-32 .icon-holder, .school-theme-32 .school-district, .school-theme-32 .school-theme-alt {
    background: #27ae60; }

.school-theme-40 {
  background: #0f846e; }
  .school-theme-40 .icon-holder, .school-theme-40 .school-district, .school-theme-40 .school-theme-alt {
    background: #16a085; }

.school-theme-41 {
  background: #16a085; }
  .school-theme-41 .icon-holder, .school-theme-41 .school-district, .school-theme-41 .school-theme-alt {
    background: #1abc9c; }

.school-theme-42 {
  background: #1abc9c; }
  .school-theme-42 .icon-holder, .school-theme-42 .school-district, .school-theme-42 .school-theme-alt {
    background: #16a085; }

.school-theme-50 {
  background: #375d7c; }
  .school-theme-50 .icon-holder, .school-theme-50 .school-district, .school-theme-50 .school-theme-alt {
    background: #2980b9; }

.school-theme-51 {
  background: #2980b9; }
  .school-theme-51 .icon-holder, .school-theme-51 .school-district, .school-theme-51 .school-theme-alt {
    background: #3498db; }

.school-theme-52 {
  background: #3498db; }
  .school-theme-52 .icon-holder, .school-theme-52 .school-district, .school-theme-52 .school-theme-alt {
    background: #2980b9; }

.basic-form__group {
  margin-bottom: 20px; }

.basic-form__text-box {
  border: 2px solid #DADADA;
  border-radius: 12px;
  box-shadow: inset 0 5px 6px #DADADA;
  color: #4a4a4a;
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  line-height: 18px;
  padding: 12px 20px 9px 20px;
  width: 100%; }
  .basic-form__text-box:focus {
    color: #000;
    border: 4px solid #FCC62D;
    padding: 10px 18px 7px 18px;
    box-shadow: none;
    outline: none; }
  .basic-form__text-box--locked {
    background: #F4F4F4;
    color: #969696; }

.basic-form__label {
  color: white;
  display: block;
  font-family: "Gotham Rounded Bold";
  font-size: 14px; }

.basic-form__disclaimer {
  color: white;
  font-family: "Gotham Rounded Bold";
  font-size: 12px; }

.basic-form__hint {
  color: #E91D1D;
  font-family: "Gotham Rounded Bold";
  font-size: 12px;
  margin-top: 10px; }

.basic-form__success, .basic-form__errors {
  align-items: center;
  background: #D3F9D0;
  border-radius: 6px;
  border: 1px solid #23B232;
  color: #23B232;
  display: flex;
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  margin-top: 20px;
  padding: 15px 10px; }

.basic-form__errors {
  background: #F8CDCD;
  border-color: #E91D1D;
  color: #E91D1D; }

.basic-form__result-img {
  margin-right: 7px;
  width: 20px; }
  .basic-form__result-img--error {
    width: 30px; }

.dropdown {
  appearance: none;
  background-color: #FFFFFF;
  background-image: url("../images/chevron-down.png");
  background-position: right 16px top 16px;
  background-repeat: no-repeat;
  background-size: 22px auto;
  border: 1px solid #D6D6D6;
  border-radius: 12px;
  color: #00A5FF;
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  line-height: 16px;
  padding: 15px 50px 15px 20px;
  width: 100%; }
  .dropdown:focus {
    border: 1px solid #00A5FF;
    outline: none; }

@media screen and (min-width: 750px) {
  .basic-form {
    max-width: 350px; }
    .basic-form__label {
      font-size: 16px; } }

.school-card {
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 20px; }
  .school-card__image-holder {
    align-items: center;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    height: 80px;
    width: 80px; }
  .school-card__image {
    max-height: 50px;
    max-width: 50px; }
  .school-card__text-holder {
    flex: 1;
    margin-left: 10px; }
  .school-card__name {
    font-size: 21px; }
  .school-card__grade {
    font-size: 18px;
    font-family: "Gotham Rounded Bold"; }
  .school-card__location {
    font-size: 14px;
    font-style: italic; }

.school-bar {
  color: #00A5FF;
  font-family: "Gotham Rounded Bold";
  padding: 5px;
  width: 100%; }
  .school-bar__inner {
    background: #FFF;
    border-radius: 12px;
    display: inline-block;
    padding: 16px 20px 16px 45px;
    position: relative;
    width: 100%; }
  .school-bar__icon {
    display: inline-block;
    height: 24px;
    position: absolute;
    left: 14px;
    top: 10px;
    width: 24px; }
  .school-bar__image {
    width: 24px;
    height: 24px; }
  .school-bar__name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }

.school-bar__filters {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%; }

.school-bar__dropdown {
  width: calc(50% - 10px); }

@media screen and (min-width: 750px) {
  .school-card {
    flex-direction: column;
    justify-content: center; }
    .school-card__text-holder {
      margin-top: 10px;
      text-align: center; } }

@media screen and (min-width: 1000px) {
  .school-bar__inner {
    width: initial; }
    .school-bar__inner--portfolio {
      float: left;
      max-width: calc(100% - 420px); }
  .school-bar__filters {
    float: right;
    margin-top: 0;
    width: 400px; } }

.boomer-panel {
  align-items: center;
  background-color: #4E406E;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: #FFCE89;
  display: flex;
  left: 0;
  padding: 10px 70px 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10; }
  .boomer-panel__content {
    flex: 1;
    margin-left: 10px; }
  .boomer-panel__content-top {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px; }
  .boomer-panel__desktop {
    display: none; }
  .boomer-panel__tablet {
    display: none; }
  .boomer-panel__rank {
    left: 15px;
    height: auto;
    position: absolute;
    top: 18px;
    width: 80px; }
  .boomer-panel__boomer {
    background: #FFF;
    border-radius: 70px;
    height: 70px;
    overflow: hidden;
    width: 70px; }
    .boomer-panel__boomer .boomer-holder__badge {
      display: block;
      left: 72px;
      top: 55px;
      width: 18px; }
  .boomer-panel__coins {
    align-items: center;
    display: flex; }
  .boomer-panel__coins-img {
    height: 18px;
    margin-right: 3px; }

@media screen and (min-width: 750px) {
  .boomer-panel {
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 20px;
    position: relative; }
    .boomer-panel__boomer .boomer-holder__badge {
      top: 64px; }
    .boomer-panel__mobile {
      display: none; }
    .boomer-panel__tablet {
      display: block; }
    .boomer-panel__rank {
      top: 28px; } }

@media screen and (min-width: 1000px) {
  .boomer-panel {
    box-shadow: none;
    display: block;
    padding: 20px 0; }
    .boomer-panel__content {
      margin-left: 0;
      padding: 0 20px; }
    .boomer-panel__content-top {
      align-content: center;
      justify-content: center; }
    .boomer-panel__desktop {
      display: block;
      margin-left: 5px; }
    .boomer-panel__mobile {
      display: none; }
    .boomer-panel__tablet {
      display: none; }
    .boomer-panel__rank {
      width: 232px;
      z-index: 50;
      left: 50%;
      margin-left: -116px;
      top: 40px; }
    .boomer-panel__boomer {
      border-radius: 200px;
      height: 200px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      position: relative;
      width: 200px; }
      .boomer-panel__boomer .boomer-holder__badge {
        top: 64%;
        left: 50%;
        width: 52px; } }

.activity-feed {
  display: none; }

@media screen and (min-width: 1000px) {
  .activity-feed {
    display: block;
    text-align: center; }
    .activity-feed__header {
      border-bottom: 1px solid #979797;
      display: inline-block;
      font-family: "Gotham Rounded Bold";
      margin-top: 40px;
      padding-bottom: 10px; }
    .activity-feed__list {
      max-height: 280px;
      overflow-y: scroll; }
    .activity-feed__item {
      font-size: 14px;
      line-height: 20px;
      padding: 20px 10px 5px 10px; }
    .activity-feed__item--placeholder {
      padding: 20px 10px; }
    .activity-feed__item:nth-child(odd) {
      background: #3D2D63; }
    .activity-feed__coins-img {
      height: 14px;
      margin-right: 2px;
      position: relative;
      top: 2px; }
    .activity-feed__time {
      color: #D18720;
      font-size: 12px;
      text-align: right; } }

.confirm-overlay__body {
  align-items: center;
  background: #FFFFFF;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 30px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 500; }
  .confirm-overlay__body--winner {
    background: #4E406E;
    color: #FFCE89; }
    .confirm-overlay__body--winner .button {
      width: 100%; }

.confirm-overlay__close-button {
  display: none; }

.confirm-overlay__boomer {
  display: none; }

.confirm-overlay__message {
  font-size: 18px;
  font-family: "Gotham Rounded Bold";
  margin-top: 20px; }

.confirm-overlay__title {
  font-size: 24px;
  font-family: "Gotham Rounded Bold";
  margin-top: 20px; }

.confirm-overlay__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px; }

.confirm-overlay__button {
  margin: 0 5px; }

.confirm-overlay__hero {
  height: auto;
  width: 250px; }

.confirm-overlay__lads {
  height: auto;
  width: 350px; }

.confirm-overlay__earnings {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-align: center; }

.confirm-overlay__earnings-coins {
  height: 16px;
  margin: 0 5px;
  width: auto; }

.confirm-overlay--hide {
  display: none; }

.view-winner-overlay__inner {
  margin: 20px 0; }

@media screen and (min-width: 750px) {
  .confirm-overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 451; }
    .confirm-overlay__body {
      border-radius: 24px;
      max-width: 80%;
      position: relative; }
      .confirm-overlay__body--winner {
        background: #FFF;
        color: #4a4a4a;
        width: 600px; }
    .confirm-overlay__close-button {
      align-items: center;
      background: #c01313;
      border: none;
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      font-family: "Gotham Rounded Bold";
      height: 40px;
      justify-content: center;
      padding: 0;
      position: absolute;
      right: -30px;
      top: -30px;
      width: 40px; }
      .confirm-overlay__close-button:hover {
        background: #E91D1D; }
    .confirm-overlay__close-icon {
      color: #FAFAFA;
      font-size: 30px; }
    .confirm-overlay__boomer {
      background: #FFFFFF;
      border: 5px solid #FCC62D;
      border-radius: 61px;
      display: block;
      height: 70px;
      left: -20px;
      overflow: hidden;
      position: absolute;
      top: -20px;
      width: 70px; }
    .confirm-overlay__hero {
      position: absolute;
      top: -125px;
      width: 300px; }
    .confirm-overlay__lads {
      bottom: -50px;
      position: absolute;
      width: 550px; }
    .confirm-overlay__earnings-coins {
      height: 20px; }
    .confirm-overlay--hide {
      display: none; }
  .view-winner-overlay__inner {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-family: "Gotham Rounded Bold";
    font-size: 18px;
    margin: 0;
    padding: 20px 20px 50px 20px;
    width: 100%; } }

.user-view--dash {
  background: url(../images/pattern-grey.png); }

.dashboard {
  padding-top: 90px; }
  .dashboard__tasks-container {
    width: 100%; }

.dash-class {
  background: #7015d0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  color: #FFF;
  margin-bottom: 40px;
  overflow: hidden; }
  .dash-class__meta {
    color: #FFF;
    float: left;
    padding: 30px 20px 40px 20px;
    position: relative;
    text-decoration: none;
    width: 240px; }
    .dash-class__meta:before {
      background: #7c17e7;
      border-radius: 800px;
      content: '';
      left: -130px;
      top: -100px;
      position: absolute;
      height: 350px;
      width: 350px;
      z-index: 0; }
  .dash-class .dash-class__meta:hover:before {
    background: #7015d0; }
  .dash-class__teacher, .dash-class__title, .dash-class__student-count {
    position: relative;
    z-index: 2; }
  .dash-class__title {
    font-family: "Gotham Rounded Bold";
    font-size: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .dash-class__student-count {
    border-radius: 200px;
    background: orange;
    display: inline;
    font-family: "Gotham Rounded Bold";
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    text-align: center;
    color: white; }
    .dash-class__student-count__orange {
      background: #4E406E; }
  .dash-class__projects {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 156px;
    float: left;
    padding-right: 20px;
    width: calc(100% - 240px); }
  .dash-class__project {
    background: #7c17e7;
    border-radius: 15px;
    color: #FFF;
    font-size: 14px;
    padding: 7px 35px 7px 10px;
    position: relative;
    margin: 5px 0;
    text-decoration: none;
    width: 100%; }
    .dash-class__project:hover {
      background: #892eea; }
  .dash-class__phase {
    border-radius: 15px;
    bottom: 0;
    background-color: orange;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px auto;
    top: 0;
    position: absolute;
    right: 0;
    width: 35px; }
    .dash-class__phase--writing {
      background-image: url("../images/pencil.png"); }
    .dash-class__phase--voting {
      background-image: url("../images/check-black.png"); }
    .dash-class__phase--reading {
      background-image: url("../images/book-icon-black.png"); }
    .dash-class__phase--multi {
      background-image: url("../images/multi.png"); }

.dash-class--alt {
  background: orange;
  color: #4a4a4a; }
  .dash-class--alt .dash-class__meta {
    color: #4a4a4a; }
  .dash-class--alt .dash-class__meta:before {
    background: #ffb329; }
  .dash-class--alt .dash-class__student-count {
    background: #7015d0;
    color: #FFF; }
  .dash-class--alt .dash-class__project {
    background: #ffb329;
    color: #4a4a4a; }
  .dash-class--alt .dash-class__phase {
    background-color: #7015d0; }
  .dash-class--alt .dash-class__phase--writing {
    background-image: url("../images/pencil-alt.png"); }
  .dash-class--alt .dash-class__phase--voting {
    background-image: url("../images/check-white.png"); }
  .dash-class--alt .dash-class__phase--reading {
    background-image: url("../images/book-icon-white.png"); }
  .dash-class--alt .dash-class__phase--multi {
    background-image: url("../images/multi-alt.png"); }

.dash-class--alt .dash-class__meta:hover:before {
  background: orange; }

.project-link {
  border-bottom: 1px solid #d4d4d4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #00A5FF;
  display: flex;
  padding: 20px 20px 20px 5px;
  text-decoration: none;
  width: 100%; }
  .project-link__img-holder {
    margin-right: 10px;
    padding-top: 5px;
    width: 50px; }
    .project-link__img-holder--wb {
      margin-left: 5px;
      width: 40px; }
  .project-link__img {
    height: auto;
    width: 100%; }
  .project-link__main {
    flex: 1; }
  .project-link__title {
    font-family: "Gotham Rounded Bold";
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px; }
  .project-link__details, .project-link__teacher {
    align-items: center;
    color: #4a4a4a;
    display: flex;
    font-size: 14px; }
  .project-link__details {
    justify-content: space-between; }
  .project-link__teacher {
    text-align: right; }
  .project-link__teacher-name {
    display: block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .project-link__section {
    font-family: "Gotham Rounded Bold";
    margin-right: 10px; }
  .project-link__ava {
    border-radius: 100px;
    height: 20px;
    margin-right: 5px;
    width: auto; }
  .project-link--wb {
    background: #EFC210; }
    .project-link--wb .project-link__title {
      color: #4a4a4a; }
  .project-link:hover {
    background: #00A5FF; }
    .project-link:hover .project-link__title {
      color: #FFF; }
  .project-link--voting {
    color: #4E406E; }
    .project-link--voting:hover {
      background: #3D2D63; }
      .project-link--voting:hover .project-link__details, .project-link--voting:hover .project-link__teacher {
        color: #FFF; }

.action-link {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  text-decoration: none;
  width: 100%; }
  .action-link .action-link__inner {
    background: #FAFAFA;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 15px; }
  .action-link .button {
    display: flex;
    justify-content: center; }
    .action-link .button__image {
      margin: 0 5px; }
  .action-link__deadline {
    align-items: center;
    color: #E91D1D;
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin-bottom: 10px;
    text-transform: capitalize; }
  .action-link__deadline-img {
    height: 18px;
    margin-right: 5px;
    width: auto; }
  .action-link__status {
    align-content: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #f0b822;
    display: flex;
    font-family: "Gotham Rounded Bold";
    font-size: 14px;
    line-height: 18px;
    justify-content: center;
    padding: 15px 0; }
    .action-link__status--success {
      background: #D3F9D0;
      color: #23B232; }
    .action-link__status--fail {
      background: #FFE7E7;
      color: #e66558; }
    .action-link__status--complete {
      background: #F0FAFF;
      color: #00A5FF; }
    .action-link__status:hover {
      background: #FAFAFA; }
    .action-link__status--winner {
      background: #F38D00;
      color: #FFE733;
      font-size: 18px;
      text-shadow: 0 0 5px rgba(200, 0, 0, 0.5); }
      .action-link__status--winner:hover {
        background: #e48400; }
  .action-link .action-link__status-icon {
    height: 18px;
    margin: 0 15px;
    width: auto; }

@media screen and (min-width: 750px) {
  .dashboard {
    padding-top: 0;
    max-width: 1200px; } }

@media screen and (min-width: 1100px) {
  .dash-class {
    margin: 20px 0 0 0;
    float: left;
    width: calc(50% - 10px); }
  .dash-class:nth-child(odd) {
    margin-right: 20px; } }

@media screen and (min-width: 1240px) {
  .dash-class {
    margin: 40px 0 0 0;
    width: calc(50% - 20px); }
  .dash-class:nth-child(odd) {
    margin-right: 40px; } }

.error-page {
  align-items: center;
  background: #F3F3F3;
  bottom: 0;
  color: #4A4A4A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 10000; }
  .error-page__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px; }
  .error-page__title {
    color: #4E406E;
    font-family: "Gotham Rounded Bold";
    font-size: 72px; }
  .error-page__image {
    max-width: 210px; }
  .error-page__subtitle {
    margin: 10px 0 20px;
    font-size: 21px; }
  .error-page__caption {
    font-size: 18px;
    margin: 20px 0 20px; }
  .error-page__body {
    font-size: 14px;
    margin-bottom: 20px; }

@media screen and (max-width: 749px) {
  .user-view--profile {
    padding-top: 127px; }
    .user-view--profile .sub-menu {
      margin-bottom: 20px; } }

@media screen and (min-width: 1000px) {
  .profile__split-content {
    display: flex;
    margin: 0 -25px;
    max-width: 900px; }
  .profile__left-content, .profile__right-content {
    flex: 1;
    margin: 0 25px; }
  .profile__left-content {
    min-width: 300px; } }

.class-list__item {
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px; }

.class-list__name {
  display: block;
  font-family: "Gotham Rounded Bold";
  margin-bottom: 10px; }

.class-list__pending {
  color: #717171;
  font-family: "Gotham Rounded Book"; }

.class-list__teacher {
  padding-left: 25px;
  position: relative; }

.class-list__avatar {
  height: auto;
  left: 0;
  top: -4px;
  position: absolute;
  width: 20px; }

.project__mobile-header {
  background: #4E406E;
  display: flex;
  justify-content: flex-start;
  padding: 10px; }
  .project__mobile-header__title {
    color: #FFF;
    flex-grow: 0;
    font-family: "Gotham Rounded Bold";
    left: -34px;
    line-height: 38px;
    margin: auto;
    position: relative;
    text-align: center;
    width: calc(100% - 	160px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .project__mobile-header__button {
    z-index: 10; }

.project__content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 58px);
  min-height: 600px;
  padding: 20px; }

.boomer-heading--project {
  width: 100%; }

.writing-container {
  display: flex;
  flex: 1;
  padding: 50px 0 0 0;
  position: relative;
  max-height: 100%;
  width: 100%; }

.project-text {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%; }

/* test */
.project-entry {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
  overflow-y: scroll;
  font-size: 16px;
  line-height: 24px;
  padding: 20px;
  white-space: pre-line;
  overflow-y: hidden; }

.project-entry-editable {
  flex: 1;
  overflow-y: scroll;
  padding-left: 2px; }

.project-entry-editable:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa; }

/*.button--project-action {
	flex: 0;
}*/
.project-tabs {
  display: flex;
  height: 50px;
  position: absolute;
  left: 0;
  top: 1px;
  width: 100%; }
  .project-tabs--preview {
    display: none; }
  .project-tabs--hide-mob {
    display: none; }

.project-tab {
  background: #F9F9F9;
  border: 1px solid #D6D6D7;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  flex: 1;
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  white-space: nowrap; }
  .project-tab--instructions {
    background: #4E406E; }
  .project-tab--active {
    background: #FFF;
    border-bottom: none;
    color: #4E406E; }
    .project-tab--active--chat {
      background-color: #4E406E;
      color: #FFCE89; }
  .project-tab:focus {
    outline: none; }
  .project-tab.project-tab--default-revisions {
    color: red; }

.project-tab__info {
  display: none; }

.project-aside {
  flex: 1;
  max-height: 100%; }
  .project-aside--show {
    width: 100%; }

.dropdown--prev {
  margin: 0 20px;
  width: calc(100% - 40px); }

.project-side {
  display: none;
  background: #FFF;
  height: 100%;
  padding: 20px 0;
  width: 100%; }
  .project-side--show {
    display: flex;
    flex-direction: column; }
  .project-side--preview {
    background: #4E406E; }
  .project-side--feedback {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-y: scroll;
    padding: 20px; }

.project-side__prev-chap {
  display: none;
  flex: 1;
  line-height: 20px;
  overflow-y: scroll;
  padding: 0 20px; }
  .project-side__prev-chap--show {
    display: flex;
    flex-direction: column; }

.instructions-mob {
  flex-grow: 0; }

.instructions__content {
  flex: 1;
  overflow-y: auto; }

.instruction {
  display: flex;
  min-width: 0;
  padding: 0 20px; }
  .instruction--closed {
    background: #F8CDCD;
    color: #4A4A4A;
    margin: 0 20px;
    padding: 20px; }
    .instruction--closed .instruction__main,
    .instruction--closed .instruction__detail {
      color: #4A4A4A; }
  .instruction--review {
    padding: 0; }

.instruction .linkified {
  color: #FFCE89; }

.instruction__img {
  height: auto;
  width: 35px; }

.instruction__side {
  min-width: 50px;
  width: 50px; }
  .instruction__side--review {
    min-width: 40px;
    width: 40px; }

.instruction__main {
  color: #FFCE89;
  font-family: "Gotham Rounded Bold";
  flex: 1;
  min-width: 0; }

.instruction__detail {
  color: #FFF;
  font-size: 14px;
  font-family: "Gotham Rounded Book";
  line-height: 18px;
  white-space: pre-line; }

.project-next--mob {
  bottom: 20px;
  left: 20px;
  position: absolute;
  width: calc(100% - 40px); }

.instruction__vocab {
  background: #3D2D63;
  border-radius: 15px;
  color: #FFCE89;
  float: left;
  font-family: "Gotham Rounded Book";
  margin: 10px 5px 0 0;
  padding: 5px 8px;
  transition: all 0.5s ease; }
  .instruction__vocab--used {
    background: #FCC62D;
    color: #4E406E; }

.instruction__vocab-usage {
  clear: both;
  font-size: 14px;
  margin-top: 10px; }

.instruction__doc {
  color: #D8D8D8;
  display: block;
  background: #3D2D63;
  border-radius: 30px;
  font-size: 12px;
  font-family: "Gotham Rounded Book";
  margin-top: 10px;
  position: relative;
  text-decoration: none; }
  .instruction__doc:hover {
    background: #2c243e; }

.instruction__doc-name {
  display: block;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  width: calc(100% - 70px);
  white-space: nowrap; }

.instruction__doc-read {
  background: #00A5FF;
  bottom: 4px;
  border-radius: 15px;
  color: #FFF;
  font-size: 14px;
  font-family: "Gotham Rounded Bold";
  /*line-height: 24px;*/
  line-height: 30px;
  position: absolute;
  padding: 0 10px;
  right: 4px;
  top: 4px; }

.instructions__close {
  display: none; }

.project-crumbs, .project-crumbs__link {
  color: #828282;
  font-family: "Gotham Rounded Bold";
  text-decoration: none; }

.project-crumbs__link {
  margin: 0 5px; }
  .project-crumbs__link--current, .project-crumbs__link:hover {
    color: #333333; }
  .project-crumbs__link:first-child {
    margin-left: 0; }

.feedback {
  color: #4A4A4A; }
  .feedback__text {
    background: #D3F9D0;
    border-radius: 10px 10px 10px 0;
    line-height: 1.5;
    padding: 10px;
    margin-bottom: 5px; }
  .feedback__teacher {
    align-items: center;
    display: flex;
    margin-bottom: 30px; }
    .feedback__teacher-image {
      border: 1px solid #F2F2F2;
      border-radius: 15px;
      margin-right: 5px;
      max-height: 30px;
      max-width: 30px; }
  .feedback__revisions {
    background: white;
    margin-top: 30px; }
  .feedback__revision {
    margin-bottom: 20px; }
    .feedback__revision-bubble {
      background: #F2F2F2;
      border-radius: 10px 10px 10px 0;
      padding: 10px;
      margin-bottom: 5px; }
    .feedback__revision-date {
      color: #4E406E;
      font-size: 12px;
      margin-bottom: 15px; }
    .feedback__revision-teacher {
      margin-bottom: 0;
      margin-top: 5px; }
    .feedback__revision:last-child {
      margin-bottom: 0; }

.revision {
  color: #4A4A4A; }
  .revision__title {
    font-family: "Gotham Rounded Bold";
    margin-bottom: 10px; }
  .revision__bubble {
    background: #F2F2F2;
    border-radius: 10px 10px 10px 0;
    padding: 10px;
    margin-bottom: 10px; }
    .revision__bubble--pending {
      background: #FFF7CB; }
    .revision__bubble--alert {
      background: #FFC6C6; }
  .revision__date {
    color: #4E406E;
    font-size: 12px;
    margin-bottom: 15px; }
  .revision__text {
    font-style: italic;
    line-height: 1.5;
    margin-bottom: 10px; }
  .revision__teacher {
    align-items: center;
    display: flex;
    margin-bottom: 30px; }
    .revision__teacher-image {
      border: 1px solid #F2F2F2;
      border-radius: 15px;
      margin-right: 5px;
      max-height: 30px;
      max-width: 30px; }

.project-entry-header {
  color: #4A4A4A;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: normal;
  max-width: 300px;
  flex-direction: column;
  flex-wrap: wrap; }
  .project-entry-header.openBreakout {
    flex-direction: column;
    align-items: flex-end; }
    .project-entry-header.openBreakout .project-entry-header__left {
      width: 100% !important; }
      .project-entry-header.openBreakout .project-entry-header__left .scroll-arrows p {
        width: 93%;
        max-width: 70%; }
  .project-entry-header__left .scroll-arrows {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /*margin-left: 20px;*/
    height: 20px; }
    .project-entry-header__left .scroll-arrows img.arrow {
      cursor: pointer; }
      .project-entry-header__left .scroll-arrows img.arrow.disable {
        pointer-events: none;
        opacity: .2; }
      .project-entry-header__left .scroll-arrows img.arrow:first-of-type {
        margin-right: 20px; }
      .project-entry-header__left .scroll-arrows img.arrow:last-of-type {
        margin-left: 20px; }
    .project-entry-header__left .scroll-arrows p {
      margin: 0 5px;
      font-size: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%; }
  .project-entry-header__left .switch-buttons {
    margin: 15px 0 20px 0; }
    .project-entry-header__left .switch-buttons .my-entry {
      height: 30px;
      line-height: 30px;
      background: #8e00f0;
      color: white;
      text-align: center;
      border-radius: 8px;
      width: 100px;
      font-size: 15px; }
    .project-entry-header__left .switch-buttons .winning-entry-slider {
      width: 260px;
      height: 35px;
      display: flex;
      justify-content: space-between;
      background-color: #4e406e;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 4px; }
      .project-entry-header__left .switch-buttons .winning-entry-slider .button {
        border-radius: 10px;
        font-weight: 300;
        font-size: 15px;
        margin: 0 2px;
        padding: 0 15px;
        border: none;
        color: white;
        background-color: transparent;
        line-height: 30px; }
        .project-entry-header__left .switch-buttons .winning-entry-slider .button.active {
          background: #8e00f0;
          color: #FFFFFF; }
    .project-entry-header__left .switch-buttons .author-name {
      margin: 15px 0; }
      .project-entry-header__left .switch-buttons .author-name p {
        font-size: 13px; }
        .project-entry-header__left .switch-buttons .author-name p:last-of-type {
          font-size: 14px;
          color: #858585;
          margin-top: 5px; }
    .project-entry-header__left .switch-buttons .button {
      margin: 0 2px;
      padding: 0 15px; }
      .project-entry-header__left .switch-buttons .button.active {
        background: #00A5FF;
        color: #FFFFFF; }
  .project-entry-header__right {
    text-align: left;
    margin-top: -10px; }
    .project-entry-header__right .flexBtns {
      display: flex;
      align-items: center;
      width: 195px;
      justify-content: flex-end; }
  .project-entry-header__title {
    font-size: 14px;
    font-family: "Gotham Rounded Bold";
    margin-bottom: 10px; }
  .project-entry-header__author {
    font-size: 14px; }
  .project-entry-header__count {
    color: #4E406E;
    font-size: 14px; }
    .project-entry-header__count--alert {
      color: #E91D1D;
      font-family: "Gotham Rounded Bold"; }
    .project-entry-header__count__review {
      margin: 15px 0 8px 0; }
  .project-entry-header__voted {
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px; }
    .project-entry-header__voted.red {
      color: red; }
    .project-entry-header__voted img {
      height: 15px;
      margin-right: 5px;
      position: relative;
      bottom: 2px; }
  .project-entry-header .project-complete {
    font-size: 12px;
    width: 150px;
    height: 25px;
    line-height: 23px;
    text-align: center;
    background: #DAF7D3;
    color: #56AD46;
    border: 2px solid #56AD46;
    border-radius: 8px; }
  .project-entry-header__numbers {
    font-family: "Gotham Rounded Bold"; }
  .project-entry-header .winner-name {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 22px;
    line-height: 22px; }
    .project-entry-header .winner-name_chapter {
      font-family: "Gotham Rounded Bold"; }

@media (min-width: 700px) {
  .project-entry-header {
    max-width: initial;
    flex-direction: row; }
    .project-entry-header__left .scroll-arrows {
      margin-left: 20px; }
    .project-entry-header__left .switch-buttons {
      margin: 15px 0 20px 15px; }
    .project-entry-header__right {
      text-align: right; } }

.previous-chapter {
  color: #4A4A4A;
  margin-top: 20px; }
  .previous-chapter__title {
    font-size: 14px;
    font-family: "Gotham Rounded Bold"; }
  .previous-chapter__author {
    font-size: 14px;
    margin-bottom: 20px; }
  .previous-chapter__content {
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line; }

.instructions__close--voting {
  background: none;
  border: none;
  display: block;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px; }

.instructions__close-icon--voting {
  height: auto;
  width: 22px; }

.writing-closed {
  align-items: center;
  background: #F8CDCD;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 10px;
  text-align: center; }
  .writing-closed__image {
    height: 18px;
    margin-right: 5px; }

.tickle-notification {
  align-items: center;
  animation: slideInNotification 6s ease 0.5s forwards;
  background: #D3F9D0;
  border-radius: 10px;
  border: 1px solid #23B232;
  color: #23B232;
  display: flex;
  font-size: 15px;
  justify-content: center;
  padding: 8px;
  position: fixed;
  top: 10px;
  right: -500px;
  z-index: 400; }
  .tickle-notification__boomer-holder {
    height: 30px;
    margin-right: 5px;
    width: 30px; }

.open-rubric {
  background: #00A5FF;
  border: 0;
  border-radius: 10px;
  color: #FFF;
  font-family: "Gotham Rounded Book";
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  position: relative;
  width: 100%; }
  .open-rubric__name {
    display: block;
    font-family: "Gotham Rounded Bold";
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 32px); }
  .open-rubric__meta {
    right: 5px;
    top: 1px;
    position: absolute; }
  .open-rubric--centered {
    text-align: center; }
  .open-rubric:hover {
    background: #008bd6;
    cursor: pointer; }

.instruction__rubric-skill {
  background: #3D2D63;
  border-radius: 10px;
  color: #AAAAAA;
  font-family: "Gotham Rounded Book";
  font-size: 12px;
  line-height: 1;
  margin-bottom: 5px;
  padding: 5px 25px 5px 10px;
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.instruction__rubric-skill-score {
  background: #CCC;
  border-radius: 15px;
  color: #FFF;
  height: 22px;
  line-height: 22px;
  font-family: "Gotham Rounded Bold";
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 22px; }
  .instruction__rubric-skill-score--1 {
    background: #CB2723; }
  .instruction__rubric-skill-score--2 {
    background: #EFAB27; }
  .instruction__rubric-skill-score--3 {
    background: #7ED81C; }
  .instruction__rubric-skill-score--4 {
    background: #417801; }

.full-rubric {
  background: #FFF;
  color: #4a4a4a;
  height: 100%;
  left: 0;
  max-height: 100%;
  padding: 40px;
  position: absolute;
  overflow-y: scroll;
  top: 0;
  width: 100%;
  z-index: 500; }

.fr-skill {
  display: flex;
  width: 100%; }

.fr-skill:nth-child(even) {
  background: #f7f6f6; }

.fr-cell {
  flex: 1;
  padding: 40px 20px; }
  .fr-cell--scored {
    background: #00A5FF;
    color: #FFF; }

.mob-skill {
  border: 1px solid #e4e3e3;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.mob-criteria {
  padding: 30px 20px 30px 70px;
  position: relative; }
  .mob-criteria--scored {
    color: #00A5FF; }
  .mob-criteria:nth-child(even) {
    background: #f7f6f6; }
  .mob-criteria__score {
    background: #CCC;
    border-radius: 200px;
    color: #FFF;
    display: block;
    height: 40px;
    line-height: 40px;
    font-family: "Gotham Rounded Bold";
    font-size: 20px;
    left: 15px;
    position: absolute;
    text-align: center;
    top: calc(50% - 20px);
    width: 40px; }
    .mob-criteria__score--1 {
      background: #CB2723; }
    .mob-criteria__score--2 {
      background: #EFAB27; }
    .mob-criteria__score--3 {
      background: #7ED81C; }
    .mob-criteria__score--4 {
      background: #417801; }

.fr-heading {
  color: #FFF;
  padding: 10px;
  text-align: center; }
  .fr-heading--1 {
    background: #CB2723; }
  .fr-heading--2 {
    background: #EFAB27; }
  .fr-heading--3 {
    background: #7ED81C; }
  .fr-heading--4 {
    background: #417801; }

.fr-close {
  background: #E91D1D;
  border-radius: 100px;
  color: #FFF;
  cursor: pointer;
  font-family: "Gotham Rounded Bold";
  font-size: 18px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 35px;
  text-align: center;
  top: 30px;
  width: 30px; }
  .fr-close:hover {
    background: #d81515; }

.large-rubric {
  display: none; }

.mobile-rubric {
  display: block; }

@media screen and (min-width: 900px) {
  .large-rubric {
    display: block; }
  .mobile-rubric {
    display: none; } }

@media screen and (max-width: 750px) {
  .writing-container--preview {
    padding: 0; }
  .writing-container--preview, .writing-container--norel {
    position: initial; }
  .project-tab--instructions {
    background: #6F59A1;
    border: none;
    border-radius: 20px;
    color: #FFCE89;
    flex: 0;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 10px 5px 25px;
    position: absolute;
    right: 0;
    top: -95px; }
  .project-side--preview {
    bottom: 0;
    height: calc(100vh - 58px);
    left: 0;
    padding: 30px 20px 60px 20px;
    position: absolute;
    top: 58px;
    z-index: 51; }
  .project-side--instructions {
    height: 100vh;
    top: 0; }
  .instruction-boomers {
    display: block;
    height: auto;
    margin: 0 auto 20px auto;
    padding: 30px 20px 20px 20px;
    width: 110px; }
  .project-tab__info {
    display: block;
    left: -10px;
    position: absolute;
    height: auto;
    top: -2px;
    width: 30px; }
  .instructions__close {
    background: none;
    border: none;
    display: block;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px; }
  .instructions__close-icon {
    height: auto;
    width: 22px; } }

@media screen and (min-width: 750px) {
  .project {
    padding: 20px 20px 20px 140px; }
  .project__content {
    height: calc(100vh - 80px); }
  .project__content {
    padding: 0; }
  .instructions-mob {
    display: none; }
    .instructions-mob--voting {
      display: block; }
  .project__mobile-header {
    display: none; }
  .project-tab--instructions {
    border: none;
    background: #4E406E;
    color: #FFF;
    flex: 1;
    position: initial;
    right: initial;
    top: initial; }
    .project-tab--instructions.project-tab--default {
      color: #FFCE89; }
  .project-tabs--preview {
    display: flex; }
  .project-tabs--hide-mob {
    display: none; }
  .project-side--preview {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px; } }

@media screen and (max-width: 1000px) {
  .instruction-boomers--voting {
    display: block;
    height: auto;
    margin: 0 auto 20px auto;
    padding: 30px 20px 20px 20px;
    width: 150px; }
  .project-entry--edit {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .project-text--hide {
    display: none; } }

@media screen and (min-width: 1000px) {
  .project {
    padding: 40px 40px 40px 160px; }
  .boomer-heading--project {
    margin-bottom: 40px; }
  .writing-container {
    display: flex;
    max-width: 1200px;
    padding: 0 0 0 0; }
    .writing-container--generic-preview {
      display: flex;
      flex-direction: column;
      max-width: 900px; }
  .project__content {
    height: calc(100vh - 120px); }
  .project-text, .project-aside {
    flex: 1;
    padding: 0 20px 0 0; }
  .project-aside {
    padding: 50px 0 20px 20px;
    position: relative;
    flex: 0;
    min-width: 425px;
    max-width: 425px; }
    .project-aside--generic-preview {
      flex: 1;
      max-width: initial;
      min-width: initial;
      padding-left: 0; }
  .instructions__content {
    height: 100%; }
  .project-tabs {
    left: 20px; }
    .project-tabs--preview {
      display: flex; }
    .project-tabs--generic-preview {
      left: 0; }
  .project-tab {
    border: 1px solid #4E406E;
    flex: 0; }
    .project-tab--entry {
      display: none; }
    .project-tab--default {
      color: #00A5FF; }
    .project-tab--active, .project-tab--default {
      background: #FFF;
      border-bottom: 1px solid #FFF; }
    .project-tab--instructions {
      background: #4E406E;
      border-bottom: 1px solid #4E406E; }
  .project-side {
    border: 1px solid #4E406E;
    height: calc(100% - 50px);
    border-top-left-radius: 0;
    border-top-right-radius: 15px; }
    .project-side--generic-preview {
      height: 100%; }
  .project-side--default {
    display: block; }
  .project-next--mob, .instructions__close--voting, .instructions-mob--voting {
    display: none; }
  .project-side--preview {
    background: #4E406E; }
  .instruction--hidden-desktop {
    display: none; } }

@-webkit-keyframes slideInNotification {
  0% {
    right: -500px; }
  10% {
    right: 10px; }
  90% {
    right: 10px; }
  100% {
    right: -500px; } }

@keyframes slideInNotification {
  0% {
    right: -500px; }
  10% {
    right: 10px; }
  90% {
    right: 10px; }
  100% {
    right: -500px; } }

.portfolio-project {
  margin-bottom: 20px;
  width: 100%; }
  .portfolio-project:last-child {
    margin-bottom: 0; }
  .portfolio-project__inner {
    color: #000;
    display: flex;
    font-family: "Gotham Rounded Bold";
    font-size: 18px;
    line-height: 24px;
    padding: 20px;
    text-decoration: none; }
  .portfolio-project__info {
    flex: 1; }
  .portfolio-project__image {
    width: 90px;
    height: auto; }
  .portfolio-project__cover {
    height: 135px;
    margin-right: 20px;
    position: relative;
    width: 90px; }
  .portfolio-project__detail {
    border-top: 1px solid #D4D4D4;
    color: #4a4a4a;
    font-size: 14px;
    font-family: "Gotham Rounded Book";
    padding: 4px 0;
    position: relative; }
  .portfolio-project__teacher {
    align-items: center;
    display: flex;
    font-size: 14px; }
  .portfolio-project__teacher-img {
    height: 16px;
    margin-right: 5px;
    width: auto; }
  .portfolio-project__status {
    font-family: "Gotham Rounded Bold"; }
    .portfolio-project__status--complete {
      color: #23B232;
      display: flex;
      align-items: center; }
    .portfolio-project__status--progress {
      color: #FB8C2B; }
  .portfolio-project__check {
    height: initial;
    margin-right: 5px;
    width: 16px; }
  .portfolio-project__actions {
    column-count: 2;
    column-gap: 10px; }

.report-read__flags {
  width: calc(100% - 120px); }

.report-read__status {
  align-items: center;
  display: flex;
  float: left;
  font-family: "Gotham Rounded Bold";
  font-size: 14px;
  margin: 0 20px 10px 0; }

.report-read__status-img {
  height: 20px;
  margin-right: 5px;
  width: auto; }

.portfolio-read-side {
  margin-bottom: 20px; }

.portfolio-side__teacher {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-top: 10px; }

.portfolio-side__ava {
  height: 16px;
  margin-right: 7px;
  width: auto; }

.portfolio-read-chapter {
  padding: 20px; }
  .portfolio-read-chapter .unit-review-scroll-arrows {
    margin-left: 5px;
    margin-bottom: 15px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .portfolio-read-chapter .unit-review-scroll-arrows img.arrow {
      cursor: pointer; }
      .portfolio-read-chapter .unit-review-scroll-arrows img.arrow.disable {
        pointer-events: none;
        opacity: .2; }
      .portfolio-read-chapter .unit-review-scroll-arrows img.arrow:first-of-type {
        margin-right: 20px; }
      .portfolio-read-chapter .unit-review-scroll-arrows img.arrow:last-of-type {
        margin-left: 20px; }
    .portfolio-read-chapter .unit-review-scroll-arrows p {
      margin: 0 5px;
      font-size: 22px; }
  .portfolio-read-chapter__title-area {
    margin-bottom: 20px; }
  .portfolio-read-chapter__title {
    margin-bottom: 10px; }
  .portfolio-read-chapter__entry-area {
    background: white;
    border: 1px solid #D6D6D6;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 20px 30px; }
  .portfolio-read-chapter__buttons {
    position: relative;
    width: 100%; }
    .portfolio-read-chapter__buttons p.unit-review-word-count {
      position: absolute;
      top: 10px;
      right: 20px; }
  .portfolio-read-chapter__switch-buttons {
    display: flex;
    margin: 0 -2px 20px;
    height: 70px; }
    .portfolio-read-chapter__switch-buttons .winning-entry-slider {
      width: 260px;
      display: flex;
      justify-content: space-between;
      background-color: #4e406e;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 4px; }
      .portfolio-read-chapter__switch-buttons .winning-entry-slider .button {
        border-radius: 10px;
        font-weight: 300;
        font-size: 15px;
        margin: 0 2px;
        padding: 0 15px;
        border: none;
        color: white;
        background-color: transparent; }
        .portfolio-read-chapter__switch-buttons .winning-entry-slider .button.active {
          background: #8e00f0;
          color: #FFFFFF; }
    .portfolio-read-chapter__switch-buttons .button {
      margin: 0 2px;
      padding: 0 15px; }
      .portfolio-read-chapter__switch-buttons .button.active {
        background: #00A5FF;
        color: #FFFFFF; }
  .portfolio-read-chapter__winning-entry {
    align-items: center;
    background: #FFF;
    border-radius: 12px;
    color: white;
    display: inline-flex;
    font-family: "Gotham Rounded Bold";
    font-size: 16px;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 20px;
    background: #065fe5;
    background: linear-gradient(180deg, #065fe5 19%, #8e00f0 87%); }
  .portfolio-read-chapter__star {
    height: 18px;
    margin-right: 10px;
    width: auto; }
  .portfolio-read-chapter__entry-holder {
    background: #FCFCFC;
    font-size: 14px;
    padding: 20px; }
  .portfolio-read-chapter__title {
    font-family: "Gotham Rounded Bold";
    margin-bottom: 5px; }
  .portfolio-read-chapter__author {
    color: #7d7d7d;
    margin-bottom: 20px; }
  .portfolio-read-chapter__entry-text {
    line-height: 1.8em;
    white-space: pre-line; }
    .portfolio-read-chapter__entry-text p {
      margin-bottom: 20px; }
  .portfolio-read-chapter__warning {
    align-items: center;
    color: #E91D1D;
    display: flex;
    font-family: "Gotham Rounded Bold"; }
  .portfolio-read-chapter__warning-img {
    height: 14px;
    margin-right: 5px; }
  .portfolio-read-chapter__feedback-holder {
    background: #FCFCFC;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;
    padding: 20px; }
  .portfolio-read-chapter__current-feedback {
    font-style: italic;
    line-height: 1.8em; }
  .portfolio-read-chapter__teacher {
    font-family: "Gotham Rounded Bold"; }
  .portfolio-read-chapter__old-feedback-holder--bordered {
    padding-top: 10px;
    border-top: 1px solid #e3e3e3;
    margin-top: 10px; }
  .portfolio-read-chapter__old-feedback {
    font-style: italic; }
  .portfolio-read-chapter__date {
    color: #7d7d7d;
    font-family: "Gotham Rounded Bold"; }

.portfolio-aside {
  margin-bottom: 20px;
  max-width: 800px;
  padding: 10px;
  position: relative; }
  .portfolio-aside__header {
    display: flex;
    align-items: center;
    padding: 10px 0; }
  .portfolio-aside__image {
    height: 100%;
    margin-right: 20px;
    width: 80px; }
  .portfolio-aside__teacher {
    align-items: center;
    display: flex;
    margin-bottom: 10px; }
  .portfolio-aside__code {
    margin-bottom: 10px; }
  .portfolio-aside__ava {
    height: auto;
    margin-right: 5px;
    width: 20px; }
  .portfolio-aside__button {
    width: 100%; }
  .portfolio-aside__title {
    display: none; }

.portfolio-main {
  max-width: 800px; }

.portfolio-side {
  padding: 20px; }

.report-instructions {
  background: #4E406E;
  bottom: 0;
  color: #FFF;
  font-size: 14px;
  left: 0;
  line-height: 18px;
  overflow-y: scroll;
  padding: 80px 40px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5000; }
  .report-instructions--hidden {
    display: none; }

.report-instructions__heading {
  color: #FFCE89;
  font-family: "Gotham Rounded Bold";
  margin-bottom: 5px; }

.report-instructions__img {
  float: left;
  height: auto;
  width: 30px; }

.report-instructions__body {
  float: left;
  margin-left: 10px;
  width: calc(100% - 40px); }

.report-instructions__inner {
  margin: 0 auto;
  max-width: 550px; }

.report-instructions__close {
  background: none;
  border: none;
  display: block;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px; }

.report-instructions__close-icon {
  height: auto;
  width: 22px; }

.portfolio-read-chapter__entry-holder {
  position: relative; }

.portfolio__instructions-btn {
  background: #6F59A1;
  border: none;
  border-radius: 20px;
  color: #FFCE89;
  font-family: "Gotham Rounded Bold";
  font-size: 12px;
  line-height: 16px;
  padding: 5px 10px 5px 25px;
  position: absolute;
  right: 15px;
  top: 15px; }

.portfolio__info {
  display: block;
  left: -10px;
  position: absolute;
  height: auto;
  top: -2px;
  width: 30px; }

@media screen and (min-width: 500px) {
  .portfolio-project__image {
    width: 100px; }
  .portfolio-project__cover {
    margin-right: 20px;
    height: auto;
    position: relative;
    width: 110px; } }

@media screen and (min-width: 750px) {
  .portfolio-read-chapter {
    padding: 0; }
    .portfolio-read-chapter__title-area {
      align-items: center;
      display: flex;
      justify-content: space-between; }
    .portfolio-read-chapter__title {
      margin: 0; }
    .portfolio-read-chapter__dropdown-container {
      border: none;
      padding: 0; }
  .portfolio-aside__button {
    margin-top: -19px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: initial; } }

@media screen and (min-width: 1000px) {
  .report-instructions__close, .portfolio__instructions-btn {
    display: none; }
  .portfolio-read-chapter__layout {
    display: flex; }
  .portfolio-read-chapter__content {
    flex: 1; }
  .portfolio-read-side {
    margin-left: 20px;
    order: 2;
    width: 300px; }
  .report-instructions {
    border-radius: 15px;
    margin-top: 20px;
    overflow: initial;
    padding: 20px;
    position: initial; }
    .report-instructions--hidden {
      display: block; } }

@media screen and (min-width: 1200px) {
  .portfolio-main {
    max-width: 950px; }
  .portfolio-read-side {
    width: 350px; }
  .portfolio-aside {
    background: none;
    border: none;
    float: right;
    padding: 0;
    position: initial;
    width: 210px; }
    .portfolio-aside__header {
      background: #FFF;
      border-radius: 12px;
      display: block;
      padding: 20px; }
    .portfolio-aside__image {
      margin: 0 0 20px 0;
      width: 100%; }
    .portfolio-aside__button {
      margin-top: 10px;
      position: initial;
      width: 100%; }
    .portfolio-aside__teacher, .portfolio-aside__code {
      border-top: 1px solid #D4D4D4;
      margin-top: 10px;
      padding-top: 10px; }
    .portfolio-aside__title {
      display: block;
      font-family: "Gotham Rounded Bold"; } }

.portfolio__filter {
  background: #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  float: left;
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  margin-right: 10px;
  padding: 10px; }
  .portfolio__filter--active {
    background: #00A5FF;
    color: #FFF; }

.boomer-holder .imageloader {
  height: 100%; }

.boomer-holder__loader {
  animation: boomer-loader-pulse 0.9s infinite;
  max-height: 100%;
  max-width: 100%; }

.boomer-holder__hidden-canvas {
  display: none; }

.boomer-holder__image {
  max-height: 100%;
  max-width: 100%; }

.boomer-holder--in-confirm {
  height: 100%;
  padding: 10px;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  width: 70px; }
  .boomer-holder--in-confirm .boomer-holder__image,
  .boomer-holder--in-confirm .boomer-holder__loader {
    height: 96px;
    left: -28px;
    max-height: none;
    max-width: none;
    position: relative;
    top: -23px; }

.boomer-holder--dashboard .boomer-holder__image,
.boomer-holder--dashboard .boomer-holder__loader {
  position: relative;
  max-height: none;
  max-width: none; }

.boomer-holder--in-heading {
  height: 100%;
  padding: 10px;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  width: 61px; }
  .boomer-holder--in-heading .boomer-holder__image,
  .boomer-holder--in-heading .boomer-holder__loader {
    height: 87px;
    left: -28px;
    max-height: none;
    max-width: none;
    position: relative;
    top: -23px; }
  .boomer-holder--in-heading-with-rank {
    height: 100%;
    width: 61px; }
    .boomer-holder--in-heading-with-rank .boomer-holder__image,
    .boomer-holder--in-heading-with-rank .boomer-holder__loader {
      height: 87px;
      left: -14px;
      max-height: none;
      max-width: none;
      position: relative;
      top: -12px; }

.boomer-holder--in-main-menu {
  height: 100%;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  border-radius: 35px;
  width: 35px; }
  .boomer-holder--in-main-menu .boomer-holder__image,
  .boomer-holder--in-main-menu .boomer-holder__loader {
    position: relative;
    height: 68px;
    left: -16px;
    max-height: none;
    max-width: none;
    top: -12px; }

.boomer-holder--in-panel {
  height: 100%;
  padding: 1px;
  overflow: hidden;
  text-align: center;
  width: 100%; }

.badge-info {
  background-image: linear-gradient(-180deg, #F0973F 0%, #E24800 100%);
  box-shadow: 0px 0px 15px 10px rgba(233, 7, 255, 0.5);
  border: 1px solid #FFA257;
  border-radius: 15px;
  min-height: 80px;
  padding: 10px 10px 10px 80px;
  position: absolute;
  left: 0;
  top: 80px;
  width: 100%;
  z-index: 201; }
  .badge-info--bp {
    top: 240px; }
  .badge-info--notification {
    animation: slideIn 0.5s ease 0.5s forwards;
    bottom: 20px;
    cursor: pointer;
    left: initial;
    right: -500px;
    top: initial;
    width: calc(100% - 40px);
    z-index: 5000; }
  .badge-info--store {
    left: 20px;
    top: 200px;
    width: calc(100% - 40px); }
  .badge-info__badge {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 100px;
    left: -10px;
    position: absolute;
    top: -10px;
    width: 100px; }
  .badge-info__coins {
    height: 70px;
    width: auto;
    position: absolute;
    top: -10px;
    left: 20px; }
  .badge-info__close {
    align-content: center;
    background: #FFF;
    border-radius: 200px;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    position: absolute;
    right: -18px;
    top: -18px;
    width: 36px; }
  .badge-info__close-img {
    height: 16px;
    margin-top: 10px;
    width: auto; }
  .badge-info__desc {
    color: #FFF;
    margin-top: 20px; }
  .badge-info__inner {
    margin-top: -22px;
    text-align: center; }
  .badge-info__title {
    color: #7C0096;
    font-size: 24px;
    text-shadow: 2px 2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, -2px -2px 0 #FFF, 2px 0px 0 #FFF, 0px 2px 0 #FFF, -2px 0px 0 #FFF, 0px -2px 0 #FFF; }

@media screen and (min-width: 750px) {
  .boomer-holder--in-main-menu {
    border-radius: 40px;
    width: 40px; }
    .boomer-holder--in-main-menu .boomer-holder__image,
    .boomer-holder--in-main-menu .boomer-holder__loader {
      height: 73px;
      left: -16px; }
  .badge-info {
    max-width: 500px; } }

@media screen and (min-height: 700px) and (min-width: 750px) {
  .boomer-holder--in-main-menu {
    border-radius: 45px;
    width: 45px; }
    .boomer-holder--in-main-menu .boomer-holder__image,
    .boomer-holder--in-main-menu .boomer-holder__loader {
      height: 80px;
      left: -17px; } }

@keyframes boomer-loader-pulse {
  0% {
    opacity: 1; }
  20% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@-webkit-keyframes slideIn {
  0% {
    right: -500px; }
  90% {
    right: 30px; }
  100% {
    right: 20px; } }

@keyframes slideIn {
  0% {
    right: -500px; }
  90% {
    right: 30px; }
  100% {
    right: 20px; } }

.showContent {
  display: block; }

.hideContent {
  display: none; }

@media screen and (max-width: 750px) {
  .project-home {
    padding: 20px; } }

.project-home {
  max-width: 1200px; }

.report {
  background: #fff;
  border-radius: 15px;
  padding: 1px 10px;
  width: 100%; }

.report-side {
  margin-bottom: 20px;
  overflow: hidden; }
  .report-side__top {
    padding: 20px; }
    .report-side__top--wb {
      background: #efc210; }
  .report-side__title {
    color: #00A5FF;
    font-family: "Gotham Rounded Bold";
    font-size: 16px; }
    .report-side__title--wb {
      color: #4a4a4a;
      padding-left: 40px;
      position: relative; }
    .report-side__title__light {
      font-family: "Gotham Rounded Book";
      font-size: 14px; }
  .report-side__code {
    color: #23B232;
    font-family: "Gotham Rounded Bold";
    font-size: 14px;
    margin-top: 10px; }
  .report-side__label {
    color: #000; }
  .report-side__wb-icon {
    height: auto;
    left: 0px;
    position: absolute;
    width: 30px; }
  .report-side__wb-showmore {
    font-size: 14px;
    padding-left: 40px; }

.project-meta {
  border-top: 1px solid #ccc;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 10px; }
  .project-meta__group {
    border-right: 1px solid #ccc;
    font-family: "Gotham Rounded Bold";
    margin-right: 10px;
    padding-right: 10px; }

.report-progress {
  background: #edf7ff;
  padding: 20px;
  position: relative; }

.report-callout {
  display: block;
  text-decoration: none; }

.report-button__para {
  flex: 1;
  font-size: 14px;
  text-align: right; }

.p-callout {
  background-color: #fff5e7;
  background-image: url("../images/chev-yellow.png");
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 30px auto;
  border: 2px solid #FCC62D;
  border-radius: 15px;
  padding: 20px; }
  .p-callout:hover {
    background-color: #ffefd8; }
  .p-callout--success {
    background-color: #d3f9d0;
    background-image: url("../images/chev-green.png");
    border: 2px solid #23b232; }
    .p-callout--success:hover {
      background-color: #bef6b9; }
  .p-callout--reject {
    background-color: #ffe7e7;
    background-image: url("../images/chev-red.png");
    border: 2px solid #ff8481; }
    .p-callout--reject:hover {
      background-color: #ffcece; }

.report__unit {
  background: #f0f8ff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  display: block;
  margin: 10px 0;
  overflow: hidden;
  width: 100%; }
  .report__unit--current {
    border: 2px solid #fcc62d; }

.report__heading {
  display: flex; }

.report__heading-link {
  align-items: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  color: #00A5FF;
  display: flex;
  flex: 1;
  font-family: "Gotham Rounded Bold";
  line-height: 22px;
  padding: 15px;
  text-decoration: none; }
  .report__heading-link .button {
    width: 60%; }
  .report__heading-link .report__heading-title {
    width: 40%; }
  .report__heading-link:hover {
    background: #e1f1ff; }

.report__heading-winner {
  color: #afafaf;
  font-family: "Gotham Rounded Book";
  margin-left: 10px; }

.report__heading-toggle {
  background: #00A5FF;
  border: none;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  width: 40px; }
  .report__heading-toggle:hover, .report__heading-toggle:focus {
    background: #0098eb;
    outline: none; }

.report__heading-alert {
  background: #ff7166;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  margin-left: 10px;
  padding: 4px 10px; }

.report__chevron {
  height: auto;
  transform: rotate(180deg);
  transition: transform 0.2s;
  width: 20px; }
  .report__chevron--up {
    transform: rotate(0deg); }

.report__body {
  padding: 5px 15px 10px 15px;
  width: 100%; }

.report__body-winner {
  border-bottom: 1px solid #c7eafd;
  color: #5f5f5f;
  font-size: 14px;
  margin-bottom: 5px;
  overflow: hidden;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap; }

.report__entry {
  background: #fff;
  border-radius: 8px;
  display: flex;
  padding: 10px;
  width: 100%; }

.report__entry-success {
  align-items: center;
  color: #23B232;
  display: flex;
  font-family: "Gotham Rounded Bold";
  font-size: 14px;
  margin-left: 20px; }

.report__entry-tick {
  height: 18px;
  margin-right: 5px;
  width: auto; }

.report__draft {
  background: #eeeeee;
  border-radius: 8px;
  color: #c5bebe;
  display: flex;
  padding: 15px; }
  .report__draft .report__draft-title {
    font-family: "Gotham Rounded Bold";
    margin-right: 10px; }

.report-earnings {
  padding: 20px; }

.earnings-breakdown {
  font-size: 14px;
  margin-bottom: 20px;
  position: relative; }

.earnings-breakdown__amount {
  align-content: center;
  display: flex;
  position: absolute;
  right: 0;
  top: 0; }
  .earnings-breakdown__amount--total {
    font-family: "Gotham Rounded Bold";
    font-size: 16px; }

.earnings-breakdown__img {
  height: 14px;
  margin-right: 3px;
  width: auto; }
  .earnings-breakdown__img--total {
    height: 16px; }

.report-earnings__title {
  font-family: "Gotham Rounded Bold"; }

.report-earnings__expand {
  color: #949494;
  cursor: pointer;
  font-family: "Gotham Rounded Book";
  font-size: 14px;
  margin-left: 10px; }

.recent-tickles {
  background: #fff;
  border-radius: 7px;
  display: flex;
  margin-top: 10px;
  padding: 10px; }
  .recent-tickles--outer-space {
    margin: 10px 15px; }
  .recent-tickles__label {
    flex: 0;
    padding-right: 20px; }
  .recent-tickles__feed {
    flex: 1; }
  .recent-tickles__tickles {
    display: flex; }
    .recent-tickles__tickles--completed {
      display: block; }
  .recent-tickles .tickle__content {
    color: #00A5FF; }
  .recent-tickles .tickle__body {
    flex: 1; }
  .recent-tickles .tickle__author {
    font-size: 16px;
    font-family: "Gotham Rounded Bold"; }
  .recent-tickles .tickle__like-button {
    align-self: flex-start; }
  .recent-tickles__avatars {
    display: flex;
    padding: 0 20px 0 10px;
    margin-top: 10px; }
  .recent-tickles__view-more {
    border: none;
    font-size: 12px;
    line-height: 28px;
    padding: 0 20px; }
  .recent-tickles__other-count {
    text-decoration: underline; }

.no-activity {
  background: #fff5e7;
  border: 1px solid #fcc62d;
  border-radius: 8px;
  color: #fcc62d;
  font-family: "Gotham Rounded Bold";
  margin: 10px;
  padding: 10px;
  text-align: center; }

.recent-tickles__time-stamp,
.tickle__time-stamp {
  color: #a2a2a2;
  font-size: 14px;
  margin-top: 5px; }

.tickles-list {
  width: 100%; }

.tickle {
  display: flex; }
  .tickle__avatar {
    margin-right: 10px; }

.recent-ava {
  background: #fff;
  border: 2px solid #4e406e;
  border-radius: 500px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  height: 40px;
  margin-left: -10px;
  position: relative;
  width: 40px; }
  .recent-ava--rank {
    border: 2px solid rgba(0, 0, 0, 0); }
  .recent-ava--orange {
    border: 2px solid #fca502; }
  .recent-ava--writingClub {
    border: 2px solid #7015D0; }
  .recent-ava .boomer-holder--recent-ava {
    border-radius: 500px;
    height: 34px;
    margin: 1px;
    overflow: hidden;
    width: 34px; }
  .recent-ava .boomer-holder__image {
    height: auto;
    left: 50%;
    margin-left: -32px;
    max-height: initial;
    max-width: initial;
    position: relative;
    top: -12px;
    width: 64px; }
  .recent-ava .boomer-holder__badge {
    margin-left: -13px;
    top: 29px;
    width: 26px; }
  .recent-ava__rank {
    height: auto;
    left: 50%;
    margin-left: -24px;
    position: absolute;
    top: 0;
    width: 48px; }

.tickle__like-button {
  align-items: center;
  background: white;
  border: 1px solid #00A5FF;
  border-radius: 8px;
  box-shadow: none;
  color: #00A5FF;
  display: flex;
  font-family: "Gotham Rounded Bold";
  font-size: 15px;
  justify-content: center;
  line-height: 25px;
  min-width: 55px; }
  .tickle__like-button__count, .tickle__like-button__img {
    margin: 0 2px; }
  .tickle__like-button:hover {
    background: #f0f8ff; }

@media screen and (max-width: 1000px) {
  .earnings-breakdown--hidden {
    display: none; } }

@media screen and (min-width: 1000px) {
  .report-earnings__expand {
    display: none; }
  .project-home__main {
    display: flex;
    min-width: 0; }
  .report-container {
    flex: 1;
    flex-grow: 1;
    min-width: 0; }
  .report-side-container {
    flex: 1;
    order: 2;
    margin-left: 20px;
    max-width: 400px;
    min-width: 0; } }

@media (max-width: 420px) {
  .recent-tickles__avatars {
    display: none; } }

.no-content {
  padding: 40px;
  text-align: center; }
  .no-content__img {
    height: auto;
    margin-bottom: 20px;
    width: 180px; }

/*@import 'v1/scrollbar.scss';*/
.store {
  background: #FFF;
  position: relative; }

.store__preview {
  height: 220px;
  position: relative; }

.store__mob-header {
  background: #4E406E;
  display: flex;
  padding: 10px; }

.store__header-text {
  color: #FFF;
  flex-grow: 1;
  font-family: "Gotham Rounded Bold";
  line-height: 38px;
  text-align: center; }

.store__header-coins {
  height: auto;
  margin-right: 5px;
  width: 20px; }

.store__header-bucks {
  align-items: center;
  color: #FFCE89;
  display: flex;
  font-family: "Gotham Rounded Bold";
  justify-content: center;
  width: 69px; }

.store__controls {
  background: #ececec;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-flow: column;
  height: calc(100vh - 280px);
  overflow: hidden;
  padding-bottom: 5px; }

.store__wallet {
  display: none; }

.store__tabs {
  background: #00A5FF;
  display: flex; }

.store__tab {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #FFF;
  cursor: pointer;
  flex: 1;
  font-family: "Gotham Rounded Bold";
  padding: 15px;
  text-align: center; }
  .store__tab:hover {
    background: #33b7ff; }
    .store__tabs {
      background: #33b7ff; }
  .store__tab--active, .store__tab--active:hover {
    background: #ececec;
    color: #000; }

.store__categories {
  display: flex; }

.store__category-tab {
  align-items: center;
  background: #F1F1F1;
  border: 2px solid #E4E4E4;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 55px;
  justify-content: center;
  margin: 10px 5px 0 5px; }
  .store__category-tab--active {
    background: #FFF;
    border: 2px solid #00A5FF; }
  .store__category-tab:hover {
    background: #FFF; }

.store__category-boomer {
  height: auto;
  width: 22px; }

.store__category-img {
  height: auto;
  width: 30px; }

.store__items {
  display: flex;
  flex: 1;
  padding-top: 10px;
  position: relative;
  width: 100%; }

.store__items-list {
  background: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 10px;
  height: 100%;
  margin: 0 5px 10px 5px;
  overflow-y: scroll;
  padding: 5px;
  width: 100%; }
  .store__items-list--padded {
    padding: 5px 5px 60px 5px; }

.store__actions {
  background: #FFF;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 1px;
  display: flex;
  left: 6px;
  padding-bottom: 5px;
  position: absolute;
  right: 6px;
  z-index: 200; }

.store__actions .button {
  flex: 1;
  margin: 0 5px; }

.store__item {
  background: #F1F1F1;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  cursor: pointer;
  float: left;
  margin: 5px;
  overflow: hidden;
  padding: 10px 5px;
  position: relative;
  width: calc( 25% - 10px); }
  .store__item:hover {
    background: #FFF;
    border: 1px solid #F0F0F0; }
  .store__item--wearing, .store__item--wearing:hover {
    background: #FCC62D; }
  .store__item--owned {
    padding: 5px 5px 15px 5px; }

.store__no-item {
  color: #4a4a4a;
  font-family: "Gotham Rounded Bold";
  padding: 5px;
  text-align: center; }

.store__item-price {
  align-items: center;
  background: #00A5FF;
  border-radius: 10px;
  color: #FFF;
  display: flex;
  font-family: "Gotham Rounded Bold";
  height: 40px;
  justify-content: center;
  margin: 5px;
  font-size: 16px;
  text-align: center;
  width: calc(100% - 10px); }

.store__item-desc {
  text-transform: capitalize; }

.store__item-coins {
  height: 18px;
  margin: 0 2px 0 10px;
  width: auto; }

.store__item-owned {
  background: #00A5FF;
  bottom: 0;
  color: #FFF;
  font-size: 12px;
  font-family: "Gotham Rounded Bold";
  left: 0;
  padding: 2px 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

.store__item-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 50px;
  width: 100%; }

.store__item-img--remove {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase; }

.store__spotlight {
  height: auto;
  left: 5px;
  position: absolute;
  top: 0;
  width: 60px; }
  .store__spotlight--right {
    left: initial;
    right: 5px; }

.store__preview .boomer-holder {
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative; }

.boomer-holder__badge {
  position: absolute;
  top: 63%;
  left: 50%;
  height: auto;
  margin-left: -26px;
  width: 52px; }

.store__purchase-overlay-items {
  border: none;
  border-top: 1px solid #F2F2F2;
  border-radius: 0;
  margin: 20px -5px 0;
  padding: 15px 0 0; }

.store__purchase-overlay-coins {
  height: 18px;
  margin-right: 2px;
  position: relative;
  top: 2px; }

.store__purchase-overlay .store__item {
  cursor: default; }
  .store__purchase-overlay .store__item:hover {
    background: #F1F1F1;
    border: 1px solid #F1F1F1; }

.store__badge {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  color: #005F62;
  cursor: pointer;
  display: flex;
  font-family: "Gotham Rounded Bold";
  margin-bottom: 10px;
  padding: 10px 10px 0 0; }
  .store__badge:hover {
    background: #FFF; }
  .store__badge--disabled {
    cursor: default; }
    .store__badge--disabled:hover {
      background: #F5F5F5; }
  .store__badge--active {
    background: #00A5FF;
    color: #FFF; }
    .store__badge--active:hover {
      background: #00A5FF; }

.badge__img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 80px;
  margin-right: 10px;
  width: 80px; }

.badge__equipped {
  color: #F5A623;
  font-size: 14px; }

.badge__quali {
  color: #FF6346;
  font-size: 12px;
  font-family: "Gotham Rounded Book";
  margin-top: 5px;
  position: relative; }

.badge__lock {
  position: absolute;
  left: -17px;
  top: -6px; }

.preview-badge {
  cursor: pointer;
  left: 20px;
  position: absolute;
  top: 140px;
  width: 70px; }
  .preview-badge--rank {
    left: initial;
    right: 20px; }
  .preview-badge__badge {
    height: auto;
    width: 100%; }

@media screen and (min-width: 750px) {
  .store {
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    height: calc(100vh - 80px);
    overflow: hidden; }
  .store__controls {
    box-shadow: none;
    height: calc(100% - 276px);
    padding-bottom: 10px; }
  .store__mob-button {
    display: none; }
  .store__header-text {
    padding-left: 69px; } }

@media screen and (min-width: 1000px) {
  .store {
    background: #ececec;
    border: none;
    border-radius: 15px;
    box-shadow: none;
    display: flex;
    height: calc(100vh - 100px);
    max-width: 800px;
    padding: 10px; }
  .store__mob-header {
    display: none; }
  .store__header {
    background: #00A5FF;
    color: #FFF;
    font-size: 20px;
    line-height: 50px;
    font-family: "Gotham Rounded Bold";
    text-align: center; }
  .store__preview {
    background: none;
    border-radius: 15px;
    height: 100%;
    flex: 1;
    overflow: hidden; }
  .store__preview .boomer-holder {
    background: #FFF;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    height: auto;
    padding: 50px 10px 50px 10px; }
  .preview-badge {
    left: 5px;
    top: 290px;
    width: 50px; }
    .preview-badge--rank {
      left: initial;
      right: 5px; }
  .store__preview .boomer-holder__image, .store__preview .boomer-holder__loader {
    height: auto;
    width: 220px; }
  .boomer-holder__badge {
    top: 58.1%; }
  .store__controls {
    background: none;
    border-radius: 15px;
    height: initial;
    margin-left: 10px;
    padding-bottom: 0;
    width: 380px; }
  .store__tabs, .store__categories, .store__items {
    background: #F5f5f5; }
  .store__tabs {
    background: #00A5FF;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden; }
  .store__tab--active, .store__tab--active:hover {
    background: #F5F5F5; }
  .store__items-list {
    margin: 0; }
  .store__items {
    padding: 10px 5px 5px 5px; }
  .store__spotlight {
    top: 50px;
    width: 50px;
    z-index: 200; }
  .store__coins {
    height: auto;
    margin: 0 5px 0 10px;
    width: 24px; }
  .store__bucks {
    color: #FFF;
    font-size: 24px;
    margin: 0 10px 0 0;
    padding-top: 1px; }
  .store__wallet {
    align-items: center;
    background: #4E406E;
    border-radius: 15px;
    color: #D4D4D4;
    display: flex;
    font-size: 16px;
    font-family: "Gotham Rounded Bold";
    height: 46px;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center; }
  .store__categories {
    padding: 0 5px; }
  .store__items {
    padding: 10px; }
  .store__actions {
    bottom: 11px;
    left: 11px;
    padding: 0 5px 10px 5px;
    right: 11px; }
  .store__purchase-overlay-items {
    margin: 20px -5px 0; } }

@media screen and (min-width: 1100px) {
  .store__spotlight {
    width: 70px; }
  .boomer-holder__badge {
    top: 60.1%; }
  .preview-badge {
    left: 5px;
    top: 300px;
    width: 75px; }
    .preview-badge--rank {
      left: initial;
      right: 10px; }
  .store__controls {
    width: 400px; } }

.tickles-modal {
  background: #FFFFFF;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 500; }
  .tickles-modal--completed {
    z-index: 1; }
  .tickles-modal__frame {
    background: #FFFFFF;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
    padding: 20px; }
  .tickles-modal__back-button {
    margin-bottom: 20px; }
  .tickles-modal__close-button {
    display: none; }
  .tickles-modal__dropdowns {
    display: flex;
    margin: 60px -10px 40px; }
  .tickles-modal__dropdown-holder {
    flex: 1;
    margin: 0 10px; }
  .tickles-modal__sub-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-family: "Gotham Rounded Bold"; }
  .tickles-modal__dropdown--add {
    background-position: right 5px top 8px;
    border-color: #F8F8F8;
    border-width: 5px;
    flex: 1;
    height: 40px;
    line-height: 30px;
    padding: 0 35px 0 5px; }
  .tickles-modal--add {
    z-index: 1001; }
    .tickles-modal--add .tickle {
      margin-top: 20px; }
  .tickles-modal__actions {
    align-content: center;
    display: flex;
    justify-content: space-between;
    margin: 40px 0 10px; }
    .tickles-modal__actions .dropdown {
      flex: none;
      width: auto; }
  .tickles-modal__no-activity {
    margin: 0; }
  .tickles-modal__incomplete {
    text-align: center;
    margin: 60px 0 0; }
  .tickles-modal__incomplete-text {
    font-family: "Gotham Rounded Bold";
    font-size: 18px;
    margin-bottom: 20px; }
  .tickles-modal__add-buttons {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 40px 0 0;
    text-align: center; }
    .tickles-modal__add-buttons button {
      margin: 0 5px; }

.tickle {
  align-items: center;
  background: #F0F8FF;
  border: 1px solid #D6D6D6;
  border-radius: 9px;
  margin: 5px 0;
  padding: 10px; }
  .tickle--completed {
    background: none;
    border: none;
    border-bottom: 1px solid #F2F2F2;
    padding: 0 0 10px 0;
    margin: 10px 0; }
  .tickle--completed:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0; }
  .tickle__content {
    color: #00A5FF;
    font-family: "Gotham Rounded Bold"; }
  .tickle__boomer-holder {
    background: #FFFFFF;
    border-radius: 9px;
    border: 1px solid #F2F2F2;
    cursor: pointer;
    height: 70px;
    margin-right: 10px;
    padding: 5px;
    position: relative;
    width: 70px;
    min-width: 70px; }
    .tickle__boomer-holder--large {
      border: 2px solid #F2F2F2;
      cursor: initial;
      height: 140px;
      width: 140px; }
  .tickle__boomer-badge {
    position: absolute;
    left: calc(50% - 10px);
    top: 44px;
    width: 20px; }
    .tickle__boomer-badge--large {
      left: calc(50% - 20px);
      top: 86px;
      width: 40px; }
  .tickle__rank {
    bottom: -8px;
    left: calc(50% - 40px);
    position: absolute;
    width: 80px; }
    .tickle__rank--large {
      bottom: -11px;
      left: calc(50% - 80px);
      width: 160px; }

.student-overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 201; }

.student-info {
  background: #FFF;
  border-radius: 15px;
  display: flex;
  max-width: 600px;
  padding: 15px;
  position: relative;
  width: calc(100% - 20px); }
  .student-info--loading {
    justify-content: center; }
  .student-info__body {
    margin-left: 20px;
    flex: 1; }
  .student-info__badges {
    max-height: 300px;
    overflow-y: scroll; }
  .student-info__badge {
    background: #F5F5F5;
    border-radius: 8px;
    cursor: pointer;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    width: calc(33.333% - 10px); }
    .student-info__badge:hover {
      background: #00A5FF; }
  .student-info__badge:nth-child(3) {
    margin-right: 0; }
  .student-info__badge-img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 50%; }
  .student-info__close {
    background: #F6F6F7;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }
  .student-info__tickles {
    overflow: scroll; }

@media screen and (min-width: 750px) {
  .tickles-modal {
    background: none;
    /*position: static;*/
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 20px; }
    .tickles-modal__blanker {
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 400; }
    .tickles-modal__back-button {
      display: none; }
    .tickles-modal__frame {
      background: #FFFFFF;
      border-radius: 12px;
      bottom: 30px;
      margin: 0 auto;
      padding: 30px 20px 20px;
      position: fixed;
      top: 50px;
      width: 80%;
      overflow: visible; }
    .tickles-modal__close-button {
      align-items: center;
      background: #c01313;
      border: none;
      border-radius: 30px;
      cursor: pointer;
      display: block;
      font-family: "Gotham Rounded Bold";
      height: 40px;
      justify-content: center;
      padding: 0;
      position: absolute;
      right: -20px;
      top: -20px;
      width: 40px; }
      .tickles-modal__close-button:hover {
        background: #E91D1D; }
    .tickles-modal__close-icon {
      color: #FAFAFA;
      font-size: 30px; }
    .tickles-modal--add .tickles-modal__frame {
      bottom: auto;
      top: 90px;
      width: 70%; }
    .tickles-modal__list-holder {
      height: 100%;
      overflow: scroll; } }

@media screen and (min-width: 1000px) {
  .tickles-modal__frame {
    max-width: 1040px; }
  .tickles-modal--add .tickles-modal__frame {
    width: 60%;
    max-width: 750px; } }

.history {
  bottom: 0;
  background: #FFF;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 500; }

.history__close {
  background: red;
  border-radius: 150px;
  color: #FFF;
  cursor: pointer;
  font-family: "Gotham Rounded Bold";
  font-size: 22px;
  height: 28px;
  line-height: 28px;
  position: fixed;
  top: 10px;
  right: 10px;
  text-align: center;
  width: 28px;
  z-index: 501; }

.history__header {
  padding: 20px; }

.history__inner, .history__main {
  display: flex;
  flex-direction: column;
  height: 100%; }

.history__list-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%; }
  .history__list-panel--hide {
    display: none; }

.history__list {
  flex: 1;
  min-height: 100px;
  padding: 0 20px;
  overflow-y: scroll; }
  .history__list--preview {
    flex: 0;
    min-height: 70px; }

.history__entry {
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  color: #00A5FF;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 20px;
  position: relative; }

.history__entry-view {
  background: #E6F6FF;
  border-radius: 5px;
  display: block;
  font-size: 12px;
  padding: 5px 10px;
  position: absolute;
  right: 20px;
  top: calc(50% - 12px); }

.history__entry:hover, .history__entry--selected {
  cursor: pointer;
  background: #00A5FF;
  color: #FFF; }
  .history__entry:hover .history__entry-view, .history__entry--selected .history__entry-view {
    background: #0073B2;
    color: #00A5FF; }

.history__preview {
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px; }
  .history__preview--hide {
    display: none; }
  .history__preview--double {
    display: flex;
    justify-content: space-between; }
  .history__preview .button {
    flex: 1;
    margin-right: 10px; }
  .history__preview .button--green {
    margin-left: 10px;
    margin-right: 0; }

.history__entry-preview {
  display: none;
  height: 100%;
  flex-direction: column;
  flex: 1; }
  .history__entry-preview--show {
    display: flex; }

.history__text {
  flex: 1;
  overflow-y: scroll;
  padding: 0 20px; }

.history__warning {
  font-family: "Gotham Rounded Bold";
  text-align: center; }

@media screen and (min-width: 1000px) {
  .history {
    bottom: 80px;
    border-radius: 15px;
    left: 80px;
    right: 80px;
    top: 80px; }
  .history__close {
    right: 66px;
    top: 66px; }
  .history__inner {
    display: flex; }
  .history__main {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
  .history__list-panel--hide {
    display: flex; }
  .history__preview {
    box-shadow: none; }
    .history__preview .button--green {
      margin-left: 0; }
  .history__header {
    min-width: 100%;
    width: 100%; }
  .history__entry-preview {
    display: flex; }
  .history__hide-desktop {
    display: none; } }

.history__entry-holder {
  border: 1px dashed #CCC;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  overflow-y: scroll;
  padding: 20px; }
  .history__entry-holder--placeholder {
    color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }

.voting {
  padding: 20px;
  position: relative; }
  .voting__instructions {
    background: #4E406E;
    display: none; }
    .voting__instructions--show {
      display: block; }
  .voting__instruction-btn {
    display: none; }
  .voting__close {
    border: none;
    border-radius: 200px;
    cursor: pointer;
    top: 20px;
    right: 20px;
    padding: 5px;
    position: absolute;
    height: 40px;
    width: 40px; }
    .voting__close:hover {
      background: #F1F1F1; }
    .voting__close:focus {
      border: 2px solid #4E406E;
      outline: none; }
    .voting__close-img {
      height: auto;
      width: 20px; }
  .voting__entry-loader {
    height: 30px;
    position: absolute;
    right: 15px;
    top: 12px;
    width: auto; }
  .voting__title {
    font-family: "Gotham Rounded Bold";
    font-size: 21px;
    margin-bottom: 10px; }
  .voting__entries-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .voting__entries-list__entry {
      background: white;
      margin: 10px;
      padding: 20px;
      position: relative;
      width: calc(100% - 20px); }
      .voting__entries-list__entry .u-pill {
        position: absolute;
        right: 15px;
        top: 15px; }
  .voting__entry-preview {
    font-size: 14px;
    line-height: 22px;
    height: 70px;
    margin: 20px 0;
    overflow: hidden; }
  .voting__entry-buttons--double {
    display: flex;
    margin: 0 -10px; }
    .voting__entry-buttons--double .button {
      flex: 0 calc(50% - 20px);
      margin: 0 10px; }
  .voting__entry-buttons .button {
    width: 100%; }
  .voting__entry {
    background: white;
    padding: 20px; }
    .voting__entry__buttons {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px 10px; }
      .voting__entry__buttons button {
        margin: 0 5px; }
    .voting__entry__title {
      align-items: center;
      color: #00A5FF;
      display: flex;
      font-family: "Gotham Rounded Bold";
      font-size: 21px;
      margin-bottom: 20px; }
      .voting__entry__title .voting__entry__star {
        height: 22px;
        margin: -4px 0 0 10px;
        width: auto; }
  .voting__entry__header {
    align-items: center;
    color: #00A5FF;
    display: flex;
    font-family: "Gotham Rounded Bold"; }
    .voting__entry__header .voting__entry__star {
      height: 18px;
      margin: -2px 0 0 5px;
      width: auto; }
  .voting__progress {
    background: #FFFBE7;
    border-radius: 15px;
    color: #00A5FF;
    font-family: "Gotham Rounded Bold";
    padding: 15px 15px 25px 15px;
    position: relative; }
  .voting__bar {
    background: #F4F4F4;
    border-radius: 20px;
    box-shadow: inset 0 0px 3px rgba(0, 0, 0, 0.2);
    display: block;
    height: 10px;
    margin-top: 10px;
    overflow: hidden;
    width: 100%; }
  .voting__bar-inner {
    background-color: #ff8000;
    /* Old browsers */
    background-image: url("../images/progress_bg.png");
    background-image: url("../images/progress_bg.png"), -moz-linear-gradient(left, #ff8000 0%, #ffbb47 100%);
    /* FF3.6-15 */
    background-image: url("../images/progress_bg.png"), -webkit-linear-gradient(left, #ff8000 0%, #ffbb47 100%);
    /* Chrome10-25,Safari5.1-6 */
    background-image: url("../images/progress_bg.png"), linear-gradient(to right, #ff8000 0%, #ffbb47 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8000', endColorstr='#ffbb47',GradientType=1 );
    /* IE6-9 */
    display: block;
    height: 10px;
    transition: width linear 0.5s;
    width: 0%; }
  .voting__progress-check {
    animation: votingEnabled 0.5s ease-in 0.5s forwards, pulse ease 1s 1s;
    background: #FFF;
    border-radius: 100px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 30px;
    width: 30px;
    transform: scale(0); }
    .voting__progress-check--report {
      height: 40px;
      width: 40px; }
  .voting__progress-img {
    height: auto;
    width: 100%; }
  .voting__progress-text {
    bottom: 5px;
    color: #4a4a4a;
    font-size: 12px;
    position: absolute;
    right: 40px;
    transition: color ease 1s; }
    .voting__progress-text--active {
      color: #23B232; }

.button--bookmark {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  left: 50%;
  line-height: 16px;
  margin-left: -111px;
  position: absolute;
  top: 15px;
  width: 222px; }

.button__image--bookmark {
  height: 14px;
  margin: -2px 5px 0 0;
  width: auto; }

.entry__bookmark-loader {
  height: auto;
  left: 50%;
  margin-left: -18px;
  position: absolute;
  top: 20px;
  width: 36px; }

.voting__entry {
  width: calc(100% - 120px);
  animation: expand 0.5s;
  bottom: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  left: 120px;
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 950; }

.voting__entry-header {
  border-bottom: 1px solid #CCC;
  margin-bottom: 10px; }

.voting__entry__text {
  font-size: 16px;
  height: calc(100vh - 170px);
  line-height: 26px;
  margin-bottom: 20px;
  overflow-y: scroll;
  white-space: pre-wrap; }

.voting__entry__content {
  animation: fadeIn 0.8s ease 0.5s 1 normal forwards;
  margin: 0 auto;
  max-width: 800px;
  opacity: 0; }

.voting-tab--instructions {
  background: #6F59A1;
  border: none;
  border-radius: 20px;
  color: #FFCE89;
  flex: 0;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 10px 5px 25px;
  position: absolute;
  right: 20px;
  top: 15px; }

.voting-tab__info {
  display: block;
  left: -10px;
  position: absolute;
  height: auto;
  top: -2px;
  width: 30px; }

.instruction__detail-prompt {
  align-items: center;
  color: #CCC;
  display: flex; }

.instruction__detail-coin {
  height: 16px;
  margin-right: 10px;
  width: auto; }

.voting-success {
  font-family: "Gotham Rounded Bold";
  max-width: 600px;
  padding: 20px;
  text-align: center; }
  .voting-success__boomers {
    height: auto;
    width: 200px; }

.vote-confirm-overlay__body {
  background: #4E406E; }

.vote-confirm-overlay__inner {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.vote-confirm-overlay__voted-img {
  max-height: 213px;
  max-width: 570px;
  width: 100%; }

.vote-confirm-overlay__group-img {
  max-height: 233px;
  max-width: 982px;
  width: 100%; }

.vote-confirm-overlay__title, .vote-confirm-overlay__message {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "Gotham Rounded Bold";
  line-height: normal;
  margin: 10px 0 10px; }

.vote-confirm-overlay__title {
  font-size: 24px;
  margin-top: 20px; }

.vote-confirm-overlay__coins {
  height: 24px; }

.vote-confirm-overlay__button-holder {
  margin: 10px 0 20px; }

.vote-confirm-overlay__button {
  display: inline-block; }

@media screen and (min-width: 750px) {
  .voting {
    padding: 0; }
    .voting__entries-list__entry {
      flex: 0 calc(50% - 20px);
      max-width: calc(50% - 20px); }
  .voting-tab--instructions {
    right: 0; }
  .vote-confirm-overlay__body {
    background: #FFFFFF; }
  .vote-confirm-overlay__inner {
    background: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px; }
  .vote-confirm-overlay__voted-img {
    max-height: 213px;
    max-width: 570px;
    position: absolute;
    top: -183px;
    width: 100%; }
  .vote-confirm-overlay__group-img {
    bottom: -100px;
    max-height: 233px;
    max-width: 982px;
    position: absolute;
    width: 100%; }
  .vote-confirm-overlay__title, .vote-confirm-overlay__message {
    color: #4A4A4A; }
  .vote-confirm-overlay__title {
    color: #00A5FF;
    margin-top: 0; }
  .vote-confirm-overlay__coins {
    height: 24px; }
  .vote-confirm-overlay__button-holder {
    margin-bottom: 0; }
  .vote-confirm-overlay__close-button {
    right: -40px; } }

@media screen and (max-width: 1000px) {
  .voting__instructions {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200; } }

@media screen and (min-width: 1000px) {
  .voting__content {
    display: flex; }
  .voting__entries-list {
    flex: 1;
    height: 0%;
    /*hack*/
    order: 1; }
    .voting__entries-list__entry {
      flex: 1 calc(50% - 20px); }
  .voting__instruction-btn {
    background: #4E406E;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #FFCE89;
    display: inline-block;
    font-family: "Gotham Rounded Bold";
    margin-top: 20px;
    padding: 10px 15px;
    width: initial; }
  .voting__instructions {
    border-radius: 15px;
    border-top-left-radius: 0;
    display: block;
    padding: 20px 0; }
  .voting__side {
    flex: 0;
    order: 2;
    min-width: 250px;
    margin: 10px 0 0  20px; }
  .voting-tab--instructions {
    display: none; }
  .vote-confirm-overlay__group-img {
    bottom: -110px; } }

@media screen and (min-width: 1100px) {
  .voting__side {
    min-width: 350px; } }

@keyframes votingEnabled {
  0% {
    transform: scale(0); }
  80% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes votingEnabled {
  0% {
    transform: scale(0); }
  80% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes expand {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 100%;
    width: 100%; } }

@keyframes expand {
  0% {
    height: 0;
    width: 0; }
  100% {
    height: 100%;
    width: 100%; } }

.votingBoomers {
  max-width: 300px;
  height: auto;
  margin-bottom: 20px; }

@media screen and (min-width: 600px) {
  .votingBoomers {
    max-width: 500px; } }

.votingEndPanel {
  max-width: 700px; }

.votingHeader {
  color: #4E406E;
  margin-bottom: 10px;
  font-size: 30px; }

.bee-container {
  flex: 1;
  overflow: scroll;
  margin-bottom: 20px; }

.bee-panel {
  text-align: center; }

.bee-panel__heading {
  font-size: 16px;
  font-family: "Gotham Rounded Bold"; }

.bee-panel__para {
  font-size: 14px;
  line-height: 20px; }

.bee-panel__image {
  height: 100px;
  width: auto; }

@media screen and (min-width: 960px) {
  .bee-container, .bee-panel__button {
    max-width: 850px; } }

.chat-toggle {
  background-color: #00A5FF;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25px auto;
  border: none;
  border-radius: 200px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 25px;
  height: 40px;
  text-align: center;
  padding: 0;
  width: 40px;
  z-index: 200; }
  .chat-toggle--new {
    animation: chat-pulse 1.5s infinite; }

.chat-loader {
  display: block;
  margin: 20px auto; }

.chat-graphic {
  height: auto;
  margin-bottom: 20px;
  width: 100px; }

.chat-main__default {
  align-items: center;
  display: flex;
  border-left: 1px solid #f4f4f4;
  flex: 1;
  justify-content: center; }

@-webkit-keyframes chat-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #f26262; }
  70% {
    -webkit-box-shadow: 0 0 0 7px rgba(242, 98, 98, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(242, 98, 98, 0); } }

@keyframes chat-pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #f26262;
    box-shadow: 0 0 0 0 #f26262; }
  70% {
    -moz-box-shadow: 0 0 0 7px rgba(242, 98, 98, 0);
    box-shadow: 0 0 0 7px rgba(242, 98, 98, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(242, 98, 98, 0);
    box-shadow: 0 0 0 0 rgba(242, 98, 98, 0); } }

.chat-modal {
  background: #ffffff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 500;
  font-family: "Gotham Rounded Book";
  text-transform: capitalize; }

.chat-mob-close {
  background: #4E406E;
  height: 50px;
  position: relative; }

.chat-mob-close__btn {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px; }

.chat-mob-close__btn-img {
  cursor: pointer;
  height: 25px;
  width: auto; }

.chat-mob__return-btn {
  position: absolute;
  left: 5px;
  top: 5px; }

.chat-modal-inner {
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  overflow-y: scroll;
  text-align: center;
  padding: 30px; }
  .chat-modal-inner--hide {
    display: none; }

.chat-contact {
  color: #4a4a4a;
  cursor: pointer;
  line-height: 30px;
  margin: 20px 0;
  padding: 0 0 0 35px;
  position: relative;
  text-align: left; }
  .chat-contact:hover {
    color: #00A5FF; }

.chat-contact__ava {
  background: #00A5FF;
  border-radius: 200px;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px; }

@media screen and (max-width: 1000px) {
  .chat-contact {
    font-size: 22px;
    padding: 10px 0 10px 70px;
    margin: 30px 0; }
  .chat-contact__ava {
    height: 60px;
    width: 60px;
    top: -5px; } }

.chat-main {
  display: none;
  flex-direction: column;
  height: 100%; }
  .chat-main--show {
    display: flex; }

.chat-main__header {
  background: #f4f4f4;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 5;
  text-align: center; }
  .chat-main__header .chat-contact {
    display: inline-block;
    cursor: default; }
    .chat-main__header .chat-contact:hover {
      color: #4a4a4a; }

.chat-main__message-body {
  flex: 1;
  overflow-y: scroll;
  padding: 20px 20px 0 20px; }

.chat-main__message {
  margin-bottom: 20px;
  position: relative;
  padding-left: 60px;
  text-align: left;
  font-weight: normal; }
  .chat-main__message--self {
    text-align: right;
    padding-left: 0;
    padding-right: 60px; }

.chat-main__ava {
  border-radius: 200px;
  height: 50px;
  left: 0;
  position: absolute;
  width: 50px; }
  .chat-main__ava--self {
    left: initial;
    right: 0; }

.chat-contact__unread {
  background: #f26262;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  border-radius: 50px;
  display: block;
  height: 10px;
  left: 22px;
  position: absolute;
  width: 10px;
  animation: chat-pulse 1s infinite; }

.chat-main__message__text {
  background: #00A5FF;
  border-radius: 10px;
  color: #fff;
  min-height: 60px;
  padding: 10px; }
  .chat-main__message__text--self {
    background: #efefef;
    color: #4a4a4a; }

.chat-main__message__date {
  color: #a6a6a6;
  font-size: 12px;
  margin-top: 5px;
  padding: 0 10px; }
  .chat-main__message__date--self {
    text-align: right; }

.chat-main__textarea {
  padding: 20px; }

.chat-main__textarea .button {
  float: right; }

.chat-main__textarea__input {
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  height: 70px;
  padding: 10px; }

@media screen and (min-width: 1000px) {
  .chat-toggle {
    background-color: #4E406E;
    right: 20px;
    top: 20px; }
  .chat-overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 499; }
  .chat-modal {
    border-radius: 15px;
    bottom: 75px;
    flex-direction: initial;
    justify-content: initial;
    left: calc(50% - 350px);
    right: initial;
    top: 75px;
    width: 800px; }
  .chat-modal-inner {
    align-items: initial;
    justify-content: initial;
    margin-bottom: 0;
    padding: 10px 20px;
    width: 250px; }
    .chat-modal-inner--hide {
      display: flex; }
  .chat-mob__return-btn {
    display: none; }
  .chat-mob-close {
    background: red;
    border-radius: 200px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    height: 33px;
    position: absolute;
    right: -15px;
    top: -15px;
    width: 33px; }
    .chat-mob-close__btn {
      right: 4px;
      top: 4px; }
  .chat-main {
    display: flex;
    padding: 20px;
    width: 550px; }
    .chat-main--hide {
      display: flex; }
    .chat-main__header {
      box-shadow: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px; }
    .chat-main__message-body {
      border: 1px solid #f4f4f4; }
    .chat-main__textarea {
      padding: 0;
      margin-top: 10px; }
      .chat-main__textarea__input {
        border-top-right-radius: 0;
        border-top-left-radius: 0; } }

.project-dash {
  padding: 20px; }
  .project-dash__card, .project-dash__units {
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }
  .project-dash__units {
    margin-top: 20px;
    padding: 10px; }
  .project-dash__unit-link {
    background: #ffa22b;
    border-radius: 7px;
    color: #4A4A4A;
    display: block;
    font-family: "Gotham Rounded Bold";
    font-size: 20px;
    padding: 20px 100px 20px 20px;
    position: relative;
    text-decoration: none; }
    .project-dash__unit-link:hover {
      background: #f78b00; }
    .project-dash__unit-link--draft, .project-dash__unit-link--draft:hover {
      background: #EBE9EE;
      color: #B5B5B5; }
    .project-dash__unit-link--active {
      background: #6518D2;
      color: #FFF; }
      .project-dash__unit-link--active:hover {
        background: #4f14a4; }
  .project-dash__reading-boomer, .project-dash__writing-boomer, .project-dash__voting-boomer {
    position: absolute;
    height: auto;
    bottom: 0;
    right: 10px;
    width: 85px; }
  .project-dash__writing-boomer {
    width: 115px; }
  .project-dash__voting-boomer {
    width: 100px; }
  .project-dash__unit-author {
    color: #696969;
    font-size: 14px;
    font-family: "Gotham Rounded Book"; }
  .project-dash__unit-pill {
    background: #FFF;
    border-radius: 15px;
    color: #4a4a4a;
    display: inline-block;
    font-size: 14px;
    line-height: 12px;
    margin-top: 5px;
    padding: 8px 10px 8px 35px;
    position: relative; }
    .project-dash__unit-pill--alt, .project-dash__unit-pill--submitted {
      background: #ffad44;
      color: #000; }
    .project-dash__unit-pill--submitted {
      background: #7ae678; }
    .project-dash__unit-pill--rejected {
      background: #f93737;
      color: #FFF; }
  .project-dash__unit-icon {
    height: auto;
    left: 10px;
    position: absolute;
    top: 6px;
    width: 18px; }
    .project-dash__unit-icon--pencil {
      left: 12px;
      width: 16px; }
  .project-dash__card-header {
    padding: 20px;
    text-align: center; }
  .project-dash__img {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 120px; }
    .project-dash__img--alt {
      width: 180px; }
  .project-dash__card-title {
    color: #4a4a4a;
    font-size: 18px;
    margin: 20px 0 5px 0; }
  .project-dash__card-code {
    background: #ffa22b;
    border-radius: 15px;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 14px;
    padding: 5px 15px; }
  .project-dash__card-class {
    color: #a5a5a5;
    display: block;
    margin-top: 20px; }
  .project-dash__card-progress {
    -webkit-box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.2);
    color: #4a4a4a;
    font-size: 14px;
    font-family: "Gotham Rounded Bold";
    padding: 20px;
    text-align: center; }
  .project-dash__unit-deadline {
    color: #ffa22b;
    font-size: 12px;
    margin-left: 10px;
    padding-left: 20px;
    position: relative; }
  .project-dash__unit-deadline-img {
    left: 0;
    height: 18px;
    position: absolute;
    top: -4px;
    width: auto; }
  .project-dash:after {
    background-image: url("../images/pattern.png");
    content: '';
    height: 220px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1; }

.project-dash--alt:after {
  background-image: url("../images/pattern-alt.png"); }

@media screen and (min-width: 750px) {
  .project-dash {
    padding: 40px 40px 40px 160px; } }

@media screen and (min-width: 1000px) {
  .project-dash__card, .project-dash__units {
    float: left;
    width: 250px; }
  .project-dash__units {
    margin: 0 0 0 40px;
    max-width: 740px;
    width: calc(100% - 290px); } }

@media screen and (min-width: 1200px) {
  .project-dash__card {
    width: 300px; }
  .project-dash__units {
    width: calc(100% - 340px); } }

.main-class {
  position: relative;
  max-width: 1200px; }

.class-title {
  color: #4a4a4a;
  font-size: 28px;
  font-family: "Gotham Rounded Bold";
  width: calc(100% - 200px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.class-teacher {
  color: #4a4a4a;
  padding-left: 35px;
  position: relative;
  margin: 15px 0; }
  .class-teacher__img {
    border-radius: 100px;
    height: auto;
    left: 0;
    top: -8px;
    position: absolute;
    width: 30px; }

.class-students {
  position: absolute;
  right: -20px;
  top: 0; }

.class-filters {
  border-top: 1px solid #e8e5e5;
  margin-top: 20px;
  padding-top: 20px; }

.class-filter {
  background: none;
  border: none;
  border-radius: 10px;
  font-family: "Gotham Rounded Bold";
  color: #4a4a4a;
  font-size: 12px;
  padding: 7px 12px; }
  .class-filter--active {
    background: orange;
    color: #FFF; }

.class-project {
  background: #7015d0;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  color: #FFF;
  display: block;
  height: 140px;
  margin-top: 20px;
  padding-right: 200px;
  position: relative;
  overflow: hidden;
  text-decoration: none; }
  .class-project__meta {
    background: #7c17e7;
    height: 100%;
    padding: 20px 0 20px 20px;
    position: relative;
    text-decoration: none;
    width: 100%; }
    .class-project__meta .meta-child {
      position: relative;
      z-index: 2; }
    .class-project__meta:before {
      background: #7c17e7;
      border-radius: 800px;
      content: '';
      right: -30px;
      top: -120px;
      position: absolute;
      height: 350px;
      width: 350px;
      z-index: 0; }
  .class-project__title {
    font-size: 20px;
    font-family: "Gotham Rounded Bold";
    line-height: 24px;
    margin-bottom: 2px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .class-project__phase {
    background: #FFF;
    border-radius: 20px;
    color: #000;
    display: inline-block;
    font-family: "Gotham Rounded Bold";
    font-size: 14px;
    margin-top: 10px;
    padding: 5px 10px; }
  .class-project__deadline {
    color: orange;
    font-size: 14px;
    font-family: "Gotham Rounded Bold";
    position: absolute;
    left: 100px;
    top: calc(50% - 7px);
    width: 100px;
    z-index: 2; }
  .class-project__alarm {
    height: 16px;
    position: absolute;
    right: -22px;
    top: 2px;
    width: auto;
    z-index: 2; }
  .class-project__hero {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 120px auto;
    background-image: url("../images/writing-boomer.png");
    height: 145px;
    position: absolute;
    right: 0px;
    top: 0;
    width: 170px; }
    .class-project__hero--voting {
      background-image: url("../images/voting-boomer.png"); }
    .class-project__hero--reading {
      background-image: url("../images/reading-boomer.png");
      background-size: 100px auto; }

.class-project--alt {
  background: orange;
  color: #4a4a4a; }
  .class-project--alt .class-project__meta:before, .class-project--alt .class-project__meta {
    background: #ffb329; }
  .class-project--alt .class-project__deadline {
    color: #7015d0; }

@media screen and (min-width: 920px) {
  .class-project {
    float: left;
    width: calc(50% - 10px); }
  .class-project:nth-child(odd) {
    margin-right: 20px; } }

@media screen and (min-width: 1400px) {
  .class-project {
    width: calc(33% - 10px);
    margin-right: 20px; }
  .class-project:nth-child(3n+3) {
    margin-right: 0; } }

.rec-slider {
  width: 100%;
  justify-content: center !important; }

.closeModal {
  position: absolute;
  top: -20px;
  right: 60px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #c01313;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2; }
  .closeModal p {
    margin-top: 4px; }

.carousel:last-of-type {
  display: none; }

.carousel-slider {
  height: 400px;
  border: none; }
  .carousel-slider p.carousel-status {
    display: none; }
  .carousel-slider .control-dots {
    opacity: 0; }
  .carousel-slider .control-arrow.control-prev, .carousel-slider .control-arrow.control-next {
    min-height: 50px;
    max-height: 50px;
    max-width: 50px;
    max-width: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50px);
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(128, 128, 128, 0.535);
    border: 1px solid #cacaca;
    opacity: 1; }
    .carousel-slider .control-arrow.control-prev.control-next, .carousel-slider .control-arrow.control-next.control-next {
      right: 20px; }
    .carousel-slider .control-arrow.control-prev.control-prev, .carousel-slider .control-arrow.control-next.control-prev {
      left: 20px; }
  .carousel-slider .control-arrow.control-disabled {
    opacity: 0;
    pointer-events: none; }
  .carousel-slider .slider-wrapper ul li {
    background: transparent; }
    .carousel-slider .slider-wrapper ul li div img {
      height: 400px;
      width: auto; }
    .carousel-slider .slider-wrapper ul li div p.legend {
      display: none; }
