@import './../NewDashboard/newShared.scss';

.modalWrapper {
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.6);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  .idleModal {
    width: 545px;
    background-color: white;
    padding: 1em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $main-purple;
    font-weight: bold;
    .heading{
      font-size: 26px;
      display: flex;
      align-items: center;
      margin: 0.5em 0;
    }
    p{
      margin:1em 0;
    }
    .orangeBtn {
      @include btn(white, $main-orange, 3em);
      margin:1em 0
    }
    .logout{
      cursor: pointer;
      margin: 1em;
    }
  }
}
