@import "./../MainWritingEditor.module.scss";

$grading-blue: #065fe5;

.grading {
    // position: absolute;
    // right: 2%;
    // top: 1.5%;
    height: 100px;
    width: 215px;
    display: flex;
    color: $grading-blue;
    font-family: "Gotham Rounded Bold";
    align-items: center;

    p {
        margin-bottom: 5px;
    }

    button {
        cursor: pointer;
        border: none;
        background-color: $main-purple;
        color: white;
        font-family: "Gotham Rounded Bold";
        // font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        border-radius: 5px;
        padding:2px 5px;
        transition: all 0.5s ease;
        &:hover{
            background-color: $main-purple--bright;
        }
    }

    .score {
        background-image: url("./../../../../../../images/newDashboard/decorations/blueCircleGrades.svg");
        // background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 55px 55px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 30px;
        font-family: "Gotham Rounded Bold";
        margin-left: 10px;
    }
}
