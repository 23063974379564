/* chrome books chrome window is 1280 x 673 */ 

html,body {
	font-family: $primaryFont;
	min-height: 100%;
}
body {
	background: url('./../images/theme/pattern-blue.png');
}

h1 {
	font-size: 28px;
    font-family: $primaryFontBold;
}

strong {
    font-family: $primaryFontBold;
}