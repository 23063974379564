

.basic-form {
	&__group {
		margin-bottom: 20px;
	}
	&__text-box {
		border: 2px solid #DADADA;
		border-radius: 12px;
		box-shadow: inset 0 5px 6px #DADADA;
		color: #4a4a4a;
        font-family: $primaryFontBold;
		font-size: 16px;
		line-height: 18px;
		padding: 12px 20px 9px 20px;
		width: 100%;
		&:focus {
			color: #000;
			border: 4px solid #FCC62D;
			padding: 10px 18px 7px 18px;
			box-shadow: none;
			outline:none;
		}
		&--locked {
			background: #F4F4F4;
			color: #969696;
		}
	}
	&__label {
		color: white;
		display: block;
        font-family: $primaryFontBold;
		font-size: 14px;
	}
	&__disclaimer {
		color: white;
        font-family: $primaryFontBold;
		font-size: 12px;
	}
	&__hint {
		color: $red;
        font-family: $primaryFontBold;
		font-size: 12px;
		margin-top: 10px;
	}
	&__success,
	&__errors {
		align-items: center;
		background: $greenLight;
		border-radius: 6px;
		border: 1px solid $green;
		color: $green;
		display: flex;
        font-family: $primaryFontBold;
		font-size: 16px;
		margin-top: 20px;
		padding: 15px 10px;
	}
	&__errors {
		background: $redLight;
		border-color: $red;
		color: $red;
	}
	&__result-img {
		margin-right: 7px;
		width: 20px;
		&--error {
			width: 30px;
		}
	}
}

.dropdown {
	appearance: none;
	background-color: #FFFFFF;
	background-image: url('../images/chevron-down.png');
	background-position: right 16px top 16px;
	background-repeat: no-repeat;
	background-size: 22px auto;
	border: 1px solid #D6D6D6;
	border-radius: 12px;
	color: $blue;
    font-family: $primaryFontBold;
	font-size: 16px;
	line-height: 16px;
	padding: 15px 50px 15px 20px;
	width: 100%;
	&:focus {
		border: 1px solid $blue;
		outline: none;
	}
}

@media screen and (min-width: $tablet) {
	.basic-form {
		max-width: 350px;
		&__label {
			font-size: 16px;
		}
	}			
}
