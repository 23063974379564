.portfolio-project {
	margin-bottom: 20px;
	width: 100%;
	&:last-child {
		margin-bottom: 0;
	}
	&__inner {
		color: #000;
		display: flex;
        font-family: $primaryFontBold;
        font-size: 18px;
        line-height: 24px;
		padding: 20px;
		text-decoration: none;
	}
	&__info {
		flex: 1;
	}
	&__image {
		width: 90px;
		height: auto;
	}
	&__cover {
		height: 135px;
		margin-right: 20px;
		position: relative;
		width: 90px;
	}
	&__detail {
        border-top: 1px solid #D4D4D4;
		color: #4a4a4a;
		font-size: 14px;
        font-family: $primaryFont;
		padding: 4px 0;
		position: relative;
	}
	&__teacher { 
		align-items: center;
		display: flex;
		font-size: 14px;
	}
    &__teacher-img {
		height: 16px;
		margin-right: 5px;
		width: auto;
	}
	&__status {
        font-family: $primaryFontBold;
		&--complete  {
			color: #23B232;
			display: flex;
			align-items: center;
		}
		&--progress {
			color: #FB8C2B;
		}
	}
	&__check {
		height: initial;
		margin-right: 5px;
		width: 16px;
	}
	&__actions {
        column-count: 2;
	    column-gap: 10px;
	}
}
.report-read__flags {
	width: calc(100% - 120px);
}
.report-read__status {
	align-items: center;
	display: flex;
	float: left;
	font-family: $primaryFontBold;
	font-size: 14px;
	margin: 0 20px 10px 0;
}
.report-read__status-img {
	height: 20px;
	margin-right: 5px;
	width: auto;
}
.portfolio-read-side {
	margin-bottom: 20px;
}
.portfolio-side__teacher {
	align-items: center;
	display: flex;
	font-size: 14px;
	margin-top: 10px;
}
.portfolio-side__ava {
	height: 16px;
	margin-right: 7px;
	width: auto;
}

.portfolio-read-chapter {
	padding: 20px;



	.unit-review-scroll-arrows{
		margin-left: 5px;
		margin-bottom: 15px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		img.arrow{
			cursor: pointer;

			&.disable{
				pointer-events: none;
				opacity: .2;
			}

			&:first-of-type{
				margin-right: 20px;
			}
			&:last-of-type{
				margin-left: 20px;
			}
		}

		p{
			margin:0 5px;
			font-size: 22px;
		}
	}

	&__title-area {
		margin-bottom: 20px;
	}
	&__title {
		margin-bottom: 10px;
	}
	&__entry-area {
		background: white;
		border: 1px solid #D6D6D6;
		border-radius: 12px;
		box-sizing:border-box;
		padding:20px 30px;
	}
	&__buttons {
		position: relative;
		width: 100%;

		p.unit-review-word-count{
			position: absolute;
			top:10px;
			right: 20px;
		}
	}
	&__switch-buttons {
		display: flex;
		margin: 0 -2px 20px;
		height: 70px;


	.winning-entry-slider{
		width: 260px;
		display: flex;
		justify-content: space-between;
		background-color: #4e406e;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 4px;

		.button {
			border-radius: 10px;
			font-weight: 300;
			font-size: 15px;
			margin: 0 2px;
			padding: 0 15px;
			border:none;
			color: white;
			background-color: transparent;
			&.active {
				background: #8e00f0;
				color: #FFFFFF;
			}
		}
	}

		.button {
			margin: 0 2px;
			padding: 0 15px;
			&.active {
				background: $blue;
				color: #FFFFFF;
			}
		}
	}
	
	&__winning-entry {
		align-items: center;
		background: #FFF;
		border-radius: 12px;
		color: white;
		display: inline-flex;
		font-family: $primaryFontBold;
		font-size: 16px;
		height: 40px;
		margin-bottom: 10px;
		padding: 0 20px;
		background: rgb(6,95,229);
		background: linear-gradient(180deg, rgba(6,95,229,1) 19%, rgba(142,0,240,1) 87%);
	}
	&__star {
		height: 18px;
		margin-right: 10px;
		width: auto;
	}
	&__entry-holder {
		background: #FCFCFC;
		// border-radius: 12px;
		// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		font-size: 14px;
		padding: 20px;
	}
	&__title {
        font-family: $primaryFontBold;
		margin-bottom: 5px;
	}
	&__author {
		color: lighten($defaultTextColor, 20%);
		margin-bottom: 20px;
	}
	&__entry-text {
		line-height: 1.8em;
		white-space: pre-line;
		p {
			margin-bottom: 20px;
		}
	}
	&__warning {
		align-items: center;
		color: $red;
		display: flex;
    	font-family: $primaryFontBold;
	}
	&__warning-img {
		height: 14px;
		margin-right: 5px;
	}
	&__feedback-holder {
		background: #FCFCFC;
		border-radius: 12px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		font-size: 14px;
		padding: 20px;
	}
	&__current-feedback {
		font-style: italic;
		line-height: 1.8em;
	}
	&__teacher {
        font-family: $primaryFontBold;
	}
	&__old-feedback-holder {
		&--bordered {
			padding-top: 10px;
			border-top: 1px solid darken(#FCFCFC, 10%);
			margin-top: 10px;
		}
	}
	&__old-feedback {
		font-style: italic;
	}
	&__date {
		color: lighten($defaultTextColor, 20%);
        font-family: $primaryFontBold;
	}
}

.portfolio-aside {
	margin-bottom: 20px;
	max-width: 800px;
	padding: 10px;
	position: relative;
	&__header {
		display: flex;
		align-items: center;
		padding: 10px 0;
	}
	&__image {
		height: 100%;
		margin-right: 20px;
		width: 80px;
	}
	&__teacher {
		align-items: center;
		display: flex;
		margin-bottom: 10px;
	}
	&__code {
		margin-bottom: 10px;
	}
	&__ava {
		height: auto;
		margin-right: 5px;
		width: 20px;
	}

	&__button {
		width: 100%;
	}

	&__title {
		display: none;
	}
}

.portfolio-main {
	max-width: 800px;
}

.portfolio-side {
	padding: 20px;
}

.report-instructions {
	background: $purple;
	bottom: 0;
	color: #FFF;
	font-size: 14px;
	left: 0;
	line-height: 18px;
	overflow-y: scroll;
	padding: 80px 40px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 5000;
	&--hidden {
		display: none;
	}
}
.report-instructions__heading {
	color: $yellowLight;
	font-family: $primaryFontBold;
	margin-bottom: 5px;
}

.report-instructions__img {
	float: left;
	height: auto;
	width: 30px;
}
.report-instructions__body {
	float: left;
	margin-left: 10px;
	width: calc(100% - 40px);
}
.report-instructions__inner {
	margin: 0 auto;
	max-width: 550px;
}
.report-instructions__close {
	background: none;
	border: none;
	display: block;
	height: 40px;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 40px;
}
.report-instructions__close-icon {
	height: auto;
	width: 22px;
}
.portfolio-read-chapter__entry-holder {
	position: relative;
}
.portfolio__instructions-btn {
	background: $purpleLight;
	border: none;
	border-radius: 20px;
	color: $yellowLight;
	font-family: $primaryFontBold;
	font-size: 12px;
	line-height: 16px;
	padding: 5px 10px 5px 25px;
	position: absolute;
	right: 15px;
	top: 15px;
}
.portfolio__info {
	display: block;
	left: -10px;
	position: absolute;
	height: auto;
	top: -2px;
	width: 30px;
}
@media screen and (min-width: $mobile) {
	.portfolio-project {
		&__image {
			width: 100px;
		}
		&__cover {
			margin-right: 20px;
			height: auto;
			position: relative;
			width: 110px;
		}
	}
}

@media screen and (min-width: $tablet) {
	.portfolio-read-chapter {
		padding: 0;
		&__title-area {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
		&__title {
			margin: 0;
		}
		&__dropdown-container {
			border: none;
			padding: 0;
		}
	}

	.portfolio-aside {
		&__button {
			margin-top: -19px;
			position: absolute;
			right: 20px;
			top: 50%;
			width: initial;
		}
	}
}

@media screen and (min-width: $desktop) {
	.report-instructions__close, .portfolio__instructions-btn {
		display: none;
	}
	.portfolio-read-chapter__layout {
		display: flex;
	}
	.portfolio-read-chapter__content {
		flex: 1;
	}
	.portfolio-read-side {
		margin-left: 20px;
		order: 2;
		width: 300px;
	}
	.report-instructions {
		border-radius: 15px;
		margin-top: 20px;
		overflow: initial;
		padding: 20px;
		position: initial;
		&--hidden {
			display: block;
		}
	}
}

@media screen and (min-width: 1200px) {
	.portfolio-main {
		max-width: 950px;
	}
	.portfolio-read-side {
		width: 350px;
	}
	.portfolio-aside {
		background: none;
		border: none;
		float: right;
		padding: 0;
		position: initial;
		width: 210px;
		&__header {
			background: #FFF;
			border-radius: 12px;
			display: block;
			padding: 20px;
		}
		&__image {
			margin: 0 0 20px 0;
			width: 100%;
		}
		&__button {
			margin-top: 10px;
			position: initial;
			width: 100%;
		}
		&__teacher, 
		&__code {
			border-top: 1px solid #D4D4D4;
			margin-top: 10px;
			padding-top: 10px;
		}
		&__title {
			display: block;
        	font-family: $primaryFontBold;
		}
	}
}


.portfolio__filter {
	background: #E5E5E5;
	border-radius: 10px;
	cursor: pointer;
	float: left;
	font-family: $primaryFontBold;
	font-size: 16px;
	margin-right: 10px;
	padding: 10px;
	&--active {
		background: $blue;
		color: #FFF;
	}
}