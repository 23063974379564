@import "./../../newShared.scss";

.boomerColumn {
  background: #ffffff;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  // width: 330px;
  display: flex;
  padding: 1.5em;
  flex-direction: column;
  align-items: center;
  color: $main-black;
  margin-right: 30px;
  box-shadow: inset 0px -8px 0px 0px $shadow-white;

  .columnHeader {
    text-align: center;
    margin-bottom: 1em;
    .bigBoomerDecoration {
      background-color: $main-orange;
      border-radius: 1000px;
      width: 240px;
      height: 240px;
      position: relative;
      box-shadow: inset 0px -4px 0px 0px $shadow-yellow;

      img {
        position: absolute;
        width: 260px;
        top: -15px;
        left: -8px;
      }
    }
    p {
      margin-top: 0.5em;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .activeInfo {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    div {
      background-color: $main-orange;
      border-radius: 10px;
      text-align: center;
      padding: 5px 10px;
      box-shadow: inset 0px -4px 0px 0px $shadow-yellow;

      &.clases {
        background-color: $main-purple--bright;
        box-shadow: inset 0px -4px 0px 0px $shadow-purple;
      }

      span {
        display: block;
        font-size: 30px;
        line-height: 36px;
      }

      p {
        color: white;
        font-weight: bold;
        width: 95px;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  .assignmentsCalendar {
    margin-top: 2em;
    width: 100%;
    p {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media (max-width: 950px) {
  .boomerColumn {
    display: none;
  }
}

@media (max-height: 690px) {
  .assignmentsCalendar{
    display: none;
  }
}
