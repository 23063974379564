@import "./../MainWritingEditor.module.scss";

@mixin popUpBtn($bgColor, $hoverColor, $shadowColor) {
  cursor: pointer;
  background-color: $bgColor;
  color: white;
  padding: 1em;
  margin: 5px;
  border-radius: 8px;
  box-shadow: inset 0px -5px 0px 0px $shadowColor;
  font-family: "Gotham Rounded Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  z-index: 2;
  transition: all 0.2s ease;
  &:hover {
    background-color: $hoverColor;
    box-shadow: inset 0px -3px 0px 0px $shadowColor;
  }
}

.popUpsContainer {
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
}
.revisionBtn {
  @include popUpBtn($main-red, $main-red--hover, $main-red--shadow);
}

.feedbackBtn {
  @include popUpBtn($main-green, $main-green--hover, $main-green--shadow);
}

