@import "./../../newShared.scss";

.scrollArrows {
  position: absolute;
  bottom: 0;
  left: -20px;
  div {
    cursor: pointer;
    background-color: $main-purple;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    height: 40px;
    width: 40px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    box-shadow: inset -4px 0px 0px 0px $shadow-purple;

    span {
      margin-bottom: -4px;
    }
    &:hover {
      background-color: $main-purple--hover;
    }
  }
}
