.boomer-holder {
    .imageloader {
        height: 100%;
    }
    &__loader {
        animation: boomer-loader-pulse 0.9s infinite;
        max-height: 100%;
        max-width: 100%;
    }
    &__hidden-canvas {
        display: none;
    }
    &__image {
        max-height: 100%;
        max-width: 100%;
    }
    &--in-confirm {
        height: 100%;
        padding: 10px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        width: 70px;
        .boomer-holder__image,
        .boomer-holder__loader {
            height: 96px;
            left: -28px;
            max-height: none;
            max-width: none;
            position: relative;
            top: -23px;
        }
    }
    &--dashboard {
        .boomer-holder__image,
        .boomer-holder__loader {
            position: relative;
            max-height: none;
            max-width: none;
        }
    }
    &--in-heading {
        height: 100%;
        padding: 10px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        width: 61px;
        .boomer-holder__image,
        .boomer-holder__loader {
            height: 87px;
            left: -28px;
            max-height: none;
            max-width: none;
            position: relative;
            top: -23px;
        }
        &-with-rank {
            height: 100%;
            width: 61px;
            .boomer-holder__image,
            .boomer-holder__loader {
                height: 87px;
                left: -14px;
                max-height: none;
                max-width: none;
                position: relative;
                top: -12px;
            }
        }
    }
    &--in-main-menu {
        height: 100%;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        border-radius: 35px;
        width: 35px;
        .boomer-holder__image,
        .boomer-holder__loader {
            position: relative;
            height: 68px;
            left: -16px;
            max-height: none;
            max-width: none;
            top: -12px;
        }
    }
    &--in-panel {
        height: 100%;
        padding: 1px;
        overflow: hidden;
        text-align: center;
        width: 100%;
    }
}

.badge-info {
    background-image: linear-gradient(-180deg, #F0973F 0%, #E24800 100%);
    box-shadow: 0px 0px 15px 10px rgba(233,7,255,0.50);
    border: 1px solid #FFA257;
    border-radius: 15px;
    min-height: 80px;
    padding: 10px 10px 10px 80px;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    z-index: 201;
    &--bp {
        top: 240px;
    }
    &--notification {
        animation: slideIn 0.5s ease 0.5s forwards;
        bottom: 20px;
        cursor: pointer;
        left: initial;
        right: -500px;
        top: initial;
        width: calc(100% - 40px);
        z-index: 5000;
    }
    &--store {
        left: 20px;
        top: 200px;
        width: calc(100% - 40px);
    }
    &__badge {
        background-position: 50% 50%;
        background-repeat:   no-repeat;
        background-size: contain;
        display: block;
        height: 100px;
        left: -10px;
        position: absolute;
        top: -10px;
        width: 100px;
    }
    &__coins {
        height: 70px;
        width: auto;
        position: absolute;
        top: -10px;
        left: 20px;
    }
    &__close {
        align-content: center;
        background: #FFF;
        border-radius: 200px;
        cursor: pointer;
        display: flex;
        height: 36px;
        justify-content: center;
        position: absolute;
        right: -18px;
        top: -18px;
        width: 36px;
    }
    &__close-img {
        height: 16px;
        margin-top: 10px;
        width: auto;
    }
    &__desc {
        color: #FFF;
        margin-top: 20px;
    }
    &__inner {
        margin-top: -22px;
        text-align: center;
    }
    &__title {
        color: #7C0096;
        font-size: 24px;
        text-shadow: 2px 2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, -2px -2px 0 #FFF, 2px 0px 0 #FFF, 0px 2px 0 #FFF, -2px 0px 0 #FFF, 0px -2px 0 #FFF;
    }
}

@media screen and (min-width: $tablet) {
    .boomer-holder {
        &--in-main-menu {
            border-radius: 40px;
            width: 40px;
            .boomer-holder__image,
            .boomer-holder__loader {
                height: 73px;
                left: -16px;
            }
        }
    }
    .badge-info {
        max-width: 500px;
    }
}

@media screen and (min-height: 700px) and (min-width: $tablet) {
    .boomer-holder {
        &--in-main-menu {
            border-radius: 45px;
            width: 45px;
            .boomer-holder__image,
            .boomer-holder__loader {
                height: 80px;
                left: -17px;
            }
        }
    }
}

@keyframes boomer-loader-pulse {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes slideIn {
    0% {
        right: -500px;
    }
    90% {
        right: 30px;
    }
    100% {
        right: 20px;
    }
}

@keyframes slideIn {
    0% {
        right: -500px;
    }
    90% {
        right: 30px;
    }
    100% {
        right: 20px;
    }
}