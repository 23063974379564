@import './../../newShared.scss';

.classSelector{
  margin-left: 20px;
  height: 90%;
  border-radius: 10px;
  width: 100%;
  position: relative;

  .inner{
    width: 100%;
    max-height: 540px;
    overflow-y: auto;
    position: relative;
  }


  .scrollBtn{
    z-index: 1000;
    content:'';
    height:50px;
    width:50px;
    background: url('../../../../images/icon-chevronCircleLeft.png');
    background-size: cover;
    position: absolute;
    bottom:15px;
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 17px -6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 10px 10px 17px -6px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 17px -6px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
    
  }
}

.memberOfAClass{
  width: 800px;
  font-size: 35px;
}

.adjustArrows{
  left:-50px
}

@media (max-width: 950px) {
  .classSelector{
    margin-left: 0;
    overflow-x: hidden;
  }
}

// @media (max-height: 820px) {
//   .classSelector{
//     height: 500px;
//   }
// }

// @media (max-height: 760px) {
//   .classSelector{
//     height: 440px;
//   }
// }
// @media (max-height: 690px) {
//   .classSelector{
//     height: 380px;
//   }
// }
// @media (max-height: 620px) {
//   .classSelector{
//     height: 320px;
//   }
// }