.starBtn {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 0 2px;
    width: auto;
    background: none;
}
.starsWrapper {
    display: flex;
    justify-content: center;
}
.stars {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    max-width: 300px;
}

.starDulled {
    opacity: 0.5;
    pointer-events: none;
}

.starDisplayOnly {
    pointer-events: none;
}

.ratingTitle,
.ratingTitleDisabled,
.ratingText {
    text-align: center;
    font-weight: bold;
    font-family: "Gotham Rounded Bold";
    font-size: 22px;
    color: #4A4A4A;
}

.ratingText {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;

    &Disabled {
        opacity: 0;
    }
}

.clockAnimation {
    margin: 20px auto;
    display: block;
}