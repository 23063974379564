.boomer-panel {
    align-items: center;
    background-color: $purple;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer; 
    color: $yellowLight;
    display: flex;
    left: 0;
    padding: 10px 70px 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    &__content {
        flex: 1;
        margin-left: 10px;
    }
    &__content-top {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    &__desktop {
        display: none;
    }
    &__tablet {
        display: none;
    }
    &__rank {
        left: 15px;
        height: auto;
        position: absolute;
        top: 18px;
        width: 80px;
    }
    &__boomer {
        background: #FFF;
        border-radius: 70px;
        height: 70px;
        overflow: hidden;
        width: 70px;
        .boomer-holder__badge {
            display: block;
            left: 72px;
            top: 55px;
            width: 18px;
        }
    }
    &__coins {
        align-items: center;
        display: flex;
    }
    &__coins-img {
        height: 18px;
        margin-right: 3px;
    }
}

@media screen and (min-width: $tablet) {
    .boomer-panel {
        border-radius: 15px;
        margin-bottom: 30px;
        padding: 20px;
        position: relative;
        &__boomer {
            .boomer-holder__badge {
                top: 64px;
            }
        }
        &__mobile {
            display: none;
        }
        &__tablet {
            display: block;
        }
        &__rank {
            top: 28px;
        }
    }
}

@media screen and (min-width: $desktop) {
    .boomer-panel {
        box-shadow: none;
        display: block;
        padding: 20px 0;
        &__content {
            margin-left: 0;
            padding: 0 20px;
        }
        &__content-top {
            align-content: center;
            justify-content: center;
        }
        &__desktop {
            display: block;
            margin-left: 5px;
        }
        &__mobile {
            display: none;
        }
        &__tablet {
            display: none;
        }
        &__rank {
            width: 232px;
            z-index: 50;
            left: 50%;
            margin-left: -116px;
            top: 40px;
        }
        &__boomer {
            border-radius: 200px;
            height: 200px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            position: relative;
            width: 200px;
            .boomer-holder__badge {
                top: 64%;
                left: 50%;
                width: 52px;
            }
        }
    }
}