@import "./../../newShared.scss";

.projectHeader {
  width: 100%;
  background-color: $main-purple;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0px -6px 0px 0px $shadow-purple;

  .authorInfo {
    display: flex;
    padding: 1.5em 2em;
    align-items: center;
    .coins{
      display: flex;
      color: #FCC62D;
      font-weight: bold;
      font-size: 20px;
      margin-left: 1em;
      img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    figure {
      width: 90px;
      height: 90px;
      border-radius: 50px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 120px;
      }
    }
    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      margin-left: 1em;
    }
  }
}
