@import '../../../NewDashboard/newShared.scss';

.panel {
    background: #FFF;
    border-radius: 10px;
    padding: 30px;
    box-shadow: inset 0px -8px 0px 0px #ccc;
}

.instructions {
    max-width: 900px;

    &Title {
        text-align: center;
        color: $main-lightBlue;
        margin-bottom: 25px;
        margin-top: 20px;
    }

    &List {
        margin-bottom: 25px;
    }

    &Item {
        display: flex;
        align-items: center;
        margin: 10px 0;
        font-weight: bold;
        line-height: 1.3;
        font-size: 18px;
    }

    &Star {
        text-align: center;
        margin-right: 20px;
        max-width: 90px;
        flex-shrink: 0;
        flex-basis: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 8px;
        font-size: 30px;
        background: url("../../../../images/star-vote.svg") no-repeat center;
    }

    &Body {
        display: flex;
        align-items: center;
    }

    &Main {
        flex-basis: 60%;
        max-width: 60%;
        padding-right: 20px;
    }

    &Img {
        img {
            max-width: 290px;
            height: auto;
        }
    }

    &StartBtn {
        margin-left: 110px;
    }


}

// voting
.entryWrapper {
    background: #fff;
    min-height: calc(100vh - 80px);
}

.votingEntryText {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    white-space: pre-wrap;
}
.entry {
    display: flex;
    padding: 30px;
    max-width: 1000px;
    align-items: flex-start;

    &Main {
        flex: 1;
        padding-right: 25px;
    }

    &Header {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;

        &Title {
            color: $main-lightBlue;
            font-weight: bold;
            font-size: 28px;
            font-family: "Gotham Rounded Bold";
            margin-bottom: 10px;
        }
        &Subtitle {
            font-size: 20px;
            font-weight: bold;
            font-family: "Gotham Rounded Bold";
        }
    }

    &Side {
        border-radius: 10px;
        border: 1px solid #D8D8D8;
        padding: 20px;
 
    }
}

@media screen and (max-width: 768px) {
    .entry {
        flex-direction: column;
        &Side {
            flex: 0 0 1;
            order: -1;
            margin-bottom: 20px;
        }
    }
}
@media screen and (min-width: 768px) {
    .entry {
        position: relative;
        &Side {
            flex-basis: 320px;
            max-width: 320px;
            margin-bottom: 0;
            position: sticky;
            top: 10px;
        }
    }
}

.votedPanel {
    background: #fff url("../../../../images/star-vote.svg") no-repeat 30px 30px;
    padding-left: 120px;
    display: flex;
    line-height: 1.3;
    align-items: center;
    max-width: 900px;

    &Title {
        margin-bottom: 5px;
        color: $main-lightBlue;
        font-size: 28px;
        font-weight: bold;
    }

    &Subtitle {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    &Done {
        text-decoration: underline;
        color: #444;
        background: none;
        border: none;
        cursor: pointer;
    }

    &Main {
        max-width: 65%;
        flex: 0 0 65%;
        padding-right: 20px;
    }

    &Side {
        flex: 1;

        img {
            max-width: 100%;
        }
    }
}