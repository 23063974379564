/* MARGINS */
.u-m-base-5 {
	margin-bottom: 5px;
}
.u-m-base-10 {
	margin-bottom: 10px;
}
.u-m-base-15 {
	margin-bottom: 15px;
}
.u-m-base-20 {
	margin-bottom: 20px;
}
.u-m-base-40 {
	margin-bottom: 40px;
}
.u-m-top-5 {
	margin-top: 5px;
}
.u-m-top-10 {
	margin-top: 10px;
}
.u-m-top-15 {
	margin-top: 15px;
}
.u-m-top-20 {
	margin-top: 20px;
}
.u-m-top-40 {
	margin-top: 40px;
}

.u-padded-20 {
	padding: 20px;
}

.u-m-right-10 {
	margin-right: 10px;
}
/* TEXT */
.u-t-green {
	color: #56AF46;
}

.u-full-width {
	width: 100%;
}

.u-inner-scroll {
	flex: 1;
	overflow-y: scroll
}

.u-hidden-mobile,
.u-only-desktop,
.u-only-tablet,
.u-visible-tablet,
.u-visible-desktop {
	display: none;
}

.u-hidden-tablet,
.u-hidden-desktop,
.u-only-mobile,
.u-visible-mobile {
	display: block;
}

.u-hidden {
	display: none;
}

.u-bold {
	font-family: $primaryFontBold;
}

.u-blue-font {
	color: $blue;
}

.u-centered {
	margin-left: auto;
	margin-right: auto;
}

.u-centered-text {
	text-align: center;
}

.u-align-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.u-border-sep {
	align-items: center;
	display: flex;
	&::before, &::after {
		background: #D8D8D8;
		content: '';
		height: 1px;
		flex: 1;
	}
}
.u-border-sep__text {
	color: $yellowLight;
	flex: 0;
	font-size: 18px;
    font-family: $primaryFontBold;
	line-height: 16px;
	padding: 0 10px;
	text-align: center;
}
.u-border-sep__button {
	// flex: 0;
	margin: 0 10px;
}

.u-rel {
	position: relative;
}

.u-pill {
	align-items: center;
	border-radius: 10px;
	display: inline-flex;
	font-size: 14px;
    font-family: $primaryFontBold;
	padding: 5px 10px;
	&__image {
		height: 14px;
		margin-right: 5px;
	}
	&--grey {
		background: #EEEEEE;
		color: #929292;
	}
	&--pending {
		background: $yellowLighter;
		border: 1px solid $orange;
		color: $orange;
	}
	&--alert {
		background: $redLight;
		border: 1px solid $red;
		color: $red;
	}
	&--success {
		background: $greenLight;
		border: 1px solid $green;
		color: $green;
	}
	&--light-text {
		font-family: $primaryFont;
		font-size: 12px;
	}
}

@media screen and (min-width: $tablet) {
	.u-hidden-tablet,
	.u-only-desktop,
	.u-only-mobile {
		display: none;
	}
	.u-hidden-mobile,
	.u-hidden-desktop,
	.u-only-tablet,
	.u-visible-tablet {
		display: block;
	}
}

@media screen and (min-width: $desktop) {
	.u-hidden-desktop,
	.u-only-mobile,
	.u-only-tablet {
		display: none;
	}
	.u-hidden-mobile,
	.u-hidden-tablet,
	.u-only-desktop,
	.u-visible-desktop {
		display: block;
	}
}
.tagvocab {
	background: $yellow;
	border-radius: 15px;
	display: inline-block;
	padding: 0px 8px;
}
.pulse {
	box-shadow: 0 0 0 rgba(46,204,113, 1);
	animation: pulse 1s;
}
  
@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(252, 198, 45, 1);
	}
	70% {
		-webkit-box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
	}
}

@keyframes pulse {
	0% {
	  -moz-box-shadow: 0 0 0 0 rgba(252, 198, 45, 1);
	  box-shadow: 0 0 0 0 rgba(252, 198, 45, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
		box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
		box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
	}
}

.green-pulse {
	box-shadow: 0 0 0 rgba(35,178,50, 1);
	animation: green-pulse 1s;	
}

@-webkit-keyframes green-pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(35,178,50, 1);
	}
	70% {
		-webkit-box-shadow: 0 0 0 15px rgba(35,178,50, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(35,178,50, 0);
	}
}

@keyframes green-pulse {
	0% {
	  -moz-box-shadow: 0 0 0 0 rgba(35,178,50, 1);
	  box-shadow: 0 0 0 0 rgba(35,178,50, 1);
	}
	70% {
		-moz-box-shadow: 0 0 0 15px rgba(35,178,50, 0);
		box-shadow: 0 0 0 15px rgba(35,178,50, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(35,178,50, 0);
		box-shadow: 0 0 0 0 rgba(35,178,50, 0);
	}
}

.emoji-warning {
	line-height: 20px;
	max-width: 600px;
}

.emoji-warning-img {
	height: auto;
	margin-top: 20px;
	width: 50px;
}