.light-panel {
    background: #FFF;
    border: 1px solid #D6D6D6;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.inset-panel {
    background: #ECECEC;
    border: 1px solid #E6E6E6;
    border-radius: 15px;
}