.bee-container {
	flex: 1;
	overflow: scroll;
	margin-bottom: 20px;
}

.bee-panel {
	text-align: center;
}

.bee-panel__heading {
	font-size: 16px;
	font-family: $primaryFontBold;
}

.bee-panel__para {
	font-size: 14px;
	line-height: 20px;
}

.bee-panel__image {
	height: 100px;
	width: auto;
}

@media screen and (min-width: 960px) {
	.bee-container, .bee-panel__button {
		max-width: 850px;
	}
}