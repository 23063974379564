@import "./../UnitWriting.module.scss";

.submitBtn {
  font-family: "Gotham Rounded Bold";
  font-size: 20px;
  line-height: 20px;
  color: white;
  padding: 1em;
  background-color: $main-lightBlue;
  border: 4px solid $main-yellow;
  box-shadow: inset 0px -5px 0px 0px $main-lightBlue--shadow;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover,
  &:disabled {
    background-color: $main-lightBlue--hover;
    box-shadow: inset 0px -3px 0px 0px $main-lightBlue--hover--shadow;
  }
}

@media (max-width: 600px) {
  .submitBtn {
    font-size: 14px;
    line-height: 14px;
    margin: 10px 0;
    span{
      margin-top: -5px;
    }
  }
}
