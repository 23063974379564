@import "./../MainWritingEditor.module.scss";

$grey: #d4d4d4;
$active-blue: #60d7f1;
$main-blue: rgb(43, 78, 123);
$main-blue--bright: rgb(6, 95, 229);
$main-blue--light: rgb(7, 124, 160);
$main-blue-brighter: rgb(55, 159, 247);
$main-orange: rgb(255, 130, 88);
$main-orange--bright: rgb(255, 168, 38);
$main-green: rgb(151, 211, 24);
$main-pink: rgb(242, 98, 131);
$main-yellow: rgb(255, 168, 38);

.autoFeedbackContainer {
  padding: 10px;
  padding-left: 0;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  .infoBox {
    background-color: $tab-yellow;
    border-radius: 10px;
    color: $main-black;
    padding: .3em 1em;
    margin-left: 10px;
    box-shadow: inset 0px -5px 0px 0px $tab-yellow-shadow;
    position: relative;
    overflow: hidden;
    .infoBoxHeader {
      display: flex;
      align-items: center;
      .infoBoxText {
        margin-left: 1em;
        h6 {
          font-family: "Gotham Rounded Book";
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 0.25em;
        }
        p{
          font-size: 12px;
        }
      }
    }

    .closeBtn {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: $main-red;
      color: white;
      position: absolute;
      top: 9px;
      right: 9px;
      border-radius: 100%;
      font-family: "Gotham Rounded Bold";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    .reportsContainer {
      background-color: white;
      border-radius: 10px;
      box-shadow: inset 0px -5px 0px 0px $grey;
      padding: 0.2em 0.5em 0.4em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: hidden;
      // max-width: 350px;
      margin: .5em 0;
      // align-content: flex-end;
      .report {
        min-width: 130px;
        width: 49%;
        height: 23px;
        border-radius: 13px;
        background-color: #d2d2d2;
        padding: 0 !important;
        margin:3px 0;
        border:1px solid transparent;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          height: 20px;
          width: 20px;
          background-color: $grey;
          border-radius: 100%;
          margin: 0 0 0px 6px;
          color: #4e4e4e;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
        }
        .reportText {
          padding: 3px;
          border-radius: 20px;
          font-size: 12px;
          line-height: 17px;
          width: fit-content;
          // width: 130px;
          text-align: center;
          color: #4e4e4e;
          max-width: 90%;
          overflow:hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }


        &.passive {
          &:hover {
            border-color: #f26283;
            background-color: #f262844a;
          }
          &.active {
            border-color: #f26283;
            background-color: #f26283;
          }
        }
        &.overused {
          &:hover {
            border-color: #077ca0;
            background-color: #077ca04a;
          }
          &.active {
            border-color: #077ca0;
            background-color: #077ca0;
          }
        }
        &.initial {
          &:hover {
            border-color: #ffa826;
            background-color: #ffa8264a;
          }
          &.active {
            border-color: #ffa826;
            background-color: #ffa826;
          }
        }
        &.grammar {
          &:hover {
            border-color: #8e00f0;
            background-color: #8e00f04a;
          }
          &.active {
            border-color: #8e00f0;
            background-color: #8e00f0;
          }
        }
        &.consistency {
          &:hover {
            border-color: #FF8258;
            background-color: #FF82584a;
          }
          &.active {
            border-color: #FF8258;
            background-color: #FF8258;
          }
        }

        &.active {
          pointer-events: none;
          *{ color: white }
          span{ background-color: transparent }
        }

        &:hover {
          span{ background-color: transparent }
        }
      }
    }
  }
  .textContainer {
    padding: 5px;
    padding-top: 0;
    margin-top: 20px;
    height: fit-content;
    // overflow-y: auto;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 17px;
    color: #4a4a4a;
    overflow: auto;
    height: calc(100% - 200px);
    // border:1px solid chartreuse;

    p {
      padding: 0em 1em;
      // padding-left: 100px;
      // margin-left: -100px;
      padding-bottom: 180px;
    }
  }
}

@media (max-width: 1402px) {
  .autoFeedbackContainer {
    .textContainer {
      height: 38%;
    }
  }
}

/// AUTO-FEEDBACK CLASSES

  /// AUTO-FEEDBACK CLASSES

   
  .auto_grammar{
    border:1px solid  #8e00f0;
    background-color: #8e00f04a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
   
  .auto_consistency{
    border:1px solid  #FF8258;
    background-color: #FF82584a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
   
  .auto_overused{
    border:1px solid  #077ca0;
    background-color: #077ca04a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }

  .auto_initial{
    border:1px solid  #ffa826;
    background-color: #ffa8264a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }

  .auto_passive{
    border:1px solid  #f26283;
    background-color: #f262834a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
  

.feedback {
  // position: relative;
  cursor: pointer;
  text-align: center;

  .tooltipWrapper{
    position: absolute;
    visibility: hidden;
   left: calc(50% - 100px);
  }
  
  .tooltip{
    position: absolute;
    top:15px;
    left: 0;
    right: 0;
    // left: 50%;
    // transform: translateX(-50%);
    height:fit-content;
    height:-moz-max-content;
    width:200px;
    width:200px;
    min-width: 160px;
    box-sizing:border-box;
    padding:10px;
    border-radius: 5px;
    color: #858585;
    background-color: white;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    z-index: 1000;

    // &::after{
    //   content:'';
    //   position: absolute;
    //   top: -10px;
    //   left:0;
    //   margin-left: calc(50% - 5px);
    //   border-width: 5px;
    //   border-style: solid;
    //   border-color:transparent transparent white transparent; 
    // }
  }

  .sugg_grammar {
    font-size: 12px;
    font-weight: 500;
    color: $main-blue--light;
    .suggHead {
      font-size: 12px;
      font-weight: 700;
      display: block;
      color: $main-blue--light;
    }
  }

  .sugg_consistency {
    font-size: 12px;
    font-weight: 500;
    color: $main-orange;
    .suggHead {
      font-size: 12px;
      font-weight: 700;
      display: block;
      color: $main-pink;
    }
  }

  .sugg_overused {
    font-size: 12px;
    font-weight: 500;
    color: #60d7f1;
    .suggHead {
      font-size: 12px;
      font-weight: 700;
      display: block;
      color: #60d7f1;
    }
  }

  .sugg_passive {
    font-size: 12px;
    font-weight: 500;
    color: $main-pink;
    .suggHead {
      font-size: 12px;
      font-weight: 700;
      display: block;
      color: $main-pink;
    }
  }

  .sugg_initial {
    font-size: 12px;
    font-weight: 500;
    color: $main-yellow;
    .suggHead {
      font-size: 12px;
      font-weight: 700;
      display: block;
      color: $main-pink;
    }
  }

  &:hover > .tooltipWrapper {
    // left: 50%;
    // transform: translateX(-50%);
    visibility: visible;
  }
}
